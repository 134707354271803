import { useEffect, useState } from "react";
import ConsultantSideBar from "./ConsultantComponents/ConsultantSideBar";
import AxiosInstance from "../../Utils/AxiosInstance";
import { toast } from "react-toastify";
import { Table } from "flowbite-react"
import moment from "moment";
import SpinnerComponent from "../../Components/SpinnerComponent";

function ConsultantClients() {
  const [paidClients, setPaidClients]: any = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/paidClient")
      .then(({ data }) => {
        setPaidClients(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to get Leads, please try again")
      });
  },[]);
  return (<ConsultantSideBar nav={true}>
    <div className="m-4 overflow-x-auto" >
        <Table>
            <Table.Head>
                <Table.HeadCell>Sr</Table.HeadCell>
                <Table.HeadCell>Client Name</Table.HeadCell>
                <Table.HeadCell>Last Session Date & Time</Table.HeadCell>
                <Table.HeadCell>Total Amount</Table.HeadCell>
                <Table.HeadCell>Number of Sessions</Table.HeadCell>
            </Table.Head>
            <Table.Body>
                {
                    paidClients.map((client:any,index:any)=>(
                        <Table.Row>
                            <Table.Cell>{index+1}</Table.Cell>
                            <Table.Cell>{client.userInfo.username}</Table.Cell>
                            <Table.Cell>{moment(client.bookingDate).format(
                              "MMMM Do YYYY, h:mm a"
                            )}</Table.Cell>
                            <Table.Cell>{client.amount}</Table.Cell>
                            <Table.Cell>{client.sessions}</Table.Cell>
                        </Table.Row>
                    ))
                }
            </Table.Body>
        </Table>
    </div>
    {loading && <SpinnerComponent/>}
  </ConsultantSideBar>);
}

export default ConsultantClients;
