import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance from "../Utils/AxiosInstance";
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalHeader,
  Rating,
} from "flowbite-react";
import SpinnerComponent from "./SpinnerComponent";

import NoCA from "../Images/NoSession.svg";
import moment from "moment";
import { costing, timeMap } from "./Costing";
import SelectionPopup from "./SelectionPopup";

function SelectSlot(props: any) {
  const [loading, setLoading] = useState(false);
  const { category } = props;

  const [consultants, setConsultants]: any = useState([]);
  const [loadMore, setLoadMore] = useState(false);

  const [consultantInfo, setConsultantInfo]: any = useState(null);
  const [showConsultantPopup, setShowConsultantPopup] = useState(false);

  useEffect(() => {
    getConsultants();
  }, [category]);

  const getConsultants = () => {
    setLoading(true);
    AxiosInstance()
      .get(
        `/users/getConsultantsByCategory?category=${category}&skip=${consultants.length}&limit=12`
      )
      .then(({ data }) => {
        setConsultants([...consultants, ...data.consultants]);
        setLoadMore(data.hasNext);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get consultants please try again`);
        setLoading(false);
      });
  };

  return (
    <div className="h-full">
      {consultants.length === 0 && loading === false && (
        <div className="h-full w-full flex flex-col items-center justify-center text-center mt-10">
          <img src={NoCA} className="w2/3" />
          <div className="text-sm font-bold">No Experts Available</div>
          <div className="text-xs font-light">
            We are onboarding more Experts, to get you the best experience
          </div>
        </div>
      )}
      <div className="mt-2 lg:mt-4 grid grid-cols-1 gap-4 lg:grid-cols-4">
        {consultants.map((consultant: any) => (
          <div className="border-[0.5px] border-[#E3E3E3] rounded-lg p-2">
            <div className="flex items-center gap-4 pr-2 pl-2 ">
              <div>
                <Avatar img={consultant.profilePic} rounded className="z-0"/>
              </div>
              <div>
                <div className="font-bold">{consultant.prefix}{" "}{consultant.username}</div>
                <div className="font-light text-xs">
                  {consultant.experience} Years experience
                </div>
                <Rating size="sm" className="mt-1">
                  <Rating.Star filled={consultant.rating >= 1} />
                  <Rating.Star filled={consultant.rating >= 2} />
                  <Rating.Star filled={consultant.rating >= 3} />
                  <Rating.Star filled={consultant.rating >= 4} />
                  <Rating.Star filled={consultant.rating >= 5} />
                </Rating>
              </div>
            </div>
            <div className="m-2 mt-4 p-2 border-t-[0.5px] border-[#E3E3E3]">
              {consultant.availableSlot.availableSlot ? (
                <Badge className="w-fit" size="sm" color="success">
                  Next Available Slot : {consultant.availableSlot.date}{" "}
                  {timeMap[consultant.availableSlot.time.toString()]}
                </Badge>
              ) : (
                <Badge size="sm" className="w-fit" color="failure">
                  No Available Slots
                </Badge>
              )}
              <div
                className="mt-1 font-semibold text-sm text-[#547AF7] cursor-pointer"
                onClick={() => {
                  setConsultantInfo(consultant);
                  setShowConsultantPopup(true);
                }}
              >
                Book a Session
              </div>
            </div>
          </div>
        ))}
      </div>
      {loadMore && (
        <div className="flex w-full justify-center mt-2">
          {" "}
          <Button
            className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
            size="xs"
            onClick={() => {
              getConsultants();
            }}
          >
            Load More
          </Button>
        </div>
      )}
      {loading && <SpinnerComponent />}
      <SelectionPopup
        showConsultantPopup={showConsultantPopup}
        modalClose={() => {
          setShowConsultantPopup(false);
          setConsultantInfo(null);
        }}
        consultantInfo={consultantInfo}
        category={category}
      />
    </div>
  );
}

export default SelectSlot;
