import { useLocation, useNavigate } from "react-router-dom";
import ConsultantProfileInfo from "./ConsultantComponents/ConsultantProfileInfo";
import ConsultantKYCInfo from "./ConsultantComponents/ConsultantKYCInfo";
import ConsultantProfessionaInfo from "./ConsultantComponents/ConsultantProfessionalInfo";
import { useEffect, useState } from "react";
import AxiosInstance from "../../Utils/AxiosInstance";
import SpinnerComponent from "../../Components/SpinnerComponent";

function ConsultantUpdateDetails(){
    const location = useLocation()
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [userInfo,setUserInfo] = useState({})

    const getDeatails = () => {
        setLoading(true);
        AxiosInstance()
          .get("/consultant/verify")
          .then(({ data }) => {
            setUserInfo(data);
            setLoading(false);
          })
          .catch((err) => {
            localStorage.clear();
            setLoading(false);
            navigate("/");
          });
      };

    useEffect(()=>{
        getDeatails()
    },[])

    console.log(location)

    return(<div>
        {
            location.state.update === "PROFILE" && !loading && (<ConsultantProfileInfo
            userInfo= {userInfo}
                onSuccess={()=>{
                    navigate("/consultant/profile")
                }}
            />)
        }
        {
            location.state.update === "KYC"&& !loading && (<ConsultantKYCInfo
                userInfo= {userInfo}
                onSuccess={()=>{
                    navigate("/consultant/profile")
                }}
            />)
        }
        {
            location.state.update === "PROFESSIONAL"&& !loading && (<ConsultantProfessionaInfo
                userInfo= {userInfo}
                onSuccess={()=>{
                    navigate("/consultant/profile")
                }}
            />)
        }
        {loading && <SpinnerComponent/>}
    </div>)
}

export default ConsultantUpdateDetails;