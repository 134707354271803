function TNC() {
  return (
    <div className="p-4">
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>ABOUT US:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Welcome to TaxView, your ultimate destination for comprehensive and
      innovative financial, legal, and tax advisory services.&nbsp;
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      In a world where navigating the complexities of financial management can
      be overwhelming, TaxView stands out as a beacon of simplicity, expertise,
      and efficiency.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Experience the future of financial and legal management with TaxView where
      expert solutions meet unparalleled convenience.
    </span>
  </p>
  
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "13.999999999999998pt" }}>
        Terms and Conditions&nbsp;
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "12pt" }}>Our website address is&nbsp;</span>
    </strong>
    <a href="https://taxview.co.in">
      <strong>
        <u>
          <span style={{ color: "#0000ff", fontSize: "12pt" }}>
            www.taxview.co.in
          </span>
        </u>
      </strong>
    </a>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      This privacy policy sets out how&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ fontSize: "12pt" }}>
      &nbsp;uses and protects any information that you give&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ fontSize: "12pt" }}>&nbsp;when you use this website.</span>
  </p>
  <p>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ fontSize: "12pt" }}>
      &nbsp;is committed to ensuring that your privacy is protected. Should we
      ask you to provide certain information by which you can be identified when
      using this website, then you can be assured that it will only be used in
      accordance with this privacy statement.
    </span>
  </p>
  <p>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ fontSize: "12pt" }}>
      &nbsp;may change this policy from time to time by updating this page. You
      should check this page from time to time to ensure that you are happy with
      any changes.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "12pt" }}>What we collect</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "12pt" }}>
        We may collect the following information:
      </span>
    </strong>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>
      name, date of birth,&nbsp;contact information including email address and
      phone numbers,&nbsp;demographic information such as postcode, country,
      city, state, other information relevant to customer surveys and/or offers,
      and payment options.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      What we do with the information we gather
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      We require this information to understand your needs and provide you with
      a better service, and in particular for the following reasons:
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>Internal record keeping.</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      We may use the information to improve our products and services.
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      We may periodically send promotional emails about new products, special
      offers or other information which we think you may find interesting using
      the email address which you have provided.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      From time to time, we may also use your information to contact you for
      market research purposes. We may contact you by email, phone, fax or mail.
      We may use the information to customise the website according to your
      interests.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Security</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      We are committed to ensuring that your information is secure. In order to
      prevent unauthorised access or disclosure, we have put in place suitable
      physical, electronic and managerial procedures to safeguard and secure the
      information we collect online.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>How we use cookies</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      A cookie is a small file which asks permission to be placed on your
      computer’s hard drive. Once you agree, the file is added and the cookie
      helps analyse web traffic or lets you know when you visit a particular
      site. Cookies allow web applications to respond to you as an individual.
      The web application can tailor its operations to your needs, likes and
      dislikes by gathering and remembering information about your preferences.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      We use traffic log cookies to identify which pages are being used. This
      helps us analyse data about web page traffic and improve our website in
      order to tailor it to customer needs. We only use this information for
      statistical analysis purposes and then the data is removed from the
      system.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      Overall, cookies help us provide you with a better website, by enabling us
      to monitor which pages you find useful and which you do not. A cookie in
      no way gives us access to your computer or any information about you,
      other than the data you choose to share with us.
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>
      You can choose to accept or decline cookies. Most web browsers
      automatically accept cookies, but you can usually modify your browser
      setting to decline cookies if you prefer. This may prevent you from taking
      full advantage of the website.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "12pt" }}>
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </span>
    </strong>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      whenever you are asked to fill in a form on the website, look for the box
      that you can click to indicate that you do not want the information to be
      used by anybody for direct marketing purposes
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      We will not sell, distribute or lease your personal information to third
      parties unless we have your permission or are required by law to do so. We
      may use your personal information to send you promotional information
      about third parties which we think you may find interesting if you tell us
      that you wish this to happen.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      You may request details of personal information which we hold about you
      under the Data Protection Act 1998. A small fee will be payable. If you
      would like a copy of the information held on you, please contact us. If
      you believe that any information, we are holding on you is incorrect or
      incomplete, please write to or email us as soon as possible, at the above
      address. We will promptly correct any information found to be incorrect.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      What personal data we collect and why we collect it
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Comments</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>
      When visitors leave comments on the site we collect the data shown in the
      comments form, and also the visitor’s IP address and browser user agent
      string to help spam detection.
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      An anonymized string created from your email address (also called a hash)
      may be provided to the Gravatar service to see if you are using it. The
      Gravatar service privacy policy is available here:&nbsp;
    </span>
    <a href="https://automattic.com/privacy/">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          https://automattic.com/privacy/.
        </span>
      </u>
    </a>
    <span style={{ fontSize: "12pt" }}>
      &nbsp;After approval of your comment, your profile picture is visible to
      the public in the context of your comment.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Media</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      If you upload images to the website, you should avoid uploading images
      with embedded location data (EXIF GPS) included. Visitors to the website
      can download and extract any location data from images on the website.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Contact forms</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Cookies</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      If you leave a comment on our site you may opt-in to saving your name,
      email address and website in cookies. These are for your convenience so
      that you do not have to fill in your details again when you leave another
      comment. These cookies will last for one year.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      If you have an account and you log in to this site, we will set a
      temporary cookie to determine if your browser accepts cookies. This cookie
      contains no personal data and is discarded when you close your browser.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      When you log in, we will also set up several cookies to save your login
      information and your screen display choices. Login cookies last for two
      days, and screen options cookies last for a year. If you select “Remember
      Me”, your login will persist for two weeks. If you log out of your
      account, the login cookies will be removed.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      If you edit or publish an article, an additional cookie will be saved in
      your browser. This cookie includes no personal data and simply indicates
      the post ID of the article you just edited. It expires after 1 day.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      Embedded content from other websites
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      Articles on this site may include embedded content (e.g. videos, images,
      articles, etc.). Embedded content from other websites behaves in the exact
      same way as if the visitor has visited the other website.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      These websites may collect data about you, use cookies, embed additional
      third-party tracking, and monitor your interaction with that embedded
      content, including tracking your interaction with the embedded content if
      you have an account and are logged in to that website.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Analytics</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Who we share your data with</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>How long we retain your data</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      If you leave a comment, the comment and its metadata are retained
      indefinitely. This is so we can recognize and approve any follow-up
      comments automatically instead of holding them in a moderation queue.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      For users that register on our website (if any), we also store the
      personal information they provide in their user profile. All users can
      see, edit, or delete their personal information at any time (except they
      cannot change their username). Website administrators can also see and
      edit that information.
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
      <br />
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      What rights you have over your data.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      If you have an account on this site, or have left comments, you can
      request to receive an exported file of the personal data we hold about
      you, including any data you have provided to us. You can also request that
      we erase any personal data we hold about you. This does not include any
      data we are obliged to keep for administrative, legal, or security
      purposes.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Where we send your data</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      Visitor comments may be checked through an automated spam detection
      service.
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Your contact information</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>Additional information</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>How we protect your data</span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      What data breach procedures we have in place
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      What third parties we receive data from
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      What automated decision making and/or profiling we do with user data
    </span>
    <span style={{ fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ fontSize: "12pt" }}>
      Industry regulatory disclosure&nbsp;requirements
    </span>
  </p>

  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "13.999999999999998pt" }}>Privacy Policy</span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        By visiting our site, you are agreeing to be bound by the following
        terms and conditions.
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      We may change these terms and conditions at any time. Your continued use
      of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;means that you accept any new or modified terms and conditions that
      we come up with. Please re-visit the ‘Terms of Use’ link at our site from
      time to time to stay abreast of any changes that we may introduce. The
      term ‘
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      ’ is used through this entire Terms of Use document to refer to the
      website, its owners and the employees and associates of the owner.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>1 REGISTRATION</span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      By registering, you certify that all information you provide, now or in
      the future, is accurate. You agree to get periodic SMS/Whtsapp alerts. You
      agree to get periodic newsletters.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;reserves the right, in its sole discretion, to deny you access to
      this website or any portion thereof without notice for the following
      reasons (a) immediately by
    </span>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;for any unauthorised access or use by you (b) immediately by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;if you assign or transfer (or attempt the same) any rights granted
      to you under this Agreement; (c) immediately, if you violate any of the
      other terms and conditions of this User Agreement.
    </span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>2 LICENCES</span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , hereby grants you a limited, non-exclusive, non-assignable and
      non-transferable licence to access&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;and its products provided and expressly conditioned upon your
      agreement that all such access and use shall be governed by all of the
      terms and conditions set forth in this USER AGREEMENT.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        3 COPYRIGHT &amp; NO RETRANSMISSIONS OF INFORMATION
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <a href="http://www.taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;as well as the design and information contained in this site is the
      valuable, exclusive property of&nbsp;
    </span>
    <a href="https://harshubh.com/">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , and nothing in this Agreement shall be construed as transferring or
      assigning any such ownership rights to you or any other person or
      &nbsp;entity. All stock call information on&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;is the proprietary, confidential property of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;and cannot be repeated for any reason outside the&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;You agree not to repeat or rebroadcast in any way any of the stock
      call information made on&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;for any reason whatsoever. You agree that if you do repeat or
      re-post any of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      calls by any mean, you will be liable for actual and punitive damages as
      determined by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;and additional damages to be determined by an Indian court of Law.
      You may not resell, redistribute, broadcast or transfer the information or
      use the information in a searchable, machine-readable database unless
      separately and specifically authorised in writing by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      prior to such use. You may not rent, lease, sublicense, distribute,
      transfer, copy, reproduce, publicly display, publish, adapt, store or
      time-share&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , any part thereof, or any of the information received or accessed there
      from to or through any other person or entity unless separately and
      specifically authorised in writing by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;prior to such use. In addition, you may not remove, alter or obscure
      any copyright, legal or proprietary notices in or on any portions&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;without prior written authorization Except as set forth herein, any
      other use of the information contained in this site requires the prior
      written consent of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;and may require a separate fee.
    </span>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        4 DELAYS IN SERVICES
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>Neither&nbsp;</span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;(including its and their directors, employees, affiliates, agents,
      representatives or subcontractors) shall be liable for any loss or
      liability resulting, directly or indirectly, from delays or interruptions
      due to electronic or mechanical equipment failures, telephone interconnect
      problems, defects, weather, strikes, walkouts, fire, acts of God, riots,
      armed conflicts, acts of war, or other like causes.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;shall have no responsibility to provide you access to&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#0000ff", fontSize: "12pt" }}>&nbsp;</span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      while interruption of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <u>
      <span style={{ color: "#0000ff", fontSize: "12pt" }}>&nbsp;</span>
    </u>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;is due to any such cause shall continue.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        5 LIABILITY DISCLAIMERS
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE
      CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED
      ON THIS WEB SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES
      ARE PERIODICALLY ADDED TO THE CONTENTS HEREIN.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;AND/OR ITS RESPECTIVE SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES
      IN THIS WEB SITE AT ANY TIME. THIS WEB SITE MAY BE TEMPORARILY UNAVAILABLE
      FROM TIME TO TIME DUE TO REQUIRED MAINTENANCE, TELECOMMUNICATIONS
      INTERRUPTIONS, OR OTHER DISRUPTIONS.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;(AND ITS OWNERS, SUPPLIERS, CONSULTANTS, ADVERTISERS, AFFILIATES,
      PARTNERS, EMPLOYEES OR ANY OTHER ASSOCIATED ENTITIES, ALL COLLECTIVELY
      REFERRED TO AS ASSOCIATED ENTITIES HEREAFTER) SHALL NOT BE LIABLE TO USER
      OR MEMBER OR ANY THIRD PARTY SHOULD&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;EXERCISE ITS RIGHT TO MODIFY OR DISCONTINUE ANY OR ALL OF THE
      CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED
      ON THIS WEBSITE.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;AND/OR ITS RESPECTIVE ASSOCIATED ENTITIES MAKE NO REPRESENTATIONS
      ABOUT THE SUITABILITY OF THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS,
      FEATURES AND SERVICES CONTAINED ON THIS WEB SITE FOR ANY PURPOSE. ALL SUCH
      CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES ARE
      PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <u>
      <span style={{ color: "#0000ff", fontSize: "12pt" }}>&nbsp;</span>
    </u>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;AND/OR ITS ASSOCIATED ENTITIES HEREBY DISCLAIM ALL WARRANTIES AND
      CONDITIONS WITH REGARD TO THESE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS,
      FEATURES AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
      NON-INFRINGEMENT, AND AVAILABILITY IN NO EVENT SHALL&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      AND/OR ITS ASSOCIATED ENTITIES BE LIABLE FOR ANY DIRECT, INDIRECT,
      PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR
      IN ANY WAY CONNECTED WITH THE USE OF THIS WEB SITE OR WITH THE DELAY OR
      INABILITY TO USE THIS WEBSITE, OR FOR ANY CONTENTS, INFORMATION, SOFTWARE,
      PRODUCTS, FEATURES AND SERVICES OBTAINED THROUGH THIS WEB SITE, OR
      OTHERWISE ARISING OUT OF THE USE OF THIS WEB SITE, WHETHER BASED ON
      CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;OR ANY OF ITS ASSOCIATED ENTITIES HAS BEEN ADVISED OF THE
      POSSIBILITY OF DAMAGES.
    </span>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        6 EQUIPMENT AND OPERATION
      </span>
    </strong>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      You shall provide and maintain all telephone/internet and other equipment
      necessary to access&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , and the costs of any such equipment and/or telephone/internet
      connections or use, including any applicable taxes, shall be borne solely
      by you. You are responsible for operating your own equipment used to
      access&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
  </p>
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        7 INFORMATION DISCLAIMERS
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      You acknowledge that the information provided through&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;is compiled from sources, which are beyond the control of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;Though such information is recognized by the parties to be generally
      reliable, the parties acknowledge that inaccuracies may occur and&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;does not warrant the accuracy or suitability of the information. For
      this reason, as well as the possibility of human and mechanical errors and
      other factors YOU ACKNOWLEDGE THAT&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;is PROVIDED TO YOU ON AN “AS IS, WITH ALL FAULTS” BASIS.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <u>
      <span style={{ color: "#0000ff", fontSize: "12pt" }}>&nbsp;</span>
    </u>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS, ORAL,
      IMPLIED, STATUTORY OR OTHERWISE, OF ANY KIND TO THE USERS AND/OR ANY THIRD
      PARTY, INCLUDING ANY IMPLIED WARRANTIES OF ACCURACY, TIMELINESS,
      COMPLETENESS, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AS
      WELL AS ANY WARRANTIES ARISING BY VIRTUE OF CUSTOM OF TRADE OR COURSE OF
      DEALING AND ANY IMPLIED WARRANTIES OF TITLE OR NON-INFRINGEMENT. IN
      ADDITION,&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , IN PROVIDING THE INFORMATION MAKES NO ENDORSEMENT OF ANY PARTICULAR
      SECURITY, MARKET PARTICIPANT, OR BROKERAGE. FURTHER,&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;DOES NOT REPRESENT OR WARRANT THAT IT WILL MEET YOUR REQUIREMENTS OR
      IS SUITABLE FOR YOUR NEEDS Under this User Agreement, you assume all risk
      of errors and/or omissions in&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , including the transmission or translation of information. You assume
      full responsibility for implementing sufficient procedures and checks to
      satisfy your requirements for the accuracy and suitability of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , including the information, and for maintaining any means, which you may
      require for the reconstruction of lost data or subsequent manipulations or
      analyses of the information under the User Agreement YOU AGREE THAT&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;(INCLUDING ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES,
      GROUP COMPANIES AGENTS, REPRESENTATIVES OR SUBCONTRACTORS. SHALL NOT IN
      ANY EVENT BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES
      ARISING OUT OF THE USE OR INABILITY TO USE
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;FOR ANY PURPOSE WHATSOEVER.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;AND Its AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS SHALL
      HAVE NO LIABILITY IN TORT, CONTRACT, OR OTHERWISE TO USER AND/OR ANY THIRD
      PARTY
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        8 LINKS TO THIRD PARTY SITES
      </span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      The links in this site will allow you to leave&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      . The linked sites are not under the control of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>.&nbsp;</span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;has not reviewed, nor approved these sites and is not responsible
      for the contents or omissions of any linked site or any links contained in
      a linked site. The inclusion of any linked site does not imply endorsement
      by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      of the site. Third party links to&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;shall be governed by a separate agreement.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp; &nbsp;&nbsp;
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        9 INDEMNIFICATION
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;(INCLUDING ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES,
      GROUP COMPANIES, AGENTS, REPRESENTATIVES OR SUBCONTRACTORS) FROM ANY AND
      ALL CLAIMS AND LOSSES IMPOSED ON, INCURRED BY OR ASSERTED AS A RESULT OF
      OR RELATED TO: (a) your access and use of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;(b) any non-compliance by user with the terms and conditions hereof;
      or (c) any third party actions related to users receipt and use of the
      information, whether authorised or unauthorised. Any clause declared
      invalid shall be deemed severable and not affect the validity or
      enforceability of the remainder. These terms may only be amended in a
      writing signed by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
  </p>
  <p>
    <br />
  </p>
  <p>
    <u>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
    </u>
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        10 CONFLICTING TERMS
      </span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      If there is any conflict between this User Agreement and other documents,
      this User Agreement shall govern, whether such order or other documents is
      prior to or subsequent to this User Agreement, or is signed or
      acknowledged by any director, officer, employee, representative or agent
      of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        11 ATTORNEY’S FEES
      </span>
    </strong>
  </p>
  <p>
    <br />
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      If Wealthview analytics pvt ltd takes action (by itself or through its
      associate companies) to enforce any of the provisions of this User
      Agreement, including collection of any amounts due hereunder, Wealthview
      analytics pvt ltd shall be entitled to recover from you (and you agree to
      pay), in addition to all sums to which it is entitled or any other relief,
      at law or in equity, reasonable and necessary attorney’s fees and any
      costs of any litigation.
    </span>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        12.ENTIREAGREEMENT
      </span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      This User Agreement constitutes the entire agreement between the parties,
      and no other agreement, written or oral, exists between you and&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;By using the Information on&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , you assume full responsibility for any and all gains and losses,
      financial, emotional or otherwise, experienced, suffered or incurred by
      you.&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      does not guarantee the accuracy, completeness or timeliness of, or
      otherwise endorse in any way, the views, opinions or recommendations
      expressed in the Information, does not give investment advice, and does
      not advocate the purchase or sale of any security or investment by you or
      any other individual. The Information is not intended to provide tax,
      legal or investment advice, which you should obtain from your professional
      advisor prior to making any investment of the type discussed in the
      Information. The Information does not constitute a solicitation by the
      information providers,&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;or other of the purchase or sale of securities.&nbsp;
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      THE SERVICE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER
      EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY WARRANTY FOR
      INFORMATION, DATA, SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS PROVIDED
      THROUGH OR IN CONNECTION WITH THE SERVICE. SPECIFICALLY,&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO: (i)
      ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, OR
      CONTENT OF INFORMATION, PRODUCTS OR SERVICES; AND (ii) ANY WARRANTIES OF
      TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY OR
      FITNESS FOR A PARTICULAR PURPOSE. THIS DISCLAIMER OF LIABILITY APPLIES TO
      ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR,
      OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
      TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR
      DESTRUCTION OR UNAUTHORISED ACCESS TO, ALTERATION OF , OR USE OF RECORD,
      WHETHER FOR BREACH OF CONTRACT, TORT, NEGLIGENCE, OR UNDER ANY OTHER CAUSE
      OF ACTION. NEITHER&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;NOR ANY OF ITS EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES,
      GROUP COMPANIES OR CONTENT OR SERVICE PROVIDERS SHALL BE LIABLE TO YOU OR
      OTHER THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR
      CONSEQUENTIAL DAMAGES ARISING OUT OF USE OF SERVICE OR INABILITY TO GAIN
      ACCESS TO OR USE THE SERVICE OR OUT OF ANY BREACH OF ANY WARRANTY. BECAUSE
      SOME COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
      CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO
      YOU. IN SUCH COUNTRIES, THE RESPECTIVE LIABILITY OF&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      , ITS EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES, GROUP COMPANIES
      AND CONTENT OR SERVICE PROVIDERS RESPECTIVE LIABILITY IS LIMITED TO THE
      AMOUNT PROVIDED UNDER SAID LAW. FURTHER, YOU AGREE AND UNDERSTAND THAT ALL
      SERVICES PROVIDED ARE NON-REFUNDABLE AND THAT YOU SHOULD CAREFULLY
      CONSIDER WHETHER OUR SERVICES ARE ABLE TO MEET YOUR NEEDS.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        13 CONFIDENTIALITY/NON-COMPETITION CLAUSE
      </span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      You agree to keep the information received from the&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;and services CONFIDENTIAL and will NOT Disclose the knowledge gained
      to other any person or firm for any reason. You hereby consent to the
      Jurisdiction of the Courts of Mumbai, India with respect to violation of
      any part of this Agreement. You expressly agree that you will be
      financially liable for actual and punitive copyright infringement and
      loss-of-business damages awardable to&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      and/or its associate companies if you derive any or all of your income
      from a stock or financial-services related web site and/or we find that
      you have copied, made derivative works from, or in any other way
      duplicated the proprietary information located on&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;web servers and/or web site (including but not limited to graphics,
      trading systems and methods, etc). The information and commentaries are
      not meant to be an endorsement or offering of any stock purchase.
      Principals and employees of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;do not represent themselves as acting in the position of an
      investment advisor, broker, or investment manager for the use of the
      information in this service.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>14.TERMINATION</span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      This User Agreement and the licence rights granted hereunder shall remain
      in full force and effect unless terminated or cancelled for any of the
      following reasons: (a) immediately by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;for any unauthorised access or use by you (b) immediately by&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;if you assign or transfer (or attempt the same) any rights granted
      to you under this Agreement; (c) immediately, if you violate any of the
      other terms and conditions of this User Agreement. Termination or
      cancellation of this Agreement shall not affect any right or relief to
      which&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      may be entitled, at law or in equity. Upon termination of this User
      Agreement, all rights granted to you will terminate and revert to&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;Except as set forth herein, regardless of the reason for
      cancellation or termination of this User Agreement, the fee charged if any
      for access to&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      &nbsp;is non-refundable for any reason.&nbsp;
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12pt" }}>
        15.JURISDICTIONS
      </span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>&nbsp;</span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      The terms of this agreement are exclusively based on and subject to Indian
      law. You hereby consent to the exclusive jurisdiction and venue of courts
      in Nagpur, maharashtra ,India in all disputes arising out of or relating
      to the use of this website. Use of this website is unauthorised in any
      jurisdiction that does not give effect to all provisions of these terms
      and conditions, including without limitation this paragraph.
      Copyright&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12pt" }}>
      . &nbsp;All rights reserved.
    </span>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span style={{ fontSize: "12pt" }}>&nbsp;</span>
  </p>
 
  <p>
    <br />
  </p>
  <p>
    <br />
  </p>
  <p>
    <strong>
      <span style={{ color: "#35093c", fontSize: "12.499999999999998pt" }}>
        REFUND POLICY
      </span>
    </strong>
    <span style={{ color: "#35093c", fontSize: "12.499999999999998pt" }}>
      <br />
    </span>
    <span style={{ color: "#35093c", fontSize: "12.499999999999998pt" }}>
      Any subscriptions made towards products/services of&nbsp;
    </span>
    <a href="https://taxview.co.in">
      <u>
        <span style={{ color: "#0000ff", fontSize: "12pt" }}>
          www.taxview.co.in
        </span>
      </u>
    </a>
    <span style={{ color: "#35093c", fontSize: "12.499999999999998pt" }}>
      &nbsp;are non-refundable under any circumstances.&nbsp;
    </span>
  </p>
  <p>
    <br />
  </p>
  
</div>

  );
}

export default TNC;
