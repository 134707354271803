import { Button, Label, Radio, Select, TextInput } from "flowbite-react";
import StepperComponent from "../../../Components/StepperComponent";
import ConsultantSideBar from "./ConsultantSideBar";
import { useState } from "react";
import { Certificate } from "crypto";
import { toast } from "react-toastify";
import AxiosInstance from "../../../Utils/AxiosInstance";
import { IoIosCloseCircle } from "react-icons/io";

function ConsultantKYCInfo(props: any) {
  const [kycDetails, setKYCDetails]: any = useState({
    aadhar: props.userInfo.kycDetails ? props.userInfo.kycDetails.aadhar : "",
    pan: props.userInfo.kycDetails ? props.userInfo.kycDetails.pan : "",
    qualification: props.userInfo.kycDetails
      ? props.userInfo.kycDetails.qualification
      : "Graduate",
    license: props.userInfo.kycDetails
      ? props.userInfo.kycDetails.license
      : null,
    certificate: props.userInfo.kycDetails
      ? props.userInfo.kycDetails.certificate
      : "False",
    degree: props.userInfo.kycDetails ? props.userInfo.kycDetails.degree ? props.userInfo.kycDetails.degree : ["BCA"] : ["BCA"]
  });

  const handleValueChange = (field: any, value: any) => {
    if (field === "aadhar" && value.length > 12) {
      return;
    }
    if (field === "pan" && value.length > 10) {
      return;
    }

    if (field === "aadhar" && isNaN(value)) {
      return;
    }

    if (field === "pan") {
      if (value.length < 6) {
        let Chars = value.split("")
        for (let index = 0; index < Chars.length; index++) {
          if (!isNaN(Chars[index])) {
            return
          }
        }
      }
      if (value.length > 5 && value.length < 10) {
        let valuesChar = value.split("")
        let Chars = valuesChar.slice(0, 5)
        let number = valuesChar.slice(5, value.length).join("")
        for (let index = 0; index < Chars.length; index++) {
          if (!isNaN(Chars[index])) {
            return
          }
        }
        if (isNaN(number)) {
          return
        }
      }
      if (value.length === 10) {
        let valuesChar = value.split("")
        let Chars = valuesChar.slice(0, 5)
        let number = valuesChar.slice(5, 9).join("")
        let lastChar = valuesChar[9]
        for (let index = 0; index < Chars.length; index++) {
          if (!isNaN(Chars[index])) {
            return
          }
        }
        if (!isNaN(lastChar)) {
          return
        }
        if (isNaN(number)) {
          return
        }
      }
    }

    let tempData = kycDetails;
    tempData[field] = value;
    setKYCDetails({ ...tempData });
  };


  const submitKYCDetails = (event: any) => {
    event.preventDefault();
    if (kycDetails.aadhar.length !== 12) {
      toast.error("Invalid Aadhar Number.")
      return
    }
    if (kycDetails.pan.length !== 10) {
      toast.error("Invalid Pan Number.")
      return
    }


    let degreeSet = new Set(kycDetails.degree)
    if (degreeSet.size !== kycDetails.degree.length) {
      toast.error("Duplicate Degrees added please remove the duplicate degree to continue.")
      return
    }
    AxiosInstance()
      .post("/consultant/updateConsultantKYCDetails", kycDetails)
      .then(({ data }) => {
        props.onSuccess();
      })
      .catch((err) => {
        toast.error("Failed to update kyc details, please try again");
      });
  };

  const degrees = [
    "BCA",
    "MCA",
    "BBA",
    "MBA",
    "LLB",
    "LLM",
    "B. Com",
    "M. Com",
    "Chartered Accountant (CA)",
    "Company Secretary (CS)",
    "Cost and Management Accountancy (CMA)",
    "BA LLB",
    "B. Com LLB",
    "Certified Financial Planner",
    "Investment Advisor",
    "Research Analyst"
  ]

  const addMoreDegree = () => {
    let defaultValue = ""
    for (let index = 0; index < degrees.length; index++) {
      if (kycDetails.degree.indexOf(degrees[index]) === -1) {
        defaultValue = degrees[index]
        break
      }
    }

    let tempData: any = kycDetails
    tempData.degree.push(defaultValue)
    setKYCDetails({ ...tempData })
  }

  const handleDegreeChange = (value: any, index: any) => {
    let tempData: any = kycDetails
    tempData.degree[index] = value
    setKYCDetails({ ...tempData })
  }

  const handleDegreeRemove = (index: any) => {
    console.log(index)
    let tempData = kycDetails
    tempData.degree.splice(index, 1)
    setKYCDetails({ ...tempData })
  }

  let qualifications = ["Graduate", "Post Graduate", "P.Hd."];

  return (
    <ConsultantSideBar>
      <div className="m-4">
        <div className="text-3xl font-bold text-[#547AF7]">
          Let's get your Personal Details
        </div>
        <div className="mt-1 font-light text-lg">
          Adding information about your profile helps us driving credibility -
          None of this information will be shared without your consent
        </div>
        {props.onBoarding && (
          <>
            <div className="m-4 pl-8 pr-8 hidden lg:flex">
              {" "}
              <StepperComponent
                steps={[
                  "Personal Details",
                  "KYC Details",
                  "Professional Details",
                  "Summary",
                ]}
                activeStep={1}
              />
            </div>
            <div className="mt-4 mb-4 text-2xl font-extrabold lg:hidden">
              {" "}
              STEP: 2/4
            </div>
          </>
        )}
        <div className="mt-4">
          <form
            className="grid grid-cols-1 gap-4 lg:grid-cols-3"
            id="PersonalDetailsForm"
            onSubmit={submitKYCDetails}
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Aadhar Number" />
              </div>
              <TextInput
                id="aadhar"
                type="text"
                placeholder="Enter aadhar Number"
                value={kycDetails.aadhar}
                maxLength={12}
                onChange={(e) => {
                  handleValueChange("aadhar", e.target.value);
                }}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="PAN Number" />
              </div>
              <TextInput
                id="pan"
                type="text"
                placeholder="Enter PAN number"
                value={kycDetails.pan}
                maxLength={10}
                onChange={(e) => {
                  handleValueChange("pan", e.target.value);
                }}
                required
              />
            </div>
            <div className="max-w-md">
              <div className="mb-2 block">
                <Label htmlFor="countries" value="Highest Qualification" />
              </div>
              <Select
                id="countries"
                defaultValue={kycDetails.qualification}
                onChange={(e) => {
                  handleValueChange("qualification", e.target.value);
                }}
                required
              >
                {qualifications.map((qualification) => (
                  <option>{qualification}</option>
                ))}
              </Select>
            </div>
            <div className="max-w-md">
              <div className="mb-2 flex justify-between items-center">
                <Label htmlFor="countries" value="Degree (Max 3)" />
                <div onClick={() => {
                  if (kycDetails.degree.length < 3) {
                    addMoreDegree()
                  }

                }} className={`text-xs ${kycDetails.degree.length < 3 ? "text-blue-700 cursor-pointer " : ""}`}>Add more</div>
              </div>
              {
                kycDetails.degree.map((degreeSelected: any, index: any) => (
                  <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""}`}>
                    <Select
                      id="countries"
                      defaultValue={degreeSelected}
                      onChange={(e) => {
                        handleDegreeChange(e.target.value, index);
                      }}
                      required
                    >
                      {degrees.map((qualification) => (
                        <option>{qualification}</option>
                      ))}
                    </Select>
                    <div>
                      {index !== 0 && (
                        <IoIosCloseCircle className="text-red-700 cursor-pointer" onClick={() => { handleDegreeRemove(index) }} />
                      )}
                    </div>
                  </div>
                ))
              }

            </div>
            {props.onBoarding && <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="email1"
                  value="Holding Certificate of Practice"
                />
              </div>
              <div className="flex items-center gap-8 flex-row">
                <div className="flex items-center gap-2">
                  <Radio
                    id="united-state"
                    name="countries"
                    value="True"
                    onChange={(e) => {
                      handleValueChange("certificate", "True");
                    }}
                    defaultChecked={kycDetails.certificate === "True"}
                  />
                  <Label htmlFor="united-state">Yes</Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio
                    id="united-state"
                    name="countries"
                    value="False"
                    onChange={(e) => {
                      handleValueChange("certificate", "False");
                    }}
                    defaultChecked={kycDetails.certificate === "False"}
                  />
                  <Label htmlFor="united-state">No</Label>
                </div>
              </div>
            </div>}
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="email1"
                  value="Institute Membership/Registration Number"
                />
              </div>
              <TextInput
                id="occuption"
                type="text"
                placeholder="Enter License Number"
                onChange={(e) => {
                  handleValueChange("license", e.target.value);
                }}
                defaultValue={kycDetails.license}
                required
              />
            </div>
          </form>
        </div>
        <div className="mt-4 flex justify-center gap-4 lg:justify-end">
          {props.onBoarding && (
            <Button
              className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
              size="xs"
              onClick={() => {
                props.setState("NEW_ACCOUNT");
              }}
            >
              Previous
            </Button>
          )}
          <Button
            className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
            size="xs"
            form="PersonalDetailsForm"
            type="submit"
          >
            {props.onBoarding ? "Next" : "Update"}
          </Button>
        </div>
      </div>
    </ConsultantSideBar>
  );
}
export default ConsultantKYCInfo;
