import SidebarImage from "../../../Images/ConsultantSidebar.svg";

import { FaVideo } from "react-icons/fa";
import { FaRegIdCard } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { TbLogout2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import { useState } from "react";

import TaxViewLogo from "../../../Images/TaxviewLogo.svg";

function ConsultantSideBar(props: any) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="h-dvh w-screen">
      <div
        className={`h-full w-11/12 justify-center items-center gap-4 absolute bg-no-repeat bg-auto bg-[#f9f9ff] ${
            showMenu ? "flex" : "hidden"} lg:flex flex-col lg:w-1/4 z-40 lg:top-0 lg:left-0 top-10`}
        style={{ backgroundImage: `url(${SidebarImage}` }}
      >
        {props.nav ? (
          <div className="flex justify-center items-center flex-col w-full gap-4 absolute bottom-14 lg:bottom-10">
            <div
              className="flex items-center gap-4 justify-start w-5/12 font-bold text-opacity-75 cursor-pointer"
              onClick={() => {
                navigate("/consultant/dashboard");
              }}
            >
              <FaVideo />
              <div>Sessions</div>
            </div>
            <div
              className="flex items-center gap-4 justify-start w-5/12 font-bold text-opacity-75 cursor-pointer"
              onClick={() => {
                navigate("/consultant/clients");
              }}
            >
              <FaRegIdCard />
              <div>Clients</div>
            </div>
            <div
              className="flex items-center gap-4 justify-start w-5/12 font-bold text-opacity-75 cursor-pointer"
              onClick={() => {
                navigate("/consultant/profile");
              }}
            >
              <FaUserAlt />
              <div>My Account</div>
            </div>
            <div
              className="flex items-center gap-4 justify-start w-5/12 font-bold text-opacity-75 cursor-pointer"
              onClick={() => {
                localStorage.clear();
                navigate("/");
              }}
            >
              <TbLogout2 />
              <div>Logout</div>
            </div>
          </div>
        ):(<div className="flex justify-center items-center flex-col w-full gap-4 absolute bottom-14 lg:bottom-10">
<div
              className="flex items-center gap-4 justify-start w-5/12 font-bold text-opacity-75 cursor-pointer"
              onClick={() => {
                localStorage.clear();
                navigate("/");
              }}
            >
              <TbLogout2 />
              <div>Logout</div>
            </div>
          
        </div>)}
      </div>
      <div className="flex flex-col lg:flex-row h-full w-full static">
        <div className="items-center gap-2 w-full lg:w-1/4 h-auto static">
          <div
            id="menu-bar"
            className={`pl-4 pt-2 flex flex-row gap-2 items-center items-start lg:hidden static `}
          >
            {props.nav && (
              <IoMdMenu
                className="cursor-pointer"
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              />
            )}

            <img src={TaxViewLogo} className="h-10 h-6" />
          </div>
        </div>
        <div className="h-full w-full lg:ml-8 lg:w-5/6 overflow-auto">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default ConsultantSideBar;
