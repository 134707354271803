import { useNavigate, useSearchParams } from "react-router-dom";
import NavigationBar from "../Components/NavigationBar";
import { useEffect, useState } from "react";
import AxiosInstance from "../Utils/AxiosInstance";
import SpinnerComponent from "../Components/SpinnerComponent";
import Success from "../Images/Success.svg";
import Failed from "../Images/Failed.png";
import { Avatar, Rating } from "flowbite-react";
import moment from "moment";
import { timeMap } from "../Components/Costing";

function BookingConfirmation() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [bookingSuccess, setBookingSuccess] = useState(
    searchParams.get("success") === "true"
  );
  const paid = searchParams.get("paid") === "true";
  const success = searchParams.get("success") === "true";
  const services = searchParams.get("service")?.split(",");
  const slotId = searchParams.get("bookingSlot");
  const [loading, setLoading] = useState(false);
  const [slotInfo, setSlotInfo]: any = useState({});

  const bookslot = () => {
    setLoading(true);
    AxiosInstance()
      .post("/users/bookSlot", {
        slotId: slotId,
        services: services,
        duration: paid ? 60 : 30,
        paid: paid,
      })
      .then(({ data }) => {
        setSlotInfo(data);
        setLoading(false);
        setBookingSuccess(true);
        localStorage.setItem("firstSlot", "False");
      })
      .catch((err) => {
        setLoading(false);
        setBookingSuccess(false);
      });
  };

  function confirmExit() {
    alert("exiting");
    window.location.href = "/dashboard";
    return true;
  }
  

  useEffect(() => {
    if (success) {
      bookslot();
    }
    window.onbeforeunload = confirmExit;
  }, []);

  const navigate = useNavigate();

  return (
    <NavigationBar>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <div className="w-full h-full lg:h-dvh flex flex-col items-center justify-center">
          {bookingSuccess ? (
            <div className="flex flex-col items-center">
              <img src={Success} />
              <div className="flex items-center gap-4 pr-2 pl-2 ">
                <div className="pt-6">
                  <Avatar
                    img={slotInfo.consultantInfo?.profilePic}
                    rounded
                    className="z-0"
                  />
                </div>
                <div>
                  <div className="font-bold">
                  {slotInfo.consultantInfo?.prefix}{" "}{slotInfo.consultantInfo?.username}
                  </div>
                  <div className="font-light text-xs">
                    {slotInfo.consultantInfo?.experience} Years experience
                  </div>
                  <Rating size="sm" className="mt-1">
                    <Rating.Star
                      filled={slotInfo.consultantInfo?.rating >= 1}
                    />
                    <Rating.Star
                      filled={slotInfo.consultantInfo?.rating >= 2}
                    />
                    <Rating.Star
                      filled={slotInfo.consultantInfo?.rating >= 3}
                    />
                    <Rating.Star
                      filled={slotInfo.consultantInfo?.rating >= 4}
                    />
                    <Rating.Star
                      filled={slotInfo.consultantInfo?.rating >= 5}
                    />
                  </Rating>
                </div>
              </div>
              <div className="text-xl font-bold mt-4">
                We have booked your session on{" "}
                {moment(slotInfo.date, "DD-MM-YYYY").format("DD MMM YY")}{" "}
                {timeMap[slotInfo.time]}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center">
              <img src={Failed} />
              <div className="text-xl font-bold mt-4">
                Failed to book your session. Please try again later
              </div>
            </div>
          )}
          <div
            className="text-lg font-bold text-[#547AF7] cursor-pointer"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Back to Home
          </div>
        </div>
      )}
    </NavigationBar>
  );
}

export default BookingConfirmation;
