import React, { useState } from "react";
import { FaCircleDot } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";

interface StepperComponentProps {
  steps: string[];
  activeStep: number;
}

const StepperComponent: React.FC<StepperComponentProps> = (props: any) => {
  const { steps, activeStep } = props;

  console.log(steps, activeStep);
  return (
    <div className="flex items-center justify-center h-20 w-full">
      {steps.map((step: string, index: number) => {
        return (
          <React.Fragment key={index}>
            <div className="flex flex-col items-center relative justify-center">
              {index < activeStep && (
                <FaCircleCheck className="text-green-800" />
              )}
              {index === activeStep && (
                <FaCircleDot className="text-[#547AF7]" />
              )}
              {index > activeStep && (
                <div className="h-3 w-3 bg-gray-200 rounded-full" />
              )}
              <div
                className={`text-sm absolute top-7 w-44 ml-16 ${
                  index < activeStep ? "text-green-800 text-bold" : ""
                } ${index > activeStep ? "text-gray-500" : ""} ${
                  index === activeStep ? "text-[#547AF7]" : ""
                }`}
              >
                {step}
              </div>
            </div>

            {index !== steps.length - 1 && (
              <div className="flex-grow border-t-2 border-gray-200 mx-2"></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StepperComponent;
