import axios from "axios";

const backendApi = "https://backend.taxview.co.in";
//const backendApi = "http://localhost:4000";

export default function AxiosInstance(passedHeaders: any = null) {
    let headers: any = passedHeaders ? passedHeaders : {};

    if (localStorage.token) {
        headers.Authorization = `Bearer ${localStorage.token}`;

    }

    const axiosInstance = axios.create({
        baseURL: backendApi,
        headers,
    });

    function clearTokenAndRedirectToHome() {
        let userType = localStorage.userType
        localStorage.clear();
        if (userType) {
            //@ts-ignore
            window.location = "/consultant";
        }
        else {
            //@ts-ignore
            window.location = "/";
        }
    }

    axiosInstance.interceptors.request.use(
        (request) => {
            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => {
            if (
                error.request.responseType === "blob" &&
                error.response.data.type.toLowerCase().indexOf("json") !== -1
            ) {
                return new Promise(async (resolve, reject) => {
                    const bufferArray = await error.response.data.text();
                    const err = JSON.parse(bufferArray);
                    reject({ open: true, type: "error", message: err.error });
                });
            }
            if (error.message === "Network Error") {
                return new Promise((resolve, reject) => {
                    reject({ open: true, type: "error", message: "Api Not Working" });
                });
            }

            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject({
                        open: true,
                        type: "error",
                        message: error.response.data.error,
                    });
                });
            }

            if (error.response.status === 401) {
                clearTokenAndRedirectToHome();
                return new Promise((resolve, reject) => {
                    reject({
                        open: true,
                        type: "error",
                        message: error.response.data.message,
                    });
                });
            } else {
                return new Promise((resolve, reject) => {
                    reject({
                        open: true,
                        type: "error",
                        message: error.response.data.error || error.response.data.message,
                    });
                });
            }

            // reject(error);
        }
    );

    return axiosInstance;
}
