import IncomeTaxFilling from "../Images/InccomTaxFiling.svg";
import TaxPlaning from "../Images/TaxPlaning.svg";
import ITNotice from "../Images/ITNotice.svg";
import GSTFilling from "../Images/GSTFilling.svg";
import DSC from "../Images/DSC.svg";
import LegalAdvice from "../Images/LegalAdvice.svg";
import ITAppeal from "../Images/IT Appeal.svg";
import MF from "../Images/MF.svg";
import GST from "../Images/GST.svg";

function Category(props: any) {
  const { onCategorySelect } = props;
  console.log(onCategorySelect);

  return (
    <div className="mt-2 lg:mt-4 grid grid-cols-1 gap-4 lg:grid-cols-3">
      <div
        onClick={() => {
          onCategorySelect("Income Tax");
        }}
        className="flex items-center gap-2 p-3 rounded-xl border-[0.5px] border-[#E3E3E3] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0"
      >
        <div>
          <img src={IncomeTaxFilling} />
        </div>
        <div>
          <div className="text-lg font-bold">Income Tax</div>
          <div className="text-sm font-light mt-1">
            Effortlessly file your income tax returns with expert guidance.
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          onCategorySelect("NRI Corner");
        }}
        className="flex items-center gap-2 p-3 rounded-xl border-[0.5px] border-[#E3E3E3] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0"
      >
        <div>
          <img src={TaxPlaning} />
        </div>
        <div>
          <div className="text-lg font-bold">NRI Corner</div>
          <div className="text-sm font-light mt-1">
          Find the best consultants for your NRI taxes and other types of taxes.
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          onCategorySelect("Business Registration");
        }}
        className="flex items-center gap-2 p-3 rounded-xl border-[0.5px] border-[#E3E3E3] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0"
      >
        <div>
          <img src={DSC} />
        </div>
        <div>
          <div className="text-lg font-bold">Business Registration</div>
          <div className="text-sm font-light mt-1">
          Setup your business right from documentation to launch all in a go
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          onCategorySelect("Legal");
        }}
        className="flex items-center gap-2 p-3 rounded-xl border-[0.5px] border-[#E3E3E3] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0"
      >
        <div>
          <img src={LegalAdvice} />
        </div>
        <div>
          <div className="text-lg font-bold">Legal</div>
          <div className="text-sm font-light mt-1">
            Navigate complex legal issues with our expert legal advisory
            services.
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          onCategorySelect("Investments");
        }}
        className="flex items-center gap-2 p-3 rounded-xl border-[0.5px] border-[#E3E3E3] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0"
      >
        <div>
          <img src={MF} />
        </div>
        <div>
          <div className="text-lg font-bold">Investments</div>
          <div className="text-sm font-light mt-1">
            Maximize your investments with tailored mutual fund and debt fund
            advice.
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          onCategorySelect("Insurance");
        }}
        className="flex items-center gap-2 p-3 rounded-xl border-[0.5px] border-[#E3E3E3] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0"
      >
        <div>
          <img src={GST} />
        </div>
        <div>
          <div className="text-lg font-bold">Insurance</div>
          <div className="text-sm font-light mt-1">
            Find the best insurance advice and understand what's best for you
          </div>
        </div>
      </div>
    </div>
  );
}
export default Category;
