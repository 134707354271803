import { useLocation, useNavigate } from "react-router-dom";
import NavigationBar from "../Components/NavigationBar";
import { Avatar, Button, Modal, Rating } from "flowbite-react";
import moment from "moment";
import { costing, timeMap } from "../Components/Costing";
import { sha512 } from "js-sha512";

function Checkout() {
  const location = useLocation();
  const slotDetails = location.state;

  let name: any = localStorage.getItem("username")
    ? localStorage.getItem("username")
    : "";
  let email: any = localStorage.getItem("email")
    ? localStorage.getItem("email")
    : "";
  let key = "dX9bIP";
  let salt = "6ITb0y126BqaUPxktYmFMkbK2VmxJiUk";

  let hash = sha512(
    `${key}|${slotDetails.selectedSlotId}|${slotDetails.totalCost}|Paid CA Slot ${slotDetails.consultantInfo?.prefix} ${slotDetails.consultantInfo?.username}|${name}|${email}|||||||||||${salt}`
  );

  const navigate = useNavigate()

  let url = "https://backend.taxview.co.in"
  return (
    <NavigationBar>
      <div className="text-2xl font-bold">Checkout</div>

      <div className="p-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-2">
          <div className="flex items-center gap-4 pr-2 pl-2 ">
            <div>
              <Avatar
                img={slotDetails.consultantInfo?.profilePic}
                rounded
                className="z-0"
              />
            </div>
            <div>
              <div className="font-bold">
              {slotDetails.consultantInfo?.prefix}{" "}{slotDetails.consultantInfo?.username}
              </div>
              <div className="font-light text-xs">
                {slotDetails.consultantInfo?.experience} Years experience
              </div>
              <Rating size="sm" className="mt-1">
                <Rating.Star filled={slotDetails.consultantInfo?.rating >= 1} />
                <Rating.Star filled={slotDetails.consultantInfo?.rating >= 2} />
                <Rating.Star filled={slotDetails.consultantInfo?.rating >= 3} />
                <Rating.Star filled={slotDetails.consultantInfo?.rating >= 4} />
                <Rating.Star filled={slotDetails.consultantInfo?.rating >= 5} />
              </Rating>
            </div>
          </div>
          <div className="border-t-[0.5px] lg:border-l-[0.5px] lg:border-t-0 p-1 lg:pl-3">
            <div className="text-sm font-light">Date & Time</div>
            <div className="font-bold">
              {moment(slotDetails.selectedDate, "DD-MM-YYYY").format(
                "DD MMM YY"
              )}{" "}
              {timeMap[slotDetails.selectedTime]}
            </div>
          </div>
          <div className="border-t-[0.5px] lg:border-l-[0.5px] lg:border-t-0 p-1 lg:pl-3">
            <div className="text-sm font-light">Duration</div>
            <div className="font-bold">
              {slotDetails.paid ? "1 Hour" : "30 Minutes"}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-4">
          <div className="p-2 border-[0.5px] border-[#547AF7] bg-[#F1F4FD] rounded-xl">
            <div className="text-lg font-bold">Services</div>
            <div className="mt-2 border-t-[0.5px] p-2">
              <div>
                {slotDetails.selectedService.map((expertise: any) => (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">{expertise}</div>
                    <div>{costing[expertise]} &#8377;</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="p-2 border-[0.5px] border-[#547AF7] bg-[#F1F4FD] rounded-xl">
            <div className="text-lg font-bold">Payment Summary</div>
            <div className="text-sm flex justify-between mt-2">
              <div>Payment Subtotal</div>
              <div>{slotDetails.totalCost} &#8377;</div>
            </div>
            <div className="mt-2 border-t-[0.5px] ">
              <div className="text-sm flex justify-between mt-2">
                <div>Payment Total</div>
                <div className="font-bold">{slotDetails.totalCost} &#8377;</div>
              </div>
            </div>
            <div className="flex justify-end mt-2 ">
                {slotDetails.totalCost === 0 ?(<Button
                  className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                  size="xs"
                  onClick={()=>{
                    navigate(`/bookingconfirmation?success=true&paid=false&bookingSlot=${slotDetails.selectedSlotId}&service=Discovery`)
                  }}
                >
                  Pay and Book
                </Button>):(<form action="https://secure.payu.in/_payment" method="POST" encType="application/x-www-form-urlencoded">
                <input type="hidden" name="key" value="dX9bIP" />
                <input
                  type="hidden"
                  name="txnid"
                  value={slotDetails.selectedSlotId}
                />
                <input
                  type="hidden"
                  name="amount"
                  value={slotDetails.totalCost}
                />
                <input type="hidden" name="email" value={email} />
                <input
                  type="hidden"
                  name="productInfo"
                  value={`Paid CA Slot ${slotDetails.consultantInfo?.prefix} ${slotDetails.consultantInfo?.username}`}
                />
                <input type="hidden" name="firstname" value={name} />
                <input
                  type="hidden"
                  name="surl"
                  value={`${url}/users/paymentRedirection?success=true&paid=true&bookingSlot=${slotDetails.selectedSlotId}&service=${slotDetails.selectedService.toString()}`}
                />
                <input
                  type="hidden"
                  name="furl"
                  value={`${url}/users/paymentRedirection?success=false&paid=true&bookingSlot=${slotDetails.selectedSlotId}`}
                />
                <input type="hidden" name="hash" value={hash} />
                <Button
                  className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                  size="xs"
                  type="submit"
                >
                  Pay and Book
                </Button>
              </form>)}
              
            </div>
          </div>
        </div>
      </div>
    </NavigationBar>
  );
}

export default Checkout;
