import "../CSS/NewHomePage.css";
import caimage1 from "../Images/caimage1.jpg"
import caimage2 from "../Images/caimage2.jpg"
import caimage3 from "../Images/caimage3.jpg"

import Suraj from "../Images/Suraj.jpg"
import Shravan from "../Images/shravan.jpg"
import Sheetal from "../Images/sheetal.jpg"
import Aditya from "../Images/aditya.jpg"
import Unnati from "../Images/unnati.jpg"
import Atharv from "../Images/Atharv.jpg"

import Ajit from "../Images/Ajit.jpg"
import Heena from "../Images/Heena.jpg"
import Jay from  "../Images/Jay.jpg"
import Smitha from "../Images/Smitha.jpg"
import TaxviewLogo from "../Images/TaxviewLogo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Farid from "../Images/Farid.jpg"
import Vineet from "../Images/Vineet.jpg"
import Vikas from "../Images/Vikas.jpg"
import Kritika from "../Images/Kritika.jpg"

import gst from "../Images/gst.png"
import filling from "../Images/Filling.png"
import compnayinc from "../Images/companyinc.png"
import financialPlaning from "../Images/finacialplanning.png"
import investadv from "../Images/investmentadv.png"
import lifeinc from "../Images/lifeinc.png"
import medicaInc from "../Images/medicalinc.png"
import generalInc from "../Images/generalinc.png"
import claim from "../Images/claim.png"
import agreement from "../Images/agreement.png"
import trade from "../Images/Trade.png"
import property from "../Images/property.png"
import register from "../Images/register.png"
import planing from "../Images/planning.png"
import notice from "../Images/notice.png"
import tax from "../Images/tax.png"
import taxes from "../Images/taxes.png"
import compliance from "../Images/compliance.png"
import stamp from "../Images/stamp.png"

function DesktopHomePage(props:any) {
  const [selectedService,setSelectedService] = useState("Income Tax");

  const navigate = useNavigate()
  const services:any = {
    "Income Tax":[
      {
        name:"ITR Filing",
        desc:"Get expert help for accurate and Stress- free tax filing",
        icon:filling
      },
      {
        name:"Tax Planning",
        desc:"Maximize your savings with expert tax planning assistance",
        icon:planing
      },
      {
        name:"Notices & Scrutiny",
        desc:"Resolve notices of all types and scrutiny efficiently",
        icon:notice
      },
      {
        name:"IT Appeals",
        desc:"Navigate IT appeals confidently",
        icon:register
      }
    ],
    "Business Registrations":[
      {
        name:"GST Registration",
        desc:"GST Registration Online by Certified GST Practitioner",
        icon: gst
      },
      {
        name:"GST Filing",
        desc:"Stay compliant with seamless GST return filing service",
        icon:filling
      },
      {
        name:"Company Incorporation",
        desc:"Achieve hassle-free company incorporation with specialized guidance",
        icon:compnayinc
      },
      {
        name:"License Registrations",
        desc:"Get Expert help for smooth Business license registration",
        icon:register
      }
    ],
    "Investments":[
      {
        name:"Financial Planning",
        desc:"Get a personalized financial plan, start-to-finish, for all your financial goals",
        icon:financialPlaning
      },
      {
        name:"Investment Advisory",
        desc:"Gain strategic investment advice customized to your financial objectives",
        icon:investadv
      },
      {
        name:"Research Services",
        desc:"Unlock Valuable insights with specialized research services",
        icon:register
      },
      {
        name:"Portfolio Management",
        desc:"Manage you portfolio to get the best returns, with the help of our experts",
        icon:planing
      }
    ],
    "Insurance":[
      {
        name:"Life Insurance",
        desc:"Get assistance in selecting the best policy",
        icon:lifeinc
      },
      {
        name:"Medical Insurance",
        desc:"Get assistance in selecting the best policy",
        icon:medicaInc
      },
      {
        name:"General Insurance",
        desc:"Get assistance in selecting the best policy",
        icon: generalInc
      },
      {
        name:"Claim Assistance",
        desc:"Get assistance in filling out claim forms accurately, reducing the chances of errors that could lead to claim rejection",
        icon:claim
      }
    ],
    "Legal":[
      {
        name:" Agreement Consulting",
        desc:"Ensure Legally sound agreements with our consulting and drafting service",
        icon:agreement
      },
      {
        name:"Trademark & Patent",
        desc:"Protect your innovations with expert trademark and patent registration consultancy",
        icon:trade
      },
      {
        name:"Property Disputes",
        desc:"Get Expert view on Property Disputes matters.",
        icon:property
      },
      {
        name:"Consumer Forum Complaints",
        desc:"Experts assistance in Filing Consumer Forum complaints",
        icon:register
      }
    ],
    "NRI Corner":[
      {
        name:"NRI ITR Filing",
        desc:"NRI tax preparation and filling by experts",
        icon:tax
      },
      {
        name:"FEMA Compliances",
        desc:"Ensure seamless FEMA Compliances with expert guidance",
        icon:stamp
      },
      {
        name:"Lower TDS Deduction",
        desc:"Minimize Tax Deductions with form 13",
        icon:compliance
      },
      {
        name:"Tax Residency Certificate",
        desc:"Obtain your tax residency certificate smoothly",
        icon:taxes
      }
    ]
  }
  return (
    <div className="parent-div ">
      <div className="pos-abs innovatexpofram-11885">
        {/* taxview-sitelogo-1.png1 */}
        <section className="pos-abs taxviewsitelogo-12137">
          <img
            src="https://s3-alpha-sig.figma.com/img/df02/dccd/d77ce90b7694ecdbb8b576ed2e916414?Expires=1721001600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QxZHN~6GQzuDAot83Hju-nqll9MHmET5qYIlphQWG91t7r-8tHbuUo6P0q6DOex6Syr0kv8lsURVDLCXiWbR1FKXeun5xevA97e6Xz2nKCkbIx3oeMjKQYeByGgn3btQlFAA5cnfjp9ShFbDvHiotkVB4rquqhNDAl1H1~z8gK2Ui6fZaa1EF1KhersIOJKdsGx0wNZgGZsWzsIey-SI45F27CaMYfJT0sFo7C8pytLg2P~BiPcViaRF8I1hWruzjgx4pqcNYIjVlw82fXoPb0MKmhvHPSSNa4N91v00l0N64SqsS4uafuImPrJ5RIny6HAnItpXSag0sT-MtM-FJw__"
            className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-12137 object-fit "
            alt="taxview-sitelogo-1.png1 Image"
          />{" "}
        </section>
        {/* Background4 */}
        <section className="pos-abs background-12096"></section>
        {/* Frame 61 */}
        <section className="pos-abs frame-6-12023 cursor-pointer" onClick={()=>{
            props.openLogin()
          }}>
          {/* <div className="pos-abs background-12025">
            <div className="pos-abs bpysgyl5w3nhfbn-12026">
              <div className="pos-abs bpysgyl5w3nhfbn-12027">
                <div className="pos-abs bpysgyl5w3nhfbn-12028">
                  <div className="pos-abs vector-12029">
                    <div className="nodeBg-12029 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="pos-abs book-a-session-12024">
            <span className="book-a-session-12024-0 ">{`Login`}</span>
          </div>
        </section>
        {/* Frame 951 */}
        <section className="pos-abs frame-95-12030 cursor-pointer" onClick={()=>{
          props.openRequestCallback()
        }} >
          <div className="pos-abs phonetelephonea-12032">
            <div className="pos-abs vector-12033">
              <div className="nodeBg-12033 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
          </div>
          <div className="pos-abs request-a-call--12031">
            <span className="request-a-call--12031-0 ">{`Request a call back`}</span>
          </div>
        </section>
        {/* Frame 952 */}
        <section className="pos-abs frame-95-172">
          <div className="pos-abs taxviewsitelogo-173">
            <img
              src={TaxviewLogo}
              className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-173 object-fit "
              alt="taxview-sitelogo-1.png1 Image"
            />{" "}
          </div>
        </section>
        {/* Header1 */}
        <section className="pos-abs header-11886">
          <div className="pos-abs ellipse-1-11887"></div>
          <div className="pos-abs frame-5-11889">
            <div className="pos-abs frame-4-11890">
              <div className="pos-abs connect-with-to-11891">
                <span className="connect-with-to-11891-0 ">{`Connect with `}</span>
                <span className="connect-with-to-11891-1 ">{`Top Experts`}</span>
                <span className="connect-with-to-11891-2 ">{` for Tax, Legal, Wealth and more
`}</span>
                <span className="connect-with-to-11891-3 ">{`in seconds`}</span>
              </div>
            </div>
            <div className="pos-abs frame-6-11892 cursor-pointer" onClick={()=>{
              props.openClientLogin()
            }}>
              <div className="pos-abs frame-2-11893">
                <div className="pos-abs background-11895">
                  <div className="pos-abs bpysgyl5w3nhfbn-11896">
                    <div className="pos-abs bpysgyl5w3nhfbn-11897">
                      <div className="pos-abs bpysgyl5w3nhfbn-11898">
                        <div className="pos-abs vector-11899">
                          <div className="nodeBg-11899 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs book-a-session-11894">
                  <span className="book-a-session-11894-0 ">{`Book a Free Session`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs group-12-11901">
            <div className="pos-abs group-9-11924">
              <div className="pos-abs omshfdvcmytznsc-11925">
                <img
                  src={Farid}
                  className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-11925 object-fit "
                  alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
                />
                <div className="pos-abs rectangle-42247-11926"></div>
                <div className="pos-abs frame-64-11927">
                  <div className="pos-abs jessica-merchan-11928">
                    <span className="jessica-merchan-11928-0 ">{`CA Farid Panjwani`}</span>
                  </div>
                  <div className="pos-abs frame-61-11929">
                    <div className="pos-abs c-5-year-of-exper-11933">
                      <span className="c-5-year-of-exper-11933-0 ">{`5 Year of Experience`}</span>
                    </div>
                    <div className="pos-abs clock01-11930">
                      <div className="pos-abs vector-11931">
                        <div className="nodeBg-11931 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-11932">
                        <div className="nodeBg-11932 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs frame-65-11940">
                  <div className="pos-abs briefcase02-11941">
                    <div className="pos-abs vector-11944">
                      <div className="nodeBg-11944 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11942">
                      <div className="nodeBg-11942 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11943">
                      <div className="nodeBg-11943 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                  <div className="pos-abs gst-specialist-11945">
                    <span className={`gst-specialist-11945-0`}>{`ITR Filling,TDS`}</span>
                  </div>
                </div>
                <div className="pos-abs frame-63-11946">
                  <div className="pos-abs usergroup-11947">
                    <div className="pos-abs vector-11951">
                      <div className="nodeBg-11951 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11949">
                      <div className="nodeBg-11949 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11948">
                      <div className="nodeBg-11948 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11950">
                      <div className="nodeBg-11950 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                  <div className="pos-abs c-100-happy-clien-11952">
                    <span className="c-100-happy-clien-11952-0 ">{`150+ Happy Clients `}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs group-10-11902">
              <div className="pos-abs omshfdvcmytznsc-11903">
                <img
                  src={Vineet}
                  className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-11903 object-fit "
                  alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
                />
                <div className="pos-abs rectangle-42247-11904"></div>
                <div className="pos-abs frame-66-11905">
                  <div className="pos-abs jessica-merchan-11906">
                    <span className="jessica-merchan-11906-0 ">{`CA Vineet Gurbani`}</span>
                  </div>
                </div>
                <div className="pos-abs frame-67-11907">
                  <div className="pos-abs c-5-year-of-exper-11911">
                    <span className="c-5-year-of-exper-11911-0 ">{`5 Year of Experience`}</span>
                  </div>
                  <div className="pos-abs clock01-11908">
                    <div className="pos-abs vector-11909">
                      <div className="nodeBg-11909 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11910">
                      <div className="nodeBg-11910 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs frame-68-11912">
                  <div className="pos-abs gst-specialist-11917">
                    <span className="gst-specialist-11917-0 ">{`Financial Planning`}</span>
                  </div>
                  <div className="pos-abs briefcase02-11913">
                    <div className="pos-abs vector-11916">
                      <div className="nodeBg-11916 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11914">
                      <div className="nodeBg-11914 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11915">
                      <div className="nodeBg-11915 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs usergroup-11918">
                  <div className="pos-abs vector-11922">
                    <div className="nodeBg-11922 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-11920">
                    <div className="nodeBg-11920 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-11919">
                    <div className="nodeBg-11919 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-11921">
                    <div className="nodeBg-11921 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs c-100-happy-clien-11923">
                  <span className="c-100-happy-clien-11923-0 ">{`50+ Happy Clients `}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs background-11900"></div>
          <div className="pos-abs group-11-11953">
            <div className="pos-abs omshfdvcmytznsc-11954">
              <img
                src={caimage1}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-11954 object-fit "
                alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
              />
              <div className="pos-abs rectangle-42247-11955"></div>
              <div className="pos-abs frame-66-11956">
                <div className="pos-abs jessica-merchan-11957">
                  <span className="jessica-merchan-11957-0 ">{`CA Muskan Rathi`}</span>
                </div>
              </div>
              <div className="pos-abs frame-67-11958">
                <div className="pos-abs c-5-year-of-exper-11962">
                  <span className="c-5-year-of-exper-11962-0 ">{`2 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-11959">
                  <div className="pos-abs vector-11960">
                    <div className="nodeBg-11960 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-11961">
                    <div className="nodeBg-11961 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-68-11963">
                <div className="pos-abs gst-specialist-11968">
                  <span className="gst-specialist-11968-0 ">{`ITR & Tax Panning`}</span>
                </div>
                <div className="pos-abs briefcase02-11964">
                  <div className="pos-abs vector-11967">
                    <div className="nodeBg-11967 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-11965">
                    <div className="nodeBg-11965 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-11966">
                    <div className="nodeBg-11966 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs usergroup-11969">
                <div className="pos-abs vector-11973">
                  <div className="nodeBg-11973 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-11971">
                  <div className="nodeBg-11971 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-11970">
                  <div className="nodeBg-11970 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-11972">
                  <div className="nodeBg-11972 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-11974">
                <span className="c-100-happy-clien-11974-0 ">{`50+ Happy Clients `}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs ellipse-2-11888"></div>
        </section>
        {/* Background5 */}
        <section className="pos-abs background-12097"></section>
        {/* Background3 */}
        <section className="pos-abs background-12095"></section>
        {/* Background2 */}
        <section className="pos-abs background-12094"></section>
        {/* Background6 */}
        <section className="pos-abs background-12098"></section>
        {/* Background7 */}
        <section className="pos-abs background-12099"></section>
        {/* Background1 */}
        <section className="pos-abs background-11977">
          <div className="pos-abs frame-17-11981">
            <div className="pos-abs frame-14-11982">
              <div className="pos-abs frame-10-11983">
                <div className="pos-abs group-5-11984">
                  <div className="pos-abs ellipse-13-11985"></div>
                  <div className="pos-abs c-1605873928indiv-11986">
                    <div className="pos-abs vector-11988">
                      <div className="nodeBg-11988 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-11987">
                      <div className="nodeBg-11987 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs verified-expert-11989">
                  <span className="verified-expert-11989-0 ">{`Verified experts with 5+ years of experience`}</span>
                </div>
              </div>
              <div className="pos-abs frame-11-11991">
                <div className="pos-abs group-4-11992">
                  <div className="pos-abs ellipse-13-11993"></div>
                  <div className="pos-abs c-1605873617taxsa-11994">
                    <div className="pos-abs group-6-11995">
                      <div className="pos-abs vector-11996">
                        <div className="nodeBg-11996 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12000">
                        <div className="nodeBg-12000 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-11997">
                        <div className="nodeBg-11997 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-11998">
                        <div className="nodeBg-11998 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-11999">
                        <div className="nodeBg-11999 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs personalized-so-12001">
                  <span className="personalized-so-12001-0 ">{`Personalized solutions from experts`}</span>
                </div>
              </div>
              <div className="pos-abs frame-12-12003">
                <div className="pos-abs group-4-12004">
                  <div className="pos-abs ellipse-13-12005"></div>
                  <div className="pos-abs c-1605873617taxsa-12006">
                    <div className="pos-abs group-5-12007">
                      <div className="pos-abs vector-12009">
                        <div className="nodeBg-12009 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12010">
                        <div className="nodeBg-12010 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12008">
                        <div className="nodeBg-12008 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs get-support-for-12011">
                  <span className="get-support-for-12011-0 ">{`Get  support for all your queries`}</span>
                </div>
              </div>
              <div className="pos-abs frame-13-12013">
                <div className="pos-abs group-4-12014">
                  <div className="pos-abs ellipse-13-12015"></div>
                  <div className="pos-abs c-1605873617taxsa-12016">
                    <div className="pos-abs group-7-12017">
                      <div className="pos-abs vector-12018">
                        <div className="nodeBg-12018 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12021">
                        <div className="nodeBg-12021 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12020">
                        <div className="nodeBg-12020 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12019">
                        <div className="nodeBg-12019 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs solve-your-prob-12022">
                  <span className="solve-your-prob-12022-0 ">{`Solve your problems in quick and efficient manner`}</span>
                </div>
              </div>
              <div className="pos-abs vector-3-11990">
                <div className="nodeBg-11990 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1-12002">
                <div className="nodeBg-12002 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-2-12012">
                <div className="nodeBg-12012 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
          </div>
          <div className="pos-abs paragraph-11978">
            <div className="pos-abs heading-4--resu-11979">
              <span className="heading-4--resu-11979-0 ">{`Why TaxView?`}</span>
            </div>
            <div className="pos-abs one-stop-soluti-11980">
              <span className="one-stop-soluti-11980-0 ">{`One stop solution for all your Tax / Business / Legal queries from the best experts`}</span>
            </div>
          </div>
        </section>
        {/* Header2 */}
        <section className="pos-abs header-12100">
          <div className="pos-abs frame-18-12101">
            <div className="pos-abs heading-2--serv-12102">
              <span className="heading-2--serv-12102-0 ">{`Services For Everyone and Everything`}</span>
            </div>
            <div className="pos-abs built-for-scale-12103">
              <span className="built-for-scale-12103-0 ">{`Built for scale, made by experts and secure by design. Bringing you maximum tax savings, unmatched
speed and complete peace of mind.`}</span>
            </div>
          </div>
          <div className="pos-abs frame-170-184">
            <div className={`pos-abs background-185 cursor-pointer ${selectedService === "Income Tax" ?"selected-background":""}`} onClick={()=>{setSelectedService("Income Tax")}}>
              <div className="pos-abs income-tax-186">
                <span className={`income-tax-186-0`}>{`Income Tax`}</span>
              </div>
            </div>
            <div className={`pos-abs background-187 cursor-pointer ${selectedService === "Business Registrations" ?"selected-background":""}`} onClick={()=>{setSelectedService("Business Registrations")}}>
              <div className="pos-abs business-regist-188">
                <span className={`business-regist-188-0 `}>{`Business Registrations`}</span>
              </div>
            </div>
            <div className={`pos-abs background-189 cursor-pointer ${selectedService === "Investments" ?"selected-background":""}`} onClick={()=>{setSelectedService("Investments")}}>
              <div className="pos-abs investments-1810">
                <span className={`investments-1810-0 `}>{`Investments`}</span>
              </div>
            </div>
            <div className={`pos-abs background-1811 cursor-pointer ${selectedService === "Insurance" ?"selected-background":""}`} onClick={()=>{setSelectedService("Insurance")}}>
              <div className="pos-abs insurance-1812">
                <span className={`insurance-1812-0 $`}>{`Insurance`}</span>
              </div>
            </div>
            <div className={`pos-abs background-1813 cursor-pointer ${selectedService === "Legal" ?"selected-background":""} `} onClick={()=>{setSelectedService("Legal")}}>
              <div className="pos-abs legal-1814">
                <span className={`legal-1814-0 `}>{`Legal`}</span>
              </div>
            </div>
            <div className={`pos-abs background-1815 cursor-pointer ${selectedService === "NRI Corner" ?"selected-background":""}`} onClick={()=>{setSelectedService("NRI Corner")}}>
              <div className="pos-abs nri-corner-1816">
                <span className={`nri-corner-1816-0 `}>{`NRI Corner`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-172-12104">
            <div className="pos-abs document-1-12105">
              <img
                src={services[selectedService][0]["icon"]}
                className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12105 object-fit "
                alt="document 11 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-171-12106">
              <div className="pos-abs heading-6--clea-12107">
                <span className="heading-6--clea-12107-0 ">{services[selectedService][0]["name"]}</span>
              </div>
              <div className="pos-abs get-expert-help-12108">
                <span className="get-expert-help-12108-0 ">{services[selectedService][0]["desc"]}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-173-12114">
            <div className="pos-abs personal2243183-12115">
              <img
                src={services[selectedService][1]["icon"]}
                className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12115 object-fit "
                alt="personal_2243183 21 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-171-12116">
              <div className="pos-abs heading-6--clea-12117">
                <span className="heading-6--clea-12117-0 ">{services[selectedService][1]["name"]}</span>
              </div>
              <div className="pos-abs maximize-your-s-12118">
                <span className="maximize-your-s-12118-0 ">{services[selectedService][1]["desc"]}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-174-12109">
            <div className="pos-abs notice-1-12110">
              <img
                src={services[selectedService][2]["icon"]}
                alt="notice 11 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-171-12111">
              <div className="pos-abs heading-6--clea-12112">
                <span className="heading-6--clea-12112-0 ">{services[selectedService][2]["name"]}</span>
              </div>
              <div className="pos-abs resolve-notices-12113">
                <span className="resolve-notices-12113-0 ">{services[selectedService][2]["desc"]}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-175-12119">
            <div className="pos-abs lawbook-1-12120">
              <img
                src={services[selectedService][3]["icon"]}
                className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12120 object-fit "
                alt="law-book 11 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-171-12121">
              <div className="pos-abs heading-6--clea-12122">
                <span className="heading-6--clea-12122-0 ">{services[selectedService][3]["name"]}</span>
              </div>
              <div className="pos-abs navigate-it-app-12123">
                <span className="navigate-it-app-12123-0 ">{services[selectedService][3]["desc"]}</span>
              </div>
            </div>
          </div>
        </section>
        {/* Frame 481 */}
        <section className="pos-abs frame-48-12047">
          <div className="pos-abs ellipse-74-12049"></div>
          <div className="pos-abs ellipse-77-12050"></div>
          <div className="pos-abs ellipse-76-12048"></div>
          <div className="pos-abs ellipse-75-12051"></div>
          <div className="pos-abs container-12055">
            <div className="pos-abs background-12056">
              <div className="pos-abs border-12058"></div>
              <div className="pos-abs heading-4--prov-12057">
                <span className="heading-4--prov-12057-0 ">{`Sign-up`}</span>
              </div>
              <div className="pos-abs backgroundshado-12060">
                <div className="pos-abs border-12062"></div>
                <div className="pos-abs heading-5--1-12061">
                  <span className="heading-5--1-12061-0 ">{`1`}</span>
                </div>
              </div>
              <div className="pos-abs login-with-your-12059">
                <span className="login-with-your-12059-0 ">{`Login with your phone number and tell us your name and email id`}</span>
              </div>
            </div>
            <div className="pos-abs background-12063">
              <div className="pos-abs border-12065"></div>
              <div className="pos-abs heading-4--tail-12064">
                <span className="heading-4--tail-12064-0 ">{`Choose Service You Want`}</span>
              </div>
              <div className="pos-abs backgroundshado-12067">
                <div className="pos-abs border-12069"></div>
                <div className="pos-abs heading-5--2-12068">
                  <span className="heading-5--2-12068-0 ">{`2`}</span>
                </div>
              </div>
              <div className="pos-abs select-the-prob-12066">
                <span className="select-the-prob-12066-0 ">{`Select the problems you need consultants on We have qualified experts with 5+ years of experience across taxes, GST and legal advisory`}</span>
              </div>
            </div>
            <div className="pos-abs background-12070">
              <div className="pos-abs border-12072"></div>
              <div className="pos-abs heading-4--resu-12071">
                <span className="heading-4--resu-12071-0 ">{`Select a Preferred Time Slot  `}</span>
              </div>
              <div className="pos-abs backgroundshado-12074">
                <div className="pos-abs border-12076"></div>
                <div className="pos-abs heading-5--3-12075">
                  <span className="heading-5--3-12075-0 ">{`3`}</span>
                </div>
              </div>
              <div className="pos-abs select-a-time-s-12073">
                <span className="select-a-time-s-12073-0 ">{`Select a time slot that works best for you. Along with that choose a service you need`}</span>
              </div>
            </div>
            <div className="pos-abs background-12077">
              <div className="pos-abs border-12079"></div>
              <div className="pos-abs heading-4--resu-12078">
                <span className="heading-4--resu-12078-0 ">{`Connect With Experts`}</span>
              </div>
              <div className="pos-abs backgroundshado-12081">
                <div className="pos-abs border-12083"></div>
                <div className="pos-abs heading-5--3-12082">
                  <span className="heading-5--3-12082-0 ">{`4`}</span>
                </div>
              </div>
              <div className="pos-abs book-a-service--12080">
                <span className="book-a-service--12080-0 ">{`Book a service or schedule a free call to discuss in details about your problem.`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-49-12052">
            <div className="pos-abs heading-2--serv-12053">
              <span className="heading-2--serv-12053-0 ">{`How it works`}</span>
            </div>
            <div className="pos-abs get-an-overview-12054">
              <span className="get-an-overview-12054-0 ">{`Get an overview of how TaxView can help you find the best experts in just 5 mins`}</span>
            </div>
          </div>
          <div className="pos-abs group-8-12084 cursor-pointer" onClick={()=>{
            props.openClientLogin()
          }}>
            <div className="pos-abs link-12085">
              <div className="pos-abs border-12093"></div>
              <div className="pos-abs background-12086">
                <div className="pos-abs border-12087"></div>
                <div className="pos-abs hctnqompeo4zyf3-12088">
                  <div className="pos-abs hctnqompeo4zyf3-12089">
                    <div className="pos-abs hctnqompeo4zyf3-12090">
                      <div className="pos-abs vector-12091">
                        <div className="nodeBg-12091 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs book-a-session--12092">
                <span className="book-a-session--12092-0 ">{`Book A Free Session Now`}</span>
              </div>
            </div>
          </div>
        </section>
        {/* Frame 491 */}
        <section className="pos-abs frame-49-12042">
          <div className="pos-abs heading-2--serv-12043">
            <span className="heading-2--serv-12043-0 ">{`What our clients are saying about us`}</span>
          </div>
          <div className="pos-abs customer-satisf-12044">
            <span className="customer-satisf-12044-0 ">{`Customer satisfaction is a primary goal for our company`}</span>
          </div>
        </section>
        {/* Frame 561 */}
        <section className="pos-abs frame-56-12138">
          <div className="pos-abs frame-53-12139">
            <div className="pos-abs frame-60-12140">
              <div className="pos-abs frame-54-12141">
                <div className="pos-abs div-12142">
                  <span className="div-12142-0 ">{`“`}</span>
                </div>
                <div className="pos-abs omg-i-cannot-be-12143">
                  <span className="omg-i-cannot-be-12143-0 ">{`“I loved how easy it was to explain my expert about the problems I had with respect to taxes. He understood it well and guided me through quickly. Loved the experience”`}</span>
                </div>
              </div>
              <div className="pos-abs frame-51-12144">
                <div className="pos-abs ellipse-4-12145">
                  <img
                    src={Suraj}
                    className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12145 object-fit "
                    alt="Ellipse 41 Image"
                  />{" "}
                </div>
                <div className="pos-abs paragraph-12146">
                  <div className="pos-abs jessica-merchan-12147">
                    <span className="jessica-merchan-12147-0 ">{`Suraj Sharma`}</span>
                  </div>
                  <div className="pos-abs cfo-charity-gal-12148">
                    <span className="cfo-charity-gal-12148-0 ">{`Product Lead, Growth Velocity`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs frame-59-12149">
              <div className="pos-abs frame-57-12150">
                <div className="pos-abs income-tax-retu-12151">
                  <span className="income-tax-retu-12151-0 ">{`Income Tax Return Filling`}</span>
                </div>
              </div>
              <div className="pos-abs frame-58-12152">
                <div className="pos-abs gst-registratio-12153">
                  <span className="gst-registratio-12153-0 ">{`IT Notice`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-58-12154">
            <div className="pos-abs frame-60-12155">
              <div className="pos-abs frame-54-12156">
                <div className="pos-abs div-12157">
                  <span className="div-12157-0 ">{`“`}</span>
                </div>
                <div className="pos-abs omg-i-cannot-be-12158">
                  <span className="omg-i-cannot-be-12158-0 ">{`“I needed help while setting my company. TaxView connected me with the right consultant and she gave me the right advice, the entire process was a breeze. Great work!”`}</span>
                </div>
              </div>
              <div className="pos-abs frame-51-12159">
                <div className="pos-abs ellipse-4-12160">
                  <img
                    src={Shravan}
                    className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12160 object-fit "
                    alt="Ellipse 41 Image"
                  />{" "}
                </div>
                <div className="pos-abs paragraph-12161">
                  <div className="pos-abs jessica-merchan-12162">
                    <span className="jessica-merchan-12162-0 ">{`Shravan`}</span>
                  </div>
                  <div className="pos-abs cfo-charity-gal-12163">
                    <span className="cfo-charity-gal-12163-0 ">{`CEO, Rethink Systems`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs frame-59-12164">
              <div className="pos-abs frame-57-12165">
                <div className="pos-abs income-tax-retu-12166">
                  <span className="income-tax-retu-12166-0 ">{`DSC Services`}</span>
                </div>
              </div>
              <div className="pos-abs frame-58-12167">
                <div className="pos-abs gst-registratio-12168">
                  <span className="gst-registratio-12168-0 ">{`GST Registration`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-12169">
            <div className="pos-abs frame-60-12170">
              <div className="pos-abs frame-54-12171">
                <div className="pos-abs div-12172">
                  <span className="div-12172-0 ">{`“`}</span>
                </div>
                <div className="pos-abs omg-i-cannot-be-12173">
                  <span className="omg-i-cannot-be-12173-0 ">{`“Struggled a lot to file taxes for the first time. TaxView's expert guided me in the right direction.”`}</span>
                </div>
              </div>
              <div className="pos-abs frame-51-12174">
                <div className="pos-abs ellipse-4-12175">
                  <img
                    src={Unnati}
                    className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12175 object-fit "
                    alt="Ellipse 41 Image"
                  />{" "}
                </div>
                <div className="pos-abs paragraph-12176">
                  <div className="pos-abs jessica-merchan-12177">
                    <span className="jessica-merchan-12177-0 ">{`Unnati`}</span>
                  </div>
                  <div className="pos-abs cfo-charity-gal-12178">
                    <span className="cfo-charity-gal-12178-0 ">{`Designer & Product Manager`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs frame-59-12179">
              <div className="pos-abs frame-57-12180">
                <div className="pos-abs income-tax-retu-12181">
                  <span className="income-tax-retu-12181-0 ">{`Income Tax Return Filling`}</span>
                </div>
              </div>
              <div className="pos-abs frame-58-12182">
                <div className="pos-abs gst-registratio-12183">
                  <span className="gst-registratio-12183-0 ">{`Legal Advisory`}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Frame 601 */}
        <section className="pos-abs frame-60-12184">
          <div className="pos-abs frame-60-12185">
            <div className="pos-abs frame-54-12186">
              <div className="pos-abs div-12187">
                <span className="div-12187-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-12188">
                <span className="omg-i-cannot-be-12188-0 ">{`“Loved the experience of finding the right CA for my tax queries, booked a free call, got some clarity and filed taxes the right way :)”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-12189">
              <div className="pos-abs ellipse-4-12190">
                <img
                  src={Sheetal}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12190 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-12191">
                <div className="pos-abs jessica-merchan-12192">
                  <span className="jessica-merchan-12192-0 ">{`Sheetal`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-12193">
                  <span className="cfo-charity-gal-12193-0 ">{`CTO, Afterlife`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-12194">
            <div className="pos-abs frame-57-12195">
              <div className="pos-abs income-tax-retu-12196">
                <span className="income-tax-retu-12196-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-12197">
              <div className="pos-abs gst-registratio-12198">
                <span className="gst-registratio-12198-0 ">{`GST Registration`}</span>
              </div>
            </div>
          </div>
        </section>
        {/* Frame 611 */}
        <section className="pos-abs frame-61-12199">
          <div className="pos-abs frame-60-12200">
            <div className="pos-abs frame-54-12201">
              <div className="pos-abs div-12202">
                <span className="div-12202-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-12203">
                <span className="omg-i-cannot-be-12203-0 ">{`“Before TaxView, I had no way to measure and see if the CA's advice was right - With TaxView, I could consult multiple CAs and find the right solution”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-12204">
              <div className="pos-abs ellipse-4-12205">
                <img
                  src={Aditya}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12205 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-12206">
                <div className="pos-abs jessica-merchan-12207">
                  <span className="jessica-merchan-12207-0 ">{`Aditya`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-12208">
                  <span className="cfo-charity-gal-12208-0 ">{`CEO, WhatNext`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-12209">
            <div className="pos-abs frame-57-12210">
              <div className="pos-abs income-tax-retu-12211">
                <span className="income-tax-retu-12211-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-12212">
              <div className="pos-abs gst-registratio-12213">
                <span className="gst-registratio-12213-0 ">{`Wealth Management`}</span>
              </div>
            </div>
          </div>
        </section>
        {/* Frame 621 */}
        <section className="pos-abs frame-62-12214">
          <div className="pos-abs frame-60-12215">
            <div className="pos-abs frame-54-12216">
              <div className="pos-abs div-12217">
                <span className="div-12217-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-12218">
                <span className="omg-i-cannot-be-12218-0 ">{`“TaxView made finding experts for my legal needs so easy! Perfect place if you need the right advice”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-12219">
              <div className="pos-abs ellipse-4-12220">
                <img
                  src={Atharv}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12220 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-12221">
                <div className="pos-abs jessica-merchan-12222">
                  <span className="jessica-merchan-12222-0 ">{`Atharv`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-12223">
                  <span className="cfo-charity-gal-12223-0 ">{`Product Lead`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-12224">
            <div className="pos-abs frame-57-12225">
              <div className="pos-abs income-tax-retu-12226">
                <span className="income-tax-retu-12226-0 ">{`Legal Advisory and DSC`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-12227">
              <div className="pos-abs gst-registratio-12228">
                <span className="gst-registratio-12228-0 ">{`IT Notices`}</span>
              </div>
            </div>
          </div>
        </section>
        <div className="pos-abs ellipse-5-12295"></div>
        <div className="pos-abs bg-11976"></div>
        {/* Frame 1641 */}
        <section className="pos-abs frame-164-12045">
          <div className="pos-abs heading-2--serv-12046">
            <span className="heading-2--serv-12046-0 ">{`Why TaxView?`}</span>
          </div>
        </section>
        {/* Frame 1631 */}
        <section className="pos-abs frame-163-12462">
          <div className="pos-abs frame-158-12463">
            <div className="pos-abs frame-157-12464">
              <div className="pos-abs c-1605873617taxsa-12465">
                <div className="pos-abs c-1605873617taxsa-12466">
                  <div className="pos-abs c-1605873617taxsa-12467">
                    <div className="pos-abs vector-12469">
                      <div className="nodeBg-12469 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-12470">
                      <div className="nodeBg-12470 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-12468">
                      <div className="nodeBg-12468 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs heading-6-12471">
                <div className="pos-abs personalized-so-12472">
                  <span className="personalized-so-12472-0 ">{`Personalized solutions lead to better savings`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs individual-or-b-12473">
              <span className="individual-or-b-12473-0 ">{`Individual or business owner, most solutions avaliable in the market are just one size fits all - We at TaxView are aiming to change that. Find a solution that works best for you`}</span>
            </div>
          </div>
          <div className="pos-abs frame-160-12474">
            <div className="pos-abs frame-159-12475">
              <div className="pos-abs c-1605873731unpar-12476">
                <div className="pos-abs c-1605873731unpar-12477">
                  <div className="pos-abs c-1605873731unpar-12478">
                    <div className="pos-abs vector-12479">
                      <div className="nodeBg-12479 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-12480">
                      <div className="nodeBg-12480 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs heading-7-12481">
                <div className="pos-abs get-help-fast-a-12482">
                  <span className="get-help-fast-a-12482-0 ">{`Get help fast and on-demand`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs our-experts-wor-12483">
              <span className="our-experts-wor-12483-0 ">{`Our experts work with you and provide you with the right advice, whenever you need it. With multiple time slots and ratings, you can find the right expert whenever you need it.`}</span>
            </div>
          </div>
          <div className="pos-abs frame-162-12484">
            <div className="pos-abs frame-161-12485">
              <div className="pos-abs c-1605873842fullc-12486">
                <div className="pos-abs c-1605873842fullc-12487">
                  <div className="pos-abs c-1605873842fullc-12488">
                    <div className="pos-abs vector-12489">
                      <div className="nodeBg-12489 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-12490">
                      <div className="nodeBg-12490 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-12491">
                      <div className="nodeBg-12491 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs heading-5-12492">
                <div className="pos-abs your-data-is-al-12493">
                  <span className="your-data-is-al-12493-0 ">{`Your data is always secured`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs we-believe-in-e-12494">
              <span className="we-believe-in-e-12494-0 ">{`We believe in ensuring that your data is always secure. Our experts will never use any of your data without your consent. Trust and security is something we always believe `}</span>
            </div>
          </div>
        </section>
        {/* Frame 701 */}
        <section className="pos-abs frame-70-12230">
          <div className="pos-abs ellipse-3-12239"></div>
          <div className="pos-abs bg-12231"></div>
          <div className="pos-abs frame-2-12232 cursor-pointer" onClick={props.openClientLogin}>
            <div className="pos-abs background-12234">
              <div className="pos-abs bpysgyl5w3nhfbn-12235">
                <div className="pos-abs bpysgyl5w3nhfbn-12236">
                  <div className="pos-abs bpysgyl5w3nhfbn-12237">
                    <div className="pos-abs vector-12238">
                      <div className="nodeBg-12238 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs book-a-session-12233">
              <span className="book-a-session-12233-0 ">{`Book A Free Session`}</span>
            </div>
          </div>
        </section>
        {/* Frame 571 */}
        <section className="pos-abs frame-57-12297">
          <div className="pos-abs heading-2--serv-12298">
            <span className="heading-2--serv-12298-0 ">{`Meet with our Experts`}</span>
          </div>
          <div className="pos-abs customer-satisf-12299">
            <span className="customer-satisf-12299-0 ">{`Customer satisfaction is a primary goal for our company`}</span>
          </div>
        </section>
        {/* Frame 671 */}
        <section className="pos-abs frame-67-12300">
          <div className="pos-abs frame-63-12301">
            <div className="pos-abs rectangle-42298-12302">
              <img
                src={Vikas}
                className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-12302 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-12303">
              <div className="pos-abs jessica-merchan-12304">
                <span className="jessica-merchan-12304-0 ">{`Vikas Gupta`}</span>
              </div>
              <div className="pos-abs frame-61-12305">
                <div className="pos-abs c-5-year-of-exper-12309">
                  <span className="c-5-year-of-exper-12309-0 ">{`27 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-12306">
                  <div className="pos-abs vector-12307">
                    <div className="nodeBg-12307 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12308">
                    <div className="nodeBg-12308 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-12310">
                <div className="pos-abs briefcase02-12311">
                  <div className="pos-abs vector-12314">
                    <div className="nodeBg-12314 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12312">
                    <div className="nodeBg-12312 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12313">
                    <div className="nodeBg-12313 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--12315">
                  <span className="gst-specialist--12315-0 ">{`SEBI Consultancy, Trademark and 
Patent Registration`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-12316">
              <div className="nodeBg-12316 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-12317">
              <div className="pos-abs usergroup-12318">
                <div className="pos-abs vector-12322">
                  <div className="nodeBg-12322 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12320">
                  <div className="nodeBg-12320 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12319">
                  <div className="nodeBg-12319 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12321">
                  <div className="nodeBg-12321 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-12323">
                <span className="c-100-happy-clien-12323-0 ">{`1000+ Happy Clients`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-67-12324">
            <div className="pos-abs rectangle-42298-12325">
              <img
                src={caimage3}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-12325 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-12326">
              <div className="pos-abs jessica-merchan-12327">
                <span className="jessica-merchan-12327-0 ">{`CA Shubham Gedam`}</span>
              </div>
              <div className="pos-abs frame-61-12328">
                <div className="pos-abs c-5-year-of-exper-12332">
                  <span className="c-5-year-of-exper-12332-0 ">{`5 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-12329">
                  <div className="pos-abs vector-12330">
                    <div className="nodeBg-12330 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12331">
                    <div className="nodeBg-12331 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-12333">
                <div className="pos-abs briefcase02-12334">
                  <div className="pos-abs vector-12337">
                    <div className="nodeBg-12337 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12335">
                    <div className="nodeBg-12335 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12336">
                    <div className="nodeBg-12336 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--12338">
                  <span className="gst-specialist--12338-0 ">{`Financial Planning, Cibil Consultancy, Research Services`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-12339">
              <div className="nodeBg-12339 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-12340">
              <div className="pos-abs usergroup-12341">
                <div className="pos-abs vector-12345">
                  <div className="nodeBg-12345 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12343">
                  <div className="nodeBg-12343 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12342">
                  <div className="nodeBg-12342 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12344">
                  <div className="nodeBg-12344 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-12346">
                <span className="c-100-happy-clien-12346-0 ">{`20+ Happy Clients `}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-68-12347">
            <div className="pos-abs rectangle-42298-12348">
              <img
                src={Heena}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-12348 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-12349">
              <div className="pos-abs jessica-merchan-12350">
                <span className="jessica-merchan-12350-0 ">{`CA Heena Katiyare`}</span>
              </div>
              <div className="pos-abs frame-61-12351">
                <div className="pos-abs c-5-year-of-exper-12355">
                  <span className="c-5-year-of-exper-12355-0 ">{`8 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-12352">
                  <div className="pos-abs vector-12353">
                    <div className="nodeBg-12353 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12354">
                    <div className="nodeBg-12354 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-12356">
                <div className="pos-abs briefcase02-12357">
                  <div className="pos-abs vector-12360">
                    <div className="nodeBg-12360 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12358">
                    <div className="nodeBg-12358 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12359">
                    <div className="nodeBg-12359 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--12361">
                  <span className="gst-specialist--12361-0 ">{`ITR Filling, Notices and Scrutiny,
IT Appeals`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-12362">
              <div className="nodeBg-12362 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-12363">
              <div className="pos-abs usergroup-12364">
                <div className="pos-abs vector-12368">
                  <div className="nodeBg-12368 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12366">
                  <div className="nodeBg-12366 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12365">
                  <div className="nodeBg-12365 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12367">
                  <div className="nodeBg-12367 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-12369">
                <span className="c-100-happy-clien-12369-0 ">{`150+ Happy Clients `}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-69-12370">
            <div className="pos-abs rectangle-42298-12371">
              <img
                src={Kritika}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-12371 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-12372">
              <div className="pos-abs jessica-merchan-12373">
                <span className="jessica-merchan-12373-0 ">{`CA Kritika Bharwani`}</span>
              </div>
              <div className="pos-abs frame-61-12374">
                <div className="pos-abs c-5-year-of-exper-12378">
                  <span className="c-5-year-of-exper-12378-0 ">{`8 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-12375">
                  <div className="pos-abs vector-12376">
                    <div className="nodeBg-12376 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12377">
                    <div className="nodeBg-12377 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-12379">
                <div className="pos-abs briefcase02-12380">
                  <div className="pos-abs vector-12383">
                    <div className="nodeBg-12383 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12381">
                    <div className="nodeBg-12381 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-12382">
                    <div className="nodeBg-12382 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--12384">
                  <span className="gst-specialist--12384-0 ">{`Company Incorporation, Financial Planning, NRI ITR Filling`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-12385">
              <div className="nodeBg-12385 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-12386">
              <div className="pos-abs usergroup-12387">
                <div className="pos-abs vector-12391">
                  <div className="nodeBg-12391 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12389">
                  <div className="nodeBg-12389 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12388">
                  <div className="nodeBg-12388 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-12390">
                  <div className="nodeBg-12390 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-12392">
                <span className="c-100-happy-clien-12392-0 ">{`15+ Happy Clients `}</span>
              </div>
            </div>
          </div>
        </section>
        <div className="pos-abs bg-11975"></div>
        {/* Frame 781 */}
        <section className="pos-abs frame-78-12393">
          <div className="pos-abs heading-2--serv-12394">
            <span className="heading-2--serv-12394-0 ">{`Taxview`}</span>
          </div>
        </section>
        {/* Container1 */}
        <section className="pos-abs container-12440">
          <div className="pos-abs container-12441">
            <div className="pos-abs container-12442">
              <div className="pos-abs border-12443"></div>
            </div>
            <div className="pos-abs heading-2--freq-12444">
              <span className="heading-2--freq-12444-0 ">{`Frequently Asked Questions`}</span>
            </div>
          </div>
          <div className="pos-abs container-12445">
            <div className="pos-abs background-12446">
              <div className="pos-abs border-12449"></div>
              <div className="pos-abs heading-5--when-12447">
                <span className="heading-5--when-12447-0 ">{`What is TaxView?`}</span>
              </div>
              <div className="pos-abs the-event-will--12448">
                <span className="the-event-will--12448-0 ">{`TaxView is your go to platform for connecting with the best consultants for all your Tax / GST / Legal needs. We onboard the best experts and help you connect, engage and solve problems with the best consultants`}</span>
              </div>
            </div>
            <div className="pos-abs background-12450">
              <div className="pos-abs border-12453"></div>
              <div className="pos-abs heading-5--is-t-12451">
                <span className="heading-5--is-t-12451-0 ">{`Can I book a free session?`}</span>
              </div>
              <div className="pos-abs yes-there-is-a--12452">
                <span className="yes-there-is-a--12452-0 ">{`Yes you can, sign in, select the domain you need help with, select the consultant based on years of experience, work profile and either book a service with them or book a free call`}</span>
              </div>
            </div>
            <div className="pos-abs background-12454">
              <div className="pos-abs border-12457"></div>
              <div className="pos-abs heading-5--what-12455">
                <span className="heading-5--what-12455-0 ">{`What are the services TaxView offers?`}</span>
              </div>
              <div className="pos-abs the-event-will--12456">
                <span className="the-event-will--12456-0 ">{`TaxView offers consultants across Tax / Legal and Insurance needs. You can explore the diverse range of domains from the dashboard. Login with your phone number and select the domain you need help on`}</span>
              </div>
            </div>
            <div className="pos-abs background-12458">
              <div className="pos-abs border-12461"></div>
              <div className="pos-abs heading-5--can--12459">
                <span className="heading-5--can--12459-0 ">{`How does TaxView get the best experts?`}</span>
              </div>
              <div className="pos-abs yes-we-welcome--12460">
                <span className="yes-we-welcome--12460-0 ">{`TaxView onboards only SEBI certified / CA Qualified or Legal practicing Consultants. They have the best acumen and worked with atleast 10+ clients to solve their needs. Be assured you're in the best hands!`}</span>
              </div>
            </div>
          </div>
        </section>
        <div className="pos-abs ellipse-3-12294"></div>
        <div className="pos-abs ellipse-4-12296"></div>
        <div className="pos-abs rectangle-42302-12395"></div>
        <div className="pos-abs join-as-a-exper-12431">
          <span className="join-as-a-exper-12431-0 ">{`Join as Expert`}</span>
        </div>
        <div className="pos-abs join-our-commun-12432">
          <span className="join-our-commun-12432-0 ">{`Join our community of Tax / Legal / Business experts.`}</span>
        </div>
        {/* Frame 21 */}
        <section className="pos-abs frame-2-12433 cursor-pointer" onClick={props.openConsultantLogin}>
          <div className="pos-abs background-12435">
            <div className="pos-abs bpysgyl5w3nhfbn-12436">
              <div className="pos-abs bpysgyl5w3nhfbn-12437">
                <div className="pos-abs bpysgyl5w3nhfbn-12438">
                  <div className="pos-abs vector-12439">
                    <div className="nodeBg-12439 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs join-now-12434">
            <span className="join-now-12434-0 ">{`Join Now`}</span>
          </div>
        </section>
        {/* Background8 */}
        <section className="pos-abs background-12396">
          <div className="pos-abs border-12430"></div>
          <div className="pos-abs frame-168-12397">
            <div className="pos-abs frame-167-12398">
              <div className="pos-abs support-12399" >
                <span className="support-12399-0 ">{`Support`}</span>
              </div>
              <div className="pos-abs paragraph-12400">
                <div className="pos-abs link--terms--pr-12401 z-40" onClick={()=>{navigate("/tnc")}}>
                  <span className="link--terms--pr-12401-0">{`Terms & Privacy`}</span>
                </div>
                <div className="pos-abs link--faqs-12402 z-40" onClick={()=>{navigate("/tnc")}}>
                  <span className="link--faqs-12402-0 ">{`FAQs`}</span>
                </div>
                {/* <div className="pos-abs link--become-sp-12403">
                  <span className="link--become-sp-12403-0 ">{`Become Sponsor`}</span>
                </div>
                <div className="pos-abs link--get-ticke-12404">
                  <span className="link--get-ticke-12404-0 ">{`Get Tickets`}</span>
                </div> */}
              </div>
            </div>
            <div className="pos-abs frame-165-12405">
              <div className="pos-abs contact-12406">
                <span className="contact-12406-0 ">{`Contact`}</span>
              </div>
              <div className="pos-abs frame-140-12407">
                <div className="pos-abs h9jxuen4q7yyuxh-12408">
                  <div className="pos-abs h9jxuen4q7yyuxh-12409">
                    <div className="pos-abs h9jxuen4q7yyuxh-12410">
                      <div className="pos-abs vector-12412">
                        <div className="nodeBg-12412 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12411">
                        <div className="nodeBg-12411 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12413">
                        <div className="nodeBg-12413 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs frame-139-12414">
                  <div className="pos-abs c-4296-coplin-ave-12415">
                    <span className="c-4296-coplin-ave-12415-0 ">{`Nagpur`}</span>
                  </div>
                  <div className="pos-abs arizona-59412-12416">
                    <span className="arizona-59412-12416-0 ">{`India`}</span>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-142-12417">
                <div className="pos-abs ygonarmqkxum8wv-12418">
                  <div className="pos-abs ygonarmqkxum8wv-12419">
                    <div className="pos-abs ygonarmqkxum8wv-12420">
                      <div className="pos-abs vector-12422">
                        <div className="nodeBg-12422 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-12421">
                        <div className="nodeBg-12421 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs frame-141-12423">
                  <div className="pos-abs link-12424">
                    <div className="pos-abs frame-166-12425">
                      <div className="pos-abs hellowildframer-12426">
                        <span className="hellowildframer-12426-0 ">{`support@taxview.co.in`}</span>
                      </div>
                      <div className="pos-abs horizontalborde-12427"></div>
                    </div>
                  </div>
                  <div className="pos-abs link-12428">
                    <div className="pos-abs c-1-4953953940-12429">
                      <span className="c-1-4953953940-12429-0 ">{`+91 9021227059`}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default DesktopHomePage;
