import {
  Avatar,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Rating,
} from "flowbite-react";
import SpinnerComponent from "./SpinnerComponent";
import { costing, timeMap } from "./Costing";
import { useEffect, useState } from "react";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import AxiosInstance from "../Utils/AxiosInstance";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function SelectionPopup(props: any) {
  const { consultantInfo, modalClose, category } = props;

  const navigate = useNavigate();

  const [pageNumber, setPageNumber]: any = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [consultantSlots, setConsultantSlots]: any = useState({});
  const [selectedSlotId, setSelectedSlotId] = useState("");
  const [freeSlotAvailable, setFreeSlotAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedService, setSelectedService]: any = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [showConsultantPopup, setShowConsultantPopup] = useState(
    props.showConsultantPopup
  );

  useEffect(() => {
    if (props.showConsultantPopup) {
      getConsultantSlots(consultantInfo._id, null);
    }
  }, [consultantInfo]);

  useEffect(() => {
    setShowConsultantPopup(props.showConsultantPopup);
  }, [props.showConsultantPopup]);

  const handleServiceChange = (checked: boolean, serviceName: string,cost:any) => {
    if (checked) {
      setSelectedService([...selectedService, serviceName]);
      setTotalCost(totalCost + cost);
    } else {
      setTotalCost(totalCost - cost);
      let tempService = selectedService;
      tempService.splice(tempService.indexOf(serviceName), 1);
      setSelectedService([...tempService]);
    }
  };

  const getConsultantSlots = (consultantId: string, date: any) => {
    setLoading(true);
    AxiosInstance()
      .get(
        `/users/getAvailableSlots?consultantId=${consultantId}${
          date ? `&date=${date}` : ""
        }`
      )
      .then(({ data }) => {
        setSelectedDate("");
        setSelectedTime("");
        setSelectedSlotId("");
        setSelectedService([]);
        if (date) {
          setConsultantSlots({ ...consultantSlots, ...data.slots });
          setFreeSlotAvailable(data.freeAvailable);
          setPageNumber(pageNumber + 1);
        } else {
          setConsultantSlots(data.slots);
          setFreeSlotAvailable(data.freeAvailable);
          setPageNumber(1);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get consultants slots`);
        setLoading(false);
      });
  };

  const getMoreSlots = () => {
    if (Object.keys(consultantSlots).length < 7 * pageNumber) {
      toast.error("Cannot find any more slots");
      return;
    }
    if (Object.keys(consultantSlots).length <= pageNumber * 7) {
      getConsultantSlots(
        consultantInfo._id,
        Object.keys(consultantSlots)[pageNumber * 7 - 1]
      );
    } else {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div>
      <Modal show={showConsultantPopup} onClose={modalClose}>
        <ModalHeader>
          <div className="flex items-center gap-4 pr-2 pl-2 ">
            <div>
              <Avatar img={consultantInfo?.profilePic} rounded  className="z-0"/>
            </div>
            <div>
              <div className="font-bold">{consultantInfo?.prefix}{" "}{consultantInfo?.username}</div>
              <div className="font-light text-xs">
                {consultantInfo?.experience} Years experience
              </div>
              <Rating size="sm" className="mt-1">
                <Rating.Star filled={consultantInfo?.rating >= 1} />
                <Rating.Star filled={consultantInfo?.rating >= 2} />
                <Rating.Star filled={consultantInfo?.rating >= 3} />
                <Rating.Star filled={consultantInfo?.rating >= 4} />
                <Rating.Star filled={consultantInfo?.rating >= 5} />
              </Rating>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="mt-1 mb-2 text-[#8A8A8A] text-xs font-light">
              Select a Preffered Date
            </div>
            <div className="grid grid-cols-9 justify-center gap-2 mb-3  pb-2 border-b-2 border-[#EDEDED]">
              <div
                className={`flex items-center justify-center ${
                  pageNumber < 2 ? "" : "cursor-pointer"
                }`}
                onClick={() => {
                  if (pageNumber >= 2) {
                    setSelectedDate("");
                    setSelectedTime("");
                    setSelectedSlotId("");
                    setSelectedService([]);
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                {pageNumber < 2 ? null : (
                  <GrFormPrevious className="text-4xl" />
                )}
              </div>
              {Object.keys(consultantSlots)
                .slice((pageNumber - 1) * 7, pageNumber * 7)
                .map((slotDate: string) => {
                  return (
                    <div
                      className={`rounded-lg cursor-pointer flex justify-center items-center p-2 text-center ${
                        selectedDate === slotDate
                          ? "bg-[#0F123F] text-white font-bold"
                          : "bg-[#F1F4FD] text-[#0D0B26]"
                      }`}
                      onClick={() => {
                        setSelectedTime("");
                        setSelectedSlotId("");
                        setSelectedService([]);
                        setSelectedDate(slotDate);
                      }}
                    >
                      {moment(slotDate, "DD-MM-YYYY").format("DD MMM")}
                    </div>
                  );
                })}
              {/* {[...Array((7 * pageNumber) - Object.keys(slots).length)].map(
                      (ele: any) => (
                        <div></div>
                      )
                    )} */}
              <div
                className="flex items-center justify-center cursor-pointer"
                onClick={() => {
                  getMoreSlots();
                }}
              >
                <GrFormNext className="text-4xl" />
              </div>
            </div>
            {selectedDate !== "" && (
              <div>
                <div className="mt-1 mb-1 text-[#8A8A8A] text-xs font-light">
                  Select a Preffered Time
                </div>
                <div className="p-3 gap-2 w-full flex overflow-y-auto shrink-0 flex-0">
                  {Object.keys(consultantSlots[selectedDate]).map(
                    (time: any) => (
                      <div
                        className={`rounded-lg cursor-pointer flex justify-center w-18 items-center p-2 text-center ${
                          selectedTime === time
                            ? "bg-[#0F123F] text-white font-bold"
                            : "bg-[#F1F4FD] text-[#0D0B26]"
                        }`}
                        onClick={() => {
                          setSelectedSlotId(
                            consultantSlots[selectedDate][time]
                          );
                          setSelectedTime(time);
                          setSelectedService([]);
                        }}
                      >
                        {timeMap[time]}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
            {selectedTime !== "" && (
              <div
                className={`mt-2 grid grid-cols-1 lg:${
                  freeSlotAvailable ? "grid-cols-2" : "grid-cols-1"
                } gap-2`}
              >
                <div className="p-2 border-[0.5px] border-[#547AF7] bg-[#F1F4FD] rounded-xl">
                  <div className="text-lg font-bold">Services</div>
                  <div className="text-xs font-light">
                    You will be paying for the selected services
                  </div>
                  <div className="mt-2 border-t-[0.5px] p-2">
                    <div>
                      {Object.keys(consultantInfo?.professionalDetails.services).map((expertise: any) => (
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <Checkbox
                              checked={
                                selectedService.indexOf(expertise) !== -1
                              }
                              onClick={(event: any) => {
                                handleServiceChange(
                                  event.target.checked,
                                  expertise,
                                  parseInt(consultantInfo?.professionalDetails.services[expertise])
                                );
                              }}
                            />
                            {expertise}
                          </div>
                          <div>{consultantInfo?.professionalDetails.services[expertise]} &#8377;</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {freeSlotAvailable && <div className="p-2 border-[0.5px] border-[#E3E3E3] rounded-xl">
                  <div className="text-lg font-bold">Free Video Call</div>
                  <div className="text-xs font-light">
                    Not Sure? Start by booking a free video call
                  </div>
                  <div className="mt-2 border-t-[0.5px] p-2 text-xs">
                    <ul style={{ listStyle: "disc" }} className="p-2">
                      <li>
                        Over the free call, i will analyse and understand your
                        queries
                      </li>
                      <li>Advice you on next steps</li>
                      <li>Help you make the right decision</li>
                    </ul>
                    <div className="mt-2 flex justify-between items-center">
                      <div className="text-sm font-bold">Duration: 30 Mins</div>
                      <Button
                        className="text-xs bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                        size="xs"
                        onClick={() => {
                          navigate("/checkout", {
                            state: {
                              paid: false,
                              selectedService: ["Discovery"],
                              selectedDate: selectedDate,
                              selectedTime: selectedTime,
                              selectedSlotId: selectedSlotId,
                              consultantInfo: consultantInfo,
                              totalCost: 0,
                              category: category,
                            },
                          });
                        }}
                      >
                        Book a free call
                      </Button>
                    </div>
                  </div>
                </div>}
                
              </div>
            )}
          </div>
          {loading && <SpinnerComponent />}
        </ModalBody>
        {selectedService.length > 0 && (
          <ModalFooter>
            <Button
              className="text-xs bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
              size="xs"
              onClick={() => {
                navigate("/checkout", {
                  state: {
                    paid: true,
                    selectedService: selectedService,
                    selectedDate: selectedDate,
                    selectedTime: selectedTime,
                    selectedSlotId: selectedSlotId,
                    consultantInfo: consultantInfo,
                    totalCost: totalCost,
                    category: category,
                  },
                });
              }}
            >
              Add to Cart & Checkout
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
}

export default SelectionPopup;
