import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConsultantDashboard from "./Pages/Consultant/ConsultantDashboard";
import UserDashboard from "./Pages/UserDashboard";
import SlotBooking from "./Pages/SlotBooking";
import Checkout from "./Pages/Checkout";
import BookingConfirmation from "./Pages/BookingConfirmation";
import HomePage from "./Pages/HomePage";
import TNC from "./Pages/TNC";
import ConsultantOnboarding from "./Pages/Consultant/ConsultantOnboarding";
import ConsultantProfilePage from "./Pages/Consultant/ConsultantProfilePage";
import ConsultantClients from "./Pages/Consultant/ConsultantClients";
import ConsultantUpdateDetails from "./Pages/Consultant/ConsultantUpdateDetails";
import NewHomePage from "./Pages/NewHomePage";
import MobileHomePage from "./Pages/MobileHomePage";
import AdminLogin from "./Pages/Admin/AdminLogin";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminLeadList from "./Pages/Admin/AdminLeadList";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NewHomePage />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/booksession" element={<SlotBooking />} />
          <Route path="/consultant/onboarding" element={<ConsultantOnboarding />} />
          <Route path="/consultant/profile" element={<ConsultantProfilePage />} />
          <Route path="/consultant/clients" element={<ConsultantClients />} />
          <Route path="/consultant/updateDetails" element={<ConsultantUpdateDetails />} />
          <Route path="/bookingconfirmation" element={<BookingConfirmation />} />
          <Route path="/slotBooking" element={<SlotBooking />} />
          <Route path="/mobile" element={<MobileHomePage/>} />
          <Route
            path="/consultant/dashboard"
            element={<ConsultantDashboard />}
          />
          <Route path="/home" element={<HomePage/>}/>
          <Route path="/tnc" element={<TNC/>}/>
          <Route path="/admin" element={<AdminLogin/>}/>
          <Route path="/admin/dashboard" element={<AdminDashboard/>}/>
          <Route path="/admin/leadlist" element={<AdminLeadList/>}/>
        </Routes>
        
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
