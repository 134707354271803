import { useEffect, useState } from "react";
import ConsultantProfile from "./ConsultantComponents/ConsultantProfile";
import SpinnerComponent from "../../Components/SpinnerComponent";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../Utils/AxiosInstance";
import {
  Avatar,
  Button,
  Checkbox,
  Label,
  Radio,
  Select,
  Textarea,
  TextInput,
} from "flowbite-react";
import Multiselect from "multiselect-react-dropdown";
import ConsultantSideBar from "./ConsultantComponents/ConsultantSideBar";
import { toast } from "react-toastify";
import { AnyCnameRecord } from "dns";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLink } from "react-icons/fa6";

function ConsultantProfilePage() {
  const [userInfo, setUserInfo]: any = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Profile");
  const [slots, setSlots]: any = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.slots) {
      getConsultantSlots();
      setSelectedTab("Slots");
    } else {
      getDeatails();
    }
  }, []);
  const getDeatails = () => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/verify")
      .then(({ data }) => {
        setUserInfo(data);
        setLoading(false);
      })
      .catch((err) => {
        localStorage.clear();
        setLoading(false);
        navigate("/");
      });
  };

  const getIconfromLink:any = (link:string) =>{
    if(link.toLowerCase().startsWith("https://facebook.com") || link.toLowerCase().startsWith("https://www.facebook.com")  || link.toLowerCase().startsWith("www.facebook.com") || link.toLowerCase().startsWith("facebook.com")){
      return FaFacebook
    }
    else if(link.toLowerCase().startsWith("https://twitter.com") || link.toLowerCase().startsWith("https://www.twitter.com") || link.toLowerCase().startsWith("www.twitter.com") || link.toLowerCase().startsWith("twitter.com")){
      return FaTwitter
    }
    else if(link.toLowerCase().startsWith("https://youtube.com") || link.toLowerCase().startsWith("https://www.youtube.com") || link.toLowerCase().startsWith("www.youtube.com") || link.toLowerCase().startsWith("youtube.com")){
      return IoLogoYoutube
    }
    else if(link.toLowerCase().startsWith("https://linkedin.com") || link.toLowerCase().startsWith("https://www.linkedin.com") || link.toLowerCase().startsWith("www.linkedin.com") || link.toLowerCase().startsWith("linkedin.com")){
      return FaLinkedin
    }
    else if(link.toLowerCase().startsWith("https://instagram.com") || link.toLowerCase().startsWith("https://www.instagram.com") || link.toLowerCase().startsWith("www.instagram.com") || link.toLowerCase().startsWith("instagram.com")){
      return FaInstagram
    }
    else{
      return FaLink
    }

  }

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  let qualifications = ["Graduate", "Post Graduate", "P.Hd."];

  const options = [
    { name: "Income Tax", id: 1 },
    { name: "NRI Corner", id: 2 },
    { name: "Business Registration", id: 3 },
    { name: "Legal", id: 4 },
    { name: "Investments", id: 5 },
    { name: "Insurance", id: 6 },
  ];

  const handleUpdateTime = (
    day: string,
    type: string,
    index: any,
    value: any
  ) => {
    let tempSlots = slots;
    tempSlots[day][index][type] = value;
    setSlots({ ...tempSlots });
  };

  const handleSlotDayClick = (day: string) => {
    let tempSlots = slots;
    if (Object.keys(slots).indexOf(day) === -1) {
      tempSlots[day] = [{ startTime: -1, endTime: -1 }];
    } else {
      delete tempSlots[day];
    }
    setSlots({ ...tempSlots });
  };

  const time: any = {
    "12 AM": 0,
    "1 AM": 1,
    "2 AM": 2,
    "3 AM": 3,
    "4 AM": 4,
    "5 AM": 5,
    "6 AM": 6,
    "7 AM": 7,
    "8 AM": 8,
    "9 AM": 9,
    "10 AM": 10,
    "11 AM": 11,
    "12 PM": 12,
    "1 PM": 13,
    "2 PM": 14,
    "3 PM": 15,
    "4 PM": 16,
    "5 PM": 17,
    "6 PM": 18,
    "7 PM": 19,
    "8 PM": 20,
    "9 PM": 21,
    "10 PM": 22,
    "11 PM": 23,
  };

  const days: any = {
    Sunday: "Sun",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",
  };

  const getConsultantSlots = () => {
    AxiosInstance()
      .get("/consultant/getSlots")
      .then(({ data }) => {
        setSlots(data);
      })
      .catch((err) => {
        toast.error("Failed to get consultant slots");
      });
  };

  const updateConsultantSlots = () => {
    let selectedDays = Object.keys(slots);
    if (selectedDays.length === 0) {
      toast.error("Please select atleast 1 day to proceed");
      return;
    }
    for (let index = 0; index < selectedDays.length; index++) {
      let timerange: any = [];
      for (
        let timeIndex = 0;
        timeIndex < slots[selectedDays[index]].length;
        timeIndex++
      ) {
        console.log(slots[selectedDays[index]][timeIndex]);
        if (slots[selectedDays[index]][timeIndex].startTime === -1) {
          toast.error(
            `Please select start and end time for ${selectedDays[index]}`
          );
          return;
        } else if (slots[selectedDays[index]][timeIndex].endTime === -1) {
          toast.error(`Please select end time for ${selectedDays[index]}`);
          return;
        }
        for (
          let tempIndex = parseInt(
            slots[selectedDays[index]][timeIndex].startTime
          );
          tempIndex <
          parseInt(slots[selectedDays[index]][timeIndex].startTime) + 1;
          tempIndex++
        ) {
          if (timerange.indexOf(tempIndex)) {
            timerange.push(tempIndex);
          } else {
            toast.error(
              `Overlapping time slot for ${selectedDays[index]}, please correct it to proceed`
            );
            return;
          }
        }
      }
    }
    setLoading(true);
    AxiosInstance()
      .post("/consultant/updateConsultantSlots", slots)
      .then(({ data }) => {
        toast.success("Slots updated successfuly");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to update slot details ${err?.message}`);
        setLoading(false);
      });
  };

  const addNewSlotTime = (day: any) => {
    let tempSlots: any = slots;
    tempSlots[day].push({ startTime: -1, endTime: -1 });
    setSlots({ ...tempSlots });
  };

  const removeSlot = (day: any, index: any) => {
    let tempSlots: any = slots;
    tempSlots[day].splice(index, 1);
    setSlots({ ...tempSlots });
  };
  return (
    <ConsultantSideBar nav={true}>
      <div>
        <div className="flex gap-2 items-center mt-4 ml-4">
          <div
            className={`pl-4 pt-1 pb-1 ${
              selectedTab === "Profile"
                ? "border-l-4 border-[#0CBC8B] font-bold text-lg text-[#212121]"
                : "text-sm text-[#B1B1B1] cursor-pointer"
            }`}
            onClick={() => {
              getDeatails();
              setSelectedTab("Profile");
            }}
          >
            Profile Details
          </div>
          <div
            className={`pl-2 pt-1 pb-1 ${
              selectedTab === "Slots"
                ? "border-l-4 border-[#0CBC8B] font-bold text-lg text-[#212121]"
                : "text-sm text-[#B1B1B1] cursor-pointer"
            }`}
            onClick={() => {
              getConsultantSlots();
              setSelectedTab("Slots");
            }}
          >
            Availability Details
          </div>
        </div>
        {selectedTab === "Profile" && (
          <div>
            <div className="grid grid-cols-1 gap-4">
              <div className="m-4 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Profile Pic" />
                    </div>
                    <Avatar img={userInfo.profilePic} />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Full Name" />
                    </div>
                    <div className="flex gap-1 items-center">
                      <Select
                        id="countries"
                        disabled
                      >
                        <option>{userInfo.prefix === "" ? "Others":userInfo.prefix }</option>
                      </Select>
                      <TextInput
                        id="name"
                        type="text"
                        placeholder="Enter Name"
                        defaultValue={userInfo.username}
                        disabled
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Email Id" />
                    </div>
                    <TextInput
                      id="email"
                      type="email"
                      placeholder="Enter Email"
                      defaultValue={userInfo.email}
                      disabled
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Occupation" />
                    </div>
                    <TextInput
                      id="occuption"
                      type="text"
                      placeholder="Enter Occupation"
                      defaultValue={userInfo.occupation}
                      disabled
                    />
                  </div>
                  <div className="max-w-md">
                    <div className="mb-2 block">
                      <Label htmlFor="countries" value="State" />
                    </div>
                    <Select
                      id="countries"
                      defaultValue={userInfo.state}
                      disabled
                    >
                      {states.map((state) => (
                        <option>{state}</option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  className="mt-2 flex justify-end cursor-pointer font-bold text-[#547AF7] text-sm"
                  onClick={() => {
                    navigate("/consultant/updateDetails", {
                      state: { update: "PROFILE" },
                    });
                  }}
                >
                  Edit Information
                </div>
              </div>
              <div className="m-4 mt-0 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Aadhar Number" />
                    </div>
                    <TextInput
                      id="aadhar"
                      type="text"
                      placeholder="Enter aadhar Number"
                      defaultValue={userInfo.kycDetails?.aadhar}
                      maxLength={12}
                      disabled
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="PAN Number" />
                    </div>
                    <TextInput
                      id="email"
                      type="text"
                      placeholder="Enter PAN number"
                      defaultValue={userInfo.kycDetails?.pan}
                      maxLength={10}
                      disabled
                    />
                  </div>
                  <div className="max-w-md">
                    <div className="mb-2 block">
                      <Label htmlFor="countries" value="Highest Qualification" />
                    </div>
                    <Select
                      id="countries"
                      defaultValue={userInfo.kycDetails?.qualification}
                      disabled
                    >
                      {qualifications.map((qualification) => (
                        <option>{qualification}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Degree" />
                    {
                      userInfo.kycDetails?.degree.map((value: any, index: any) => (
                        <Select
                          id="countries"
                          defaultValue={value}
                          disabled
                          className={`${index !== 0 ? "mt-2" : ""}`}
                        >
                          <option>{value}</option>

                        </Select>
                      ))
                    }
                  </div>

                </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Holiding Certificate" />
                    </div>
                    <div className="flex items-center gap-8 flex-row">
                      <div className="flex items-center gap-2">
                        <Radio
                          id="united-state"
                          name="countries"
                          value="True"
                          defaultChecked={
                            userInfo.kycDetails?.certificate === "True"
                          }
                          disabled
                        />
                        <Label htmlFor="united-state">Yes</Label>
                      </div>
                      <div className="flex items-center gap-2">
                        <Radio
                          id="united-state"
                          name="countries"
                          value="False"
                          defaultChecked={
                            userInfo.kycDetails?.certificate === "False"
                          }
                          disabled
                        />
                        <Label htmlFor="united-state">No</Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="email1"
                        value="Institute/License Number"
                      />
                    </div>
                    <TextInput
                      id="occuption"
                      type="text"
                      placeholder="Enter License Number"
                      defaultValue={userInfo.kycDetails?.license}
                      disabled
                    />
                  </div>
                </div>

                <div
                  className="mt-2 flex justify-end cursor-pointer font-bold text-[#547AF7] text-sm"
                  onClick={() => {
                    navigate("/consultant/updateDetails", {
                      state: { update: "KYC" },
                    });
                  }}
                >
                  Edit Information
                </div>
              </div>
              <div className="m-4 mt-0 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Years of Experience" />
                    </div>
                    <TextInput
                      id="aadhar"
                      type="number"
                      placeholder="Enter Years of Experience"
                      defaultValue={userInfo.experience}
                      maxLength={12}
                      disabled
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Clients worked with" />
                    </div>
                    <TextInput
                      id="email"
                      type="number"
                      placeholder="Enter number of clients worked with"
                      defaultValue={userInfo.professionalDetails?.clients}
                      maxLength={10}
                      disabled
                    />
                  </div>
                  <div className="max-w-md">
                <div className="mb-2 flex justify-between items-center">
                  <Label htmlFor="countries" value="Social Media Handles (Max 5)" />
                </div>
                {
                  userInfo.professionalDetails?.links.map((achivement: any, index: any) => (
                    <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""} w-full cursor-pointer`} onClick={() => { window.open(achivement, '_blank', 'noopener,noreferrer') }}>
                      <TextInput
                        id="email"
                        placeholder="Add your social media links"
                        defaultValue={achivement}
                        disabled
                        icon={getIconfromLink(achivement)}
                        className="w-full"
                      />
                    </div>
                  ))
                }
              </div>
                  <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Awards" />
                    {
                      userInfo.professionalDetails?.award.map((value: any, index: any) => (
                        <Textarea
                          id="countries"
                          defaultValue={value}
                          disabled
                          className={`${index !== 0 ? "mt-2" : ""}`}
                        />
                      ))
                    }
                  </div>

                </div>
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Achivements" />
                    {
                      userInfo.professionalDetails?.achivement.map((value: any, index: any) => (
                        <Textarea
                          id="countries"
                          defaultValue={value}
                          disabled
                          className={`${index !== 0 ? "mt-2" : ""}`}
                        />
                      ))
                    }
                  </div>

                </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Professional Summary" />
                    </div>
                    <Textarea
                      id="email"
                      rows={4}
                      placeholder="Enter a description"
                      defaultValue={userInfo.professionalDetails?.summary}
                      disabled
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Select Category (max 3)" />
                    </div>
                    <Multiselect
                      options={options} // Options to display in the dropdown
                      selectedValues={options.filter(
                        (option) =>
                          userInfo.expertise &&
                          userInfo.expertise.indexOf(option.name) !== -1
                      )} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      placeholder="Select your Category"
                      disable
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Services" />
                    </div>
                    {userInfo.professionalDetails?.services &&
                      Object.keys(userInfo.professionalDetails?.services).map(
                        (serivce: any) => (
                          <div className="grid gap-2 mt-2 grid-col-2 items-center">
                            <div>{serivce}</div>
                            <div>
                              <TextInput
                                type="number"
                                placeholder="Enter amount"
                                defaultValue={
                                  userInfo.professionalDetails?.services[
                                    serivce
                                  ]
                                }
                                disabled
                              />
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>

                <div
                  className="flex justify-end cursor-pointer font-bold text-[#547AF7] mt-2 text-sm"
                  onClick={() => {
                    navigate("/consultant/updateDetails", {
                      state: { update: "PROFESSIONAL" },
                    });
                  }}
                >
                  Edit Information
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedTab === "Slots" && (
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-center gap-2 m-4">
              {Object.keys(days).map((day: any) => (
                <div
                  className={`rounded-lg shadow-lg p-4 flex gap-2 items-center ${
                    Object.keys(slots).indexOf(days[day]) !== -1
                      ? "border-2 border-[#547AF7]"
                      : ""
                  }`}
                >
                  <Checkbox
                    onClick={() => {
                      handleSlotDayClick(days[day]);
                    }}
                    checked={Object.keys(slots).indexOf(days[day]) !== -1}
                  />
                  {day}
                  {Object.keys(slots).indexOf(days[day]) !== -1 && (
                    <div>
                      {slots[days[day]].map((detail: any, index: any) => (
                        <div className="flex gap-2 items-center">
                          <div>
                            <div className="text-xs font-bold">Start</div>
                            <select
                              id="start"
                              required
                              className="h-8 text-xs"
                              defaultValue={slots[days[day]][index].startTime}
                              onChange={(event) => {
                                handleUpdateTime(
                                  days[day],
                                  "startTime",
                                  index,
                                  event.target.value
                                );
                              }}
                            >
                              <option>Select</option>
                              {Object.keys(time).map((timeName) => (
                                <option value={time[timeName]}>
                                  {timeName}
                                </option>
                              ))}
                            </select>
                          </div>
                          {slots[days[day]][index].startTime > -1 && (
                            <div>
                              <div className="text-xs font-bold">End</div>
                              <select
                                id="start"
                                required
                                className="h-8 text-xs"
                                defaultValue={slots[days[day]][index].endTime}
                                onChange={(event) => {
                                  handleUpdateTime(
                                    days[day],
                                    "endTime",
                                    index,
                                    event.target.value
                                  );
                                }}
                              >
                                <option>Select</option>
                                {Object.keys(time).map((timeName) => (
                                  <>
                                    {time[timeName] >
                                      slots[days[day]][index].startTime && (
                                      <option value={time[timeName]}>
                                        {timeName}
                                      </option>
                                    )}
                                  </>
                                ))}
                              </select>
                            </div>
                          )}
                          {slots[days[day]].length > 1 && (
                            <div
                              className="cursor-pointer text-red-700"
                              onClick={() => removeSlot(days[day], index)}
                            >
                              X
                            </div>
                          )}
                        </div>
                      ))}
                      <div
                        className="flex justify-center cursor-pointer text-blue-500 mt-2"
                        onClick={() => {
                          addNewSlotTime(days[day]);
                        }}
                      >
                        Add Slot
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="m-4 flex justify-center lg:justify-end">
              <Button
                className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
                size="xs"
                onClick={updateConsultantSlots}
              >
                Update
              </Button>
            </div>
          </div>
        )}
        {loading && <SpinnerComponent />}
      </div>
    </ConsultantSideBar>
  );
}

export default ConsultantProfilePage;
