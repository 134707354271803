import { Button, Label, Textarea, TextInput } from "flowbite-react";
import ConsultantSideBar from "./ConsultantSideBar";
import StepperComponent from "../../../Components/StepperComponent";
import { useEffect, useRef, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import AxiosInstance from "../../../Utils/AxiosInstance";
import { IoIosCloseCircle } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLink } from "react-icons/fa6";

function ConsultantProfessionaInfo(props: any) {
  const serviceRef: any = useRef()
  const options = [
    { name: "Income Tax", id: 1 },
    { name: "NRI Corner", id: 2 },
    { name: "Business Registration", id: 3 },
    { name: "Legal", id: 4 },
    { name: "Investments", id: 5 },
    { name: "Insurance", id: 6 },
  ];

  const [serviceOptions, setServiceOptions] = useState([])

  const getIconfromLink:any = (link:string) =>{
    if(link.toLowerCase().startsWith("https://facebook.com") || link.toLowerCase().startsWith("https://www.facebook.com")  || link.toLowerCase().startsWith("www.facebook.com") || link.toLowerCase().startsWith("facebook.com")){
      return FaFacebook
    }
    else if(link.toLowerCase().startsWith("https://twitter.com") || link.toLowerCase().startsWith("https://www.twitter.com") || link.toLowerCase().startsWith("www.twitter.com") || link.toLowerCase().startsWith("twitter.com")){
      return FaTwitter
    }
    else if(link.toLowerCase().startsWith("https://youtube.com") || link.toLowerCase().startsWith("https://www.youtube.com") || link.toLowerCase().startsWith("www.youtube.com") || link.toLowerCase().startsWith("youtube.com")){
      return IoLogoYoutube
    }
    else if(link.toLowerCase().startsWith("https://linkedin.com") || link.toLowerCase().startsWith("https://www.linkedin.com") || link.toLowerCase().startsWith("www.linkedin.com") || link.toLowerCase().startsWith("linkedin.com")){
      return FaLinkedin
    }
    else if(link.toLowerCase().startsWith("https://instagram.com") || link.toLowerCase().startsWith("https://www.instagram.com") || link.toLowerCase().startsWith("www.instagram.com") || link.toLowerCase().startsWith("instagram.com")){
      return FaInstagram
    }
    else{
      return FaLink
    }

  }

  const servicesMapping: any = {
    "Income Tax": [
      { name: "ITR Planning", id: 1 },
      { name: "Tax Filling", id: 2 },
      { name: "Notices & Scrunity", id: 3 },
      { name: "IT Appeals", id: 4 }
    ],
    "Business Registration": [
      { name: "GST Registration", id: 5 },
      { name: "GST Filling", id: 6 },
      { name: "Company Incorporation", id: 7 },
      { name: "Licenses Registration", id: 8 },
    ],
    "Investments": [
      { name: "Financial Planning", id: 9 },
      { name: "Investement Advisory", id: 10 },
      { name: "Research Services", id: 11 },
    ],
    "Legal": [
      { name: "Agreement Consulting & Drafting", id: 15 },
      { name: "Trademark & Patent Registration", id: 16 },
      { name: "Rera Advice", id: 17 },
      { name: "Payment Recovery Consultancy", id: 18 },
      { name: "Cibil Consultancy", id: 19 },
      { name: "Sebi Consultancy", id: 20 },
      { name: "Online Scam Consultant Process", id: 21 },
      { name: "Consumer Forum Complaints", id: 22 },
    ],
    "Insurance": [
      { name: "Life Insurance", id: 12 },
      { name: "Medi Claim", id: 13 },
      { name: "General Insurance", id: 14 },
    ],
    "NRI Corner": [
      { name: "NRI ITR Filling", id: 23 },
      { name: "FEMA Compliances", id: 24 },
      { name: "Lower TDS Deduction", id: 25 },
      { name: "15 CA/CB Remmitance", id: 26 },
      { name: "Tax Residency Certificate", id: 27 }
    ]
  }


  const services = [
    { name: "ITR Planning", id: 1 },
    { name: "Tax Filling", id: 2 },
    { name: "Notices & Scrunity", id: 3 },
    { name: "IT Appeals", id: 4 },
    { name: "GST Registration", id: 5 },
    { name: "GST Filling", id: 6 },
    { name: "Company Incorporation", id: 7 },
    { name: "Licenses Registration", id: 8 },
    { name: "Financial Planning", id: 9 },
    { name: "Investement Advisory", id: 10 },
    { name: "Research Services", id: 11 },
    { name: "Life Insurance", id: 12 },
    { name: "Medi Claim", id: 13 },
    { name: "General Insurance", id: 14 },
    { name: "Agreement Consulting & Drafting", id: 15 },
    { name: "Trademark & Patent Registration", id: 16 },
    { name: "Rera Advice", id: 17 },
    { name: "Payment Recovery Consultancy", id: 18 },
    { name: "Cibil Consultancy", id: 19 },
    { name: "Sebi Consultancy", id: 20 },
    { name: "Online Scam Consultant Process", id: 21 },
    { name: "Consumer Forum Complaints", id: 22 },
    { name: "NRI ITR Filling", id: 23 },
    { name: "FEMA Compliances", id: 24 },
    { name: "Lower TDS Deduction", id: 25 },
    { name: "15 CA/CB Remmitance", id: 26 },
    { name: "Tax Residency Certificate", id: 27 }
  ]

  const convertListToObject = (list: any) => {
    let listOfObject = [];
    if (list === null) {
      return [];
    } else {
      let tempOptions: any = []
      for (let index = 0; index < list.length; index++) {
        tempOptions = [...tempOptions, ...servicesMapping[list[index]]]
        listOfObject.push(
          options.filter((option) => option.name === list[index])[0]
        );
      }
      //setServiceOptions(tempOptions)
      return listOfObject;
    }
  };

  useEffect(() => {
    let tempOptions: any = [];
    if (props.userInfo.expertise) {
      for (let index = 0; index < props.userInfo.expertise.length; index++) {
        tempOptions = [...tempOptions, ...servicesMapping[props.userInfo.expertise[index]]]
      }
      setServiceOptions(tempOptions);
    }

  }, [])

  const convertServiceToObject = (list: any) => {
    let listOfObject = [];
    if (list === null) {
      return [];
    } else {
      for (let index = 0; index < list.length; index++) {
        listOfObject.push(
          services.filter((option) => option.name === list[index])[0]
        );
      }
      console.log(listOfObject)
      return listOfObject;
    }
  };

  const [professionalDetails, setProfessionalDetails]: any = useState({
    experience: props.userInfo.experience,
    expertise: convertListToObject(
      props.userInfo.expertise ? props.userInfo.expertise : null
    ),
    serviceList: convertServiceToObject(props.userInfo.professionalDetails ?
      Object.keys(props.userInfo.professionalDetails.services) : null),
    services: props.userInfo.professionalDetails ?
      props.userInfo.professionalDetails.services : {},
    clients: props.userInfo.professionalDetails
      ? props.userInfo.professionalDetails.clients
      : null,
    links: props.userInfo.professionalDetails
      ? props.userInfo.professionalDetails.links
      : [""],
    achivement: props.userInfo.professionalDetails
      ? props.userInfo.professionalDetails.achivement ? props.userInfo.professionalDetails.achivement : [""]
      : [""],
    award: props.userInfo.professionalDetails
      ? props.userInfo.professionalDetails.award ? props.userInfo.professionalDetails.award : [""]
      : [""],
    summary: props.userInfo.professionalDetails
      ? props.userInfo.professionalDetails.summary
      : null,
  });

  const handleValueChange = (field: any, value: any) => {
    let tempData = professionalDetails;
    tempData[field] = value;
    setProfessionalDetails(tempData);
  };



  const submitprofessionalDetails = (event: any) => {
    event.preventDefault();
    if (professionalDetails.expertise.length === 0) {
      toast.error("Atleast select 1 category to proceed");
      return;
    }

    if (Object.keys(professionalDetails.services).length === 0) {
      toast.error("Atleast select 1 service to proceed");
      return;
    }

    if (professionalDetails.experience.length > 2) {
      toast.error("Experience cannot be greater than 99 years")
      return
    }

    if (professionalDetails.clients.length > 3) {
      toast.error("Clients cannot be greater than 999")
      return
    }

    let linksSet = new Set(professionalDetails.links)
    if(linksSet.size !== professionalDetails.links.length){
      toast.error("Duplicate Social Media Links, please remove the duplicate values.")
      return
    }

    let expertise: any = [];
    for (let index = 0; index < professionalDetails.expertise.length; index++) {
      expertise.push(professionalDetails.expertise[index]["name"]);
    }

    let tempData: any = professionalDetails;
    tempData.expertise = expertise;
    delete tempData.serviceList;

    
    let tempAwards:any = []
    for(let index=0;index<professionalDetails.award.length;index++){
      if(professionalDetails.award[index].trim().length!== 0){
        tempAwards.push(professionalDetails.award[index])
      }
    }
    tempData.award = tempAwards


    let tempAchivement:any = []
    for(let index=0;index<professionalDetails.achivement.length;index++){
      if(professionalDetails.achivement[index].trim().length!== 0){
        tempAchivement.push(professionalDetails.achivement[index])
      }
    }
    tempData.achivement = tempAchivement

    let tempLinks:any = []
    for(let index=0;index<professionalDetails.links.length;index++){
      if(professionalDetails.links[index].trim().length!== 0){
        tempLinks.push(professionalDetails.links[index])
      }
    }
    tempData.links = tempLinks

    console.log(tempData)
    AxiosInstance()
      .post("/consultant/updateConsultantProfessionalDetails", tempData)
      .then(({ data }) => {
        props.onSuccess();
      })
      .catch((err) => {
        toast.error("Failed to update professional details, please try again");
      });
  };

  const onChangeCategory = (selectedList: any, changedItem: any) => {
    let tempData = professionalDetails;
    console.log(tempData)
    tempData.expertise = selectedList;
    let tempOptions: any = []
    for (let index = 0; index < selectedList.length; index++) {
      tempOptions = [...tempOptions, ...servicesMapping[selectedList[index].name]]
    }
    let tempServices: any = {}
    let services = Object.keys(professionalDetails.services)
    for (let index = 0; index < services.length; index++) {
      let exists = tempOptions.map(function (e: any) { return e.name; }).indexOf(services[index]);
      if (exists !== -1) {
        tempServices[services[index]] = professionalDetails.services[services[index]]
      }
    }

    let serviceList: any = []
    console.log(professionalDetails.serviceList)
    console.log(tempData)
    if (professionalDetails.serviceList) {
      for (let index = 0; index < professionalDetails.serviceList.length; index++) {
        let exists = tempOptions.map(function (e: any) { return e.name; }).indexOf(professionalDetails.serviceList[index].name);
        if (exists !== -1) {
          serviceList.push(professionalDetails.serviceList[index])
        }
      }
    }
    tempData.services = tempServices
    tempData.serviceList = serviceList
    setServiceOptions(tempOptions)
    setProfessionalDetails({ ...tempData });
    serviceRef.current.state.selectedValues = serviceList
  };

  const onServiceChange = (selectedList: any, changedItem: any) => {
    let tempData = professionalDetails;
    let services: any = {}
    let oldServices = Object.keys(tempData.services)
    for (let index = 0; index < selectedList.length; index++) {
      if (oldServices.indexOf(selectedList[index].name) !== -1) {
        services[selectedList[index].name] = tempData.services[selectedList[index].name]
      }
      else {
        services[selectedList[index].name] = ""
      }
    }
    tempData.serviceList = selectedList;
    tempData.services = services;
    setProfessionalDetails({ ...tempData });
  }

  const handleServiceCostChange = (serviceName: any, cost: any) => {
    let tempData = professionalDetails
    professionalDetails.services[serviceName] = cost
    setProfessionalDetails({ ...tempData })
  }

  const addMoreField = (field: any) => {

    let tempData: any = professionalDetails
    tempData[field].push("")
    setProfessionalDetails({ ...tempData })
  }

  const updateField = (field: any, value: any, index: any) => {
    let tempData: any = professionalDetails
    tempData[field][index] = value
    setProfessionalDetails({ ...tempData })
  }

  const removeField = (field: any, index: any) => {
    console.log(field)
    let tempData = professionalDetails
    console.log(tempData)
    tempData[field].splice(index, 1)
    setProfessionalDetails({ ...tempData })
  }


  return (
    <ConsultantSideBar>
      <div className="m-4">
        <div className="text-3xl font-bold text-[#547AF7]">
          Let's get your Personal Details
        </div>
        <div className="mt-1 font-light text-lg">
          Adding information about your profile helps us driving credibility -
          None of this information will be shared without your consent
        </div>
        {props.onBoarding && (
          <>
            <div className="m-4 pl-8 pr-8 hidden lg:flex">
              {" "}
              <StepperComponent
                steps={[
                  "Personal Details",
                  "KYC Details",
                  "Professional Details",
                  "Summary",
                ]}
                activeStep={2}
              />
            </div>
            <div className="mt-4 mb-4 text-2xl font-extrabold lg:hidden">
              {" "}
              STEP: 3/4
            </div>
          </>
        )}
        <div className="mt-4">
          <form
            className="grid grid-cols-1 gap-4 lg:grid-cols-3"
            id="PersonalDetailsForm"
            onSubmit={submitprofessionalDetails}
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Years of Experience" />
              </div>
              <TextInput
                id="aadhar"
                type="number"
                placeholder="Enter Years of Experience"
                defaultValue={professionalDetails.experience}
                maxLength={12}
                onChange={(e) => {
                  handleValueChange("experience", e.target.value);
                }}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Clients worked with" />
              </div>
              <TextInput
                id="email"
                type="number"
                placeholder="Enter number of clients worked with"
                defaultValue={professionalDetails.clients}
                maxLength={10}
                onChange={(e) => {
                  handleValueChange("clients", e.target.value);
                }}
                required
              />
            </div>
            <div className="max-w-md">
              <div className="mb-2 flex justify-between items-center">
                <Label htmlFor="countries" value="Social Media Handles (Max 5)" />
                <div onClick={() => {
                  if (professionalDetails.links.length < 5) {
                    addMoreField("links")
                  }

                }} className={`text-xs ${professionalDetails.links.length < 5 ? "text-blue-700 cursor-pointer " : ""}`}>Add more</div>
              </div>
              {
                professionalDetails.links.map((achivement: any, index: any) => (
                  <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""} w-full `}>
                    <TextInput
                      id="email"
                      placeholder="Add your social media links"
                      defaultValue={achivement}
                      onChange={(e) => {
                        updateField("links", e.target.value, index);
                      }}
                      icon={getIconfromLink(achivement)}
                      className="w-full"
                    />
                    <div>
                      {index !== 0 && (
                        <IoIosCloseCircle className="text-red-700 cursor-pointer" onClick={() => { removeField("links", index) }} />
                      )}
                    </div>
                  </div>
                ))
              }
            </div>
            <div>
              <div className="mb-2 flex justify-between items-center">
                <Label htmlFor="countries" value="Awards (Max 5)" />
                <div onClick={() => {
                  if (professionalDetails.award.length < 5) {
                    addMoreField("award")
                  }

                }} className={`text-xs ${professionalDetails.award.length < 5 ? "text-blue-700 cursor-pointer " : ""}`}>Add more</div>
              </div>
              {
                professionalDetails.award.map((award: any, index: any) => (
                  <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""}`}>
                    <Textarea
                      id="email"
                      maxLength={160}

                      placeholder="Add you award details"
                      defaultValue={award}
                      onChange={(e) => {
                        updateField("award", e.target.value, index);
                      }}
                    />
                    <div>
                      {index !== 0 && (
                        <IoIosCloseCircle className="text-red-700 cursor-pointer" onClick={() => { removeField("award", index) }} />
                      )}
                    </div>
                  </div>
                ))
              }

            </div>

            <div>
              <div className="mb-2 flex justify-between items-center">
                <Label htmlFor="countries" value="Achivements (Max 5)" />
                <div onClick={() => {
                  if (professionalDetails.achivement.length < 5) {
                    addMoreField("achivement")
                  }

                }} className={`text-xs ${professionalDetails.achivement.length < 5 ? "text-blue-700 cursor-pointer " : ""}`}>Add more</div>
              </div>
              {
                professionalDetails.achivement.map((achivement: any, index: any) => (
                  <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""}`}>
                    <Textarea
                      id="email"
                      maxLength={160}
                      placeholder="Add you achivement details"
                      defaultValue={achivement}
                      onChange={(e) => {
                        updateField("achivement", e.target.value, index);
                      }}
                    />
                    <div>
                      {index !== 0 && (
                        <IoIosCloseCircle className="text-red-700 cursor-pointer" onClick={() => { removeField("achivement", index) }} />
                      )}
                    </div>
                  </div>
                ))
              }

            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Professional Summary" />
              </div>
              <Textarea
                id="email"
                rows={4}
                maxLength={250}
                placeholder="Enter a description"
                defaultValue={professionalDetails.summary}
                onChange={(e) => {
                  handleValueChange("summary", e.target.value);
                }}
                required
              /></div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Select Category (max 3)" />
              </div>
              <Multiselect
                options={options} // Options to display in the dropdown
                caseSensitiveSearch={true}
                avoidHighlightFirstOption={true}
                selectedValues={professionalDetails.expertise} // Preselected value to persist in dropdown
                onSelect={onChangeCategory} // Function will trigger on select event
                onRemove={onChangeCategory} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Select your Category"
                disable={props.display}
                selectionLimit={3}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Select Services (max 3)" />
              </div>
              <Multiselect
                options={serviceOptions} // Options to display in the dropdown
                caseSensitiveSearch={true}
                avoidHighlightFirstOption={true}
                selectedValues={professionalDetails.serviceList} // Preselected value to persist in dropdown
                onSelect={onServiceChange} // Function will trigger on select event
                onRemove={onServiceChange} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                placeholder="Select your Services"
                disable={props.display}
                selectionLimit={3}
                ref={serviceRef}
              />
            </div>
            {Object.keys(professionalDetails.services).length > 0 && <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Service Cost" />
              </div>
              {Object.keys(professionalDetails.services).map((serivce: any) => (
                <div className="grid gap-2 mt-2 grid-col-2 items-center">
                  <div>{serivce}</div>
                  <div><TextInput type="number"
                    placeholder="Enter amount"
                    defaultValue={professionalDetails.services[serivce]}
                    onChange={(e) => {
                      handleServiceCostChange(serivce, e.target.value);
                    }}
                    required
                  /></div>
                </div>
              ))}
            </div>}
          </form>
        </div>
        <div className="mt-4 flex justify-center gap-4 lg:justify-end">
          {props.onBoarding && (
            <Button
              className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
              size="xs"
              onClick={() => {
                props.setState("PROFILE_COMPLETED");
              }}
            >
              Previous
            </Button>
          )}
          <Button
            className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
            size="xs"
            form="PersonalDetailsForm"
            type="submit"
          >
            {props.onBoarding ? "Next" : "Update"}
          </Button>
        </div>
      </div>
    </ConsultantSideBar>
  );
}

export default ConsultantProfessionaInfo;
