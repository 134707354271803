import ConsultantSideBar from "./ConsultantComponents/ConsultantSideBar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CAImage from "../../Static/Images/CAImage.svg";
import HomePageImage from "../../Static/Images/HomePageImage.svg";
import NoSession from "../../Static/Images/NoSession.svg";

import RegistrationRejected from "../../Images/RegistationRejected.svg";

import { toast } from "react-toastify";

import { IoIosLink } from "react-icons/io";
import { FaRegClock } from "react-icons/fa6";
import moment from "moment";
import {
  BtnBold,
  BtnItalic,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import { Parser } from "html-to-react";
import AxiosInstance from "../../Utils/AxiosInstance";
import { Button, Modal } from "flowbite-react";
import SpinnerComponent from "../../Components/SpinnerComponent";

function ConsultantDashboard() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [feedbackSubmittedSessions, setFeedbackSubmittedSessions] = useState(
    []
  );

  const [selectedTab, setSelectedTab] = useState("Upcoming Session");
  const [experience, setExperience] = useState("");
  const [showSessionDetails, setShowSessionDetails] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackCall, setFeedbackCall]: any = useState({});
  const [feedback, setFeedback] = useState({
    description: "",
    documents: "",
  });

  const [slots, setSlots] = useState({});

  const htmlParser = Parser();

  const submitFeedback = () => {
    if (feedback.description.length === 0) {
      toast.error("Add Description to submit feedback");
      return;
    }

    setLoading(true);
    AxiosInstance()
      .post("/consultant/addMOM", {
        bookingId: feedbackCall._id,
        ...feedback,
      })
      .then(({ data }) => {
        console.log("Here");
        toast.success("Feedback submitted successfully");
        setShowFeedbackModal(false);
        getPendingFeedbackSession();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to submit feedback ${err?.message}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/getSlots")
      .then(({ data }) => {
        setLoading(false);
        setSlots(data);
        if (Object.keys(data).length > 0) {
          getUpcomingSlots();
        }
      })
      .catch((err) => {
        toast.error("Something went wrong please try again later");
      });
  }, []);

  const getUpcomingSlots = () => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/getUpcomingBookings")
      .then(({ data }) => {
        setUpcomingSessions(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get your upcoming sessions ${err.message}`);
        setLoading(false);
      });
  };

  const getPendingFeedbackSession = () => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/getPendingFeebackCalls")
      .then(({ data }) => {
        setPastSessions(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get your upcoming sessions ${err.message}`);
        setLoading(false);
      });
  };

  const getFeedbackSubmittedCalls = () => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/getFeedbackSubmittedCalls")
      .then(({ data }) => {
        setFeedbackSubmittedSessions(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get your upcoming sessions ${err.message}`);
        setLoading(false);
      });
  };

  const time: any = {
    "12 AM": 0,
    "1 AM": 1,
    "2 AM": 2,
    "3 AM": 3,
    "4 AM": 4,
    "5 AM": 5,
    "6 AM": 6,
    "7 AM": 7,
    "8 AM": 8,
    "9 AM": 9,
    "10 AM": 10,
    "11 AM": 11,
    "12 PM": 12,
    "1 PM": 13,
    "2 PM": 14,
    "3 PM": 15,
    "4 PM": 16,
    "5 PM": 17,
    "6 PM": 18,
    "7 PM": 19,
    "8 PM": 20,
    "9 PM": 21,
    "10 PM": 22,
    "11 PM": 23,
  };

  return (
    <ConsultantSideBar nav={true}>
      {Object.keys(slots).length > 0 ? (
        <div className="m-4 gap-1 h-full">
          <div className="flex flex-row gap-2 items-center">
            <div
              className={`pl-4 pt-1 pb-1 ${
                selectedTab === "Upcoming Session"
                  ? "border-l-4 border-[#0CBC8B] font-bold text-lg text-[#212121]"
                  : "text-sm text-[#B1B1B1] cursor-pointer"
              }`}
              onClick={() => {
                getUpcomingSlots();
                setSelectedTab("Upcoming Session");
              }}
            >
              Upcoming Session
            </div>
            <div
              className={`pl-2 pt-1 pb-1 ${
                selectedTab === "Past Session"
                  ? "border-l-4 border-[#0CBC8B] font-bold text-lg text-[#212121]"
                  : "text-sm text-[#B1B1B1] cursor-pointer"
              }`}
              onClick={() => {
                getPendingFeedbackSession();
                setSelectedTab("Past Session");
              }}
            >
              Pending Feedback
            </div>
            <div
              className={`pl-2 pt-1 pb-1 ${
                selectedTab === "Submitted Feedback"
                  ? "border-l-4 border-[#0CBC8B] font-bold text-lg text-[#212121]"
                  : "text-sm text-[#B1B1B1] cursor-pointer"
              }`}
              onClick={() => {
                setShowSessionDetails("");
                getFeedbackSubmittedCalls();
                setSelectedTab("Submitted Feedback");
              }}
            >
              Submitted Feedback
            </div>
          </div>
          <div className="h-full overflow-y-scroll">
            {selectedTab === "Upcoming Session" && (
              <>
                {upcomingSessions.length === 0 ? (
                  <div className="flex flex-col items-center justify-center w-full h-full p-8">
                    <img src={NoSession} />
                    <div className="text-sm text-[#121212]">
                      No Upcoming Sessions Available
                    </div>
                  </div>
                ) : (
                  <div className="p-4 h-full overflow-y-auto">
                    {upcomingSessions.map((session: any) => (
                      <div className="pb-2 mt-2 border-b-2 border-[#DEDEDE]">
                        <div className="flex justify-between rounded-lg bg-white p-4 items-center">
                          <div className="flex gap-2 items-center">
                            <img src={CAImage} />
                            <div>
                              <div className="flex gap-1">
                                <div className="font-bold">
                                  {session.userInfo.username}
                                </div>
                              </div>
                              <div className="text-xs font-light">
                                Session for {session.services.toString()}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-1 flex-col">
                            <div className="flex gap-1 items-center font-bold">
                              <FaRegClock />
                              {moment(session.startTime).format(
                                "MMMM Do YYYY, h:mm a"
                              )}
                            </div>
                            <div
                              className="flex gap-1 items-center font-light text-xs text text-[#547AF7] cursor-pointer"
                              onClick={() => {
                                window.open(
                                  session.meetingUrl,
                                  "_blank",
                                  "noreferrer"
                                );
                              }}
                            >
                              <IoIosLink /> Join Session
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {selectedTab === "Past Session" && (
              <>
                {pastSessions.length === 0 ? (
                  <div className="flex flex-col items-center justify-center w-full h-full p-8">
                    <img src={NoSession} />
                    <div className="text-sm text-[#121212]">
                      No Pending Feedbacks
                    </div>
                  </div>
                ) : (
                  <div className="p-4 flex flex-col h-full overflow-y-auto">
                    {pastSessions.map((session: any) => (
                      <div className="pb-2 mt-2 border-b-2 border-[#DEDEDE]">
                        <div className="flex justify-between rounded-lg bg-white p-4 items-center">
                          <div className="flex gap-2 items-center">
                            <img src={CAImage} />
                            <div>
                              <div className="flex gap-1">
                                <div className="font-bold">
                                  {session.userInfo.username}
                                </div>
                              </div>
                              <div className="text-xs font-light">
                                Session for {session.services.toString()}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-1 flex-col">
                            <div className="flex gap-1 items-center font-bold">
                              <FaRegClock />
                              {moment(session.startTime).format(
                                "MMMM Do YYYY, h:mm a"
                              )}
                            </div>
                            <div className="flex gap-3 items-center font-light text-xs ">
                              <div
                                className="flex items-center text-[#547AF7] cursor-pointer gap-1"
                                onClick={() => {
                                  setFeedbackCall(session);
                                  setFeedback({
                                    description: "",
                                    documents: "",
                                  });
                                  setShowFeedbackModal(true);
                                }}
                              >
                                <div>Submit Feedback</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {selectedTab === "Submitted Feedback" && (
              <>
                {feedbackSubmittedSessions.length === 0 ? (
                  <div className="flex flex-col items-center justify-center w-full h-full p-8">
                    <img src={NoSession} />
                    <div className="text-sm text-[#121212]">
                      No Feedbacks Submitted
                    </div>
                  </div>
                ) : (
                  <div className="p-4 flex flex-col h-full overflow-y-auto">
                    {feedbackSubmittedSessions.map((session: any) => (
                      <div className="pb-2 mt-2 border-b-2 border-[#DEDEDE]">
                        <div className="rounded-lg bg-white p-4 ">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <img src={CAImage} />
                              <div>
                                <div className="flex gap-1">
                                  <div className="font-bold">
                                    {session.userInfo.username}
                                  </div>
                                </div>
                                <div className="text-xs font-light">
                                  Session for {session.services.toString()}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="flex gap-1 items-center font-bold">
                                <FaRegClock />
                                {moment(session.startTime).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}
                              </div>
                              <div className="flex gap-3 items-center font-light text-xs ">
                                <div
                                  className="flex items-center text-[#547AF7] cursor-pointer gap-1"
                                  onClick={() => {
                                    setShowSessionDetails(session._id);
                                  }}
                                >
                                  <div>View Summary</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {showSessionDetails === session._id && (
                            <div>
                              {session.momAdded ? (
                                <div className="flex flex-col gap-2 bg-white">
                                  <div>
                                    <div className="font-extrabold mt-2">
                                      Session Summary
                                    </div>
                                    <div className="mt-1 text-xs">
                                      {htmlParser.parse(
                                        session.mom.description
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="font-extrabold mt-2">
                                      Documents
                                    </div>
                                    <div className="mt-1 text-xs">
                                      {htmlParser.parse(session.mom.documents)}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="flex mt-2 mb-2 items-center text-xs font-light justify-center">
                                  Summary not added yet
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="h-full w-full justify-center items-center text-center flex flex-col p-4">
          <img src={RegistrationRejected} />
          <div className="font-bold text-2xl text-[#FF5F69] mt-2 mb-2">
            Action Needed
          </div>
          <div className="text-sm font-light mb-2">
            <strong>You have not added your Availability Details</strong>.
          </div>
          <div className="text-sm font-light mb-8">
            Add your Availability Details to get started
          </div>
          <Button
            className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
            size="xs"
            onClick={() => {
              navigate("/consultant/profile", {
                state: {
                  slots: true,
                },
              });
            }}
          >
            Add Availability
          </Button>
        </div>
      )}

      <Modal show={showFeedbackModal} className="p-4">
        <div className="p-4">
          <div className="flex items-center justify-between">
            <div className="text-xl font-bold">Submit Feedback</div>
            <div
              className="font-bold cursor-pointer"
              onClick={() => {
                setShowFeedbackModal(false);
              }}
            >
              X
            </div>
          </div>
          <div>
            <div className="mt-2 mb-2 text-xs text-light">Description</div>
            <EditorProvider>
              <Editor
                containerProps={{ style: { height: "150px" } }}
                value={feedback.description}
                onChange={(event: any) => {
                  let tempFeedback = feedback;
                  tempFeedback.description = event.target.value;
                  setFeedback({ ...tempFeedback });
                }}
              >
                <Toolbar>
                  <BtnBold />
                  <BtnItalic />
                  <BtnUnderline />
                  <BtnStrikeThrough />
                </Toolbar>
              </Editor>
            </EditorProvider>
          </div>
          <div>
            <div className="mt-2 mb-2 text-xs text-light">Documents</div>
            <EditorProvider>
              <Editor
                containerProps={{ style: { height: "150px" } }}
                value={feedback.documents}
                onChange={(event: any) => {
                  console.log(event.target.value);
                  let tempFeedback = feedback;
                  tempFeedback.documents = event.target.value;
                  setFeedback({ ...tempFeedback });
                }}
              >
                <Toolbar>
                  <BtnBold />
                  <BtnItalic />
                  <BtnUnderline />
                  <BtnStrikeThrough />
                </Toolbar>
              </Editor>
            </EditorProvider>
          </div>
          <div className="flex justify-end">
            <Button
              className="mt-2 mb-2 bg-[#547AF7] font-bold text-sm"
              onClick={submitFeedback}
            >
              {" "}
              Submit Feedback
            </Button>
          </div>
        </div>
        {loading ? <SpinnerComponent /> : null}
      </Modal>
      {loading ? <SpinnerComponent /> : null}
    </ConsultantSideBar>
  );
}

export default ConsultantDashboard;
