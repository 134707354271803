import {
  Avatar,
  Button,
  Label,
  Radio,
  Select,
  Textarea,
  TextInput,
} from "flowbite-react";
import ConsultantProfileInfo from "./ConsultantProfileInfo";
import ConsultantSideBar from "./ConsultantSideBar";
import Multiselect from "multiselect-react-dropdown";
import StepperComponent from "../../../Components/StepperComponent";
import AxiosInstance from "../../../Utils/AxiosInstance";
import { toast } from "react-toastify";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLink } from "react-icons/fa6";

function ConsultantProfile(props: any) {
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  let qualifications = ["Graduate", "Post Graduate", "P.Hd."];

  const options = [
    { name: "Income Tax", id: 1 },
    { name: "NRI Corner", id: 2 },
    { name: "Business Registration", id: 3 },
    { name: "Legal", id: 4 },
    { name: "Investments", id: 5 },
    { name: "Insurance", id: 6 },
  ];

  const submitForVerification = () => {
    AxiosInstance()
      .post("/consultant/submitForVerification")
      .then(({ data }) => {
        props.onSuccess();
      })
      .catch((err) => {
        toast.error("Failed to Register, please try again");
      });
  };

  const getIconfromLink:any = (link:string) =>{
    if(link.toLowerCase().startsWith("https://facebook.com") || link.toLowerCase().startsWith("https://www.facebook.com")  || link.toLowerCase().startsWith("www.facebook.com") || link.toLowerCase().startsWith("facebook.com")){
      return FaFacebook
    }
    else if(link.toLowerCase().startsWith("https://twitter.com") || link.toLowerCase().startsWith("https://www.twitter.com") || link.toLowerCase().startsWith("www.twitter.com") || link.toLowerCase().startsWith("twitter.com")){
      return FaTwitter
    }
    else if(link.toLowerCase().startsWith("https://youtube.com") || link.toLowerCase().startsWith("https://www.youtube.com") || link.toLowerCase().startsWith("www.youtube.com") || link.toLowerCase().startsWith("youtube.com")){
      return IoLogoYoutube
    }
    else if(link.toLowerCase().startsWith("https://linkedin.com") || link.toLowerCase().startsWith("https://www.linkedin.com") || link.toLowerCase().startsWith("www.linkedin.com") || link.toLowerCase().startsWith("linkedin.com")){
      return FaLinkedin
    }
    else if(link.toLowerCase().startsWith("https://instagram.com") || link.toLowerCase().startsWith("https://www.instagram.com") || link.toLowerCase().startsWith("www.instagram.com") || link.toLowerCase().startsWith("instagram.com")){
      return FaInstagram
    }
    else{
      return FaLink
    }

  }

  return (
    <ConsultantSideBar>
      <div className="m-4">
        <div className="text-3xl font-bold text-[#547AF7]">
          Let's get your Personal Details
        </div>
        <div className="mt-1 font-light text-lg">
          Adding information about your profile helps us driving credibility -
          None of this information will be shared without your consent
        </div>
        {props.onBoarding && (
          <>
            <div className="m-4 pl-8 pr-8 hidden lg:flex">
              {" "}
              <StepperComponent
                steps={[
                  "Personal Details",
                  "KYC Details",
                  "Professional Details",
                  "Summary",
                ]}
                activeStep={3}
              />
            </div>
            <div className="mt-4 mb-4 text-2xl font-extrabold lg:hidden">
              {" "}
              STEP: 4/4
            </div>
          </>
        )}
      </div>
      <div>
        <div className="grid grid-cols-1 gap-4">
          <div className="m-4 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Profile Pic" />
                </div>
                <Avatar img={props.userInfo.profilePic} />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Full Name" />
                </div>
                <div className="flex gap-1 items-center">
                  <Select
                    id="countries"
                    defaultValue={props.userInfo.prefix}
                    disabled
                  >
                    <option>{props.userInfo.prefix === "" ? "Others" : props.userInfo.prefix}</option>
                  </Select>
                  <TextInput
                    id="name"
                    type="text"
                    placeholder="Enter Name"
                    defaultValue={props.userInfo.username}
                    disabled
                  />
                </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Email Id" />
                </div>
                <TextInput
                  id="email"
                  type="email"
                  placeholder="Enter Email"
                  defaultValue={props.userInfo.email}
                  disabled
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Occupation" />
                </div>
                <TextInput
                  id="occuption"
                  type="text"
                  placeholder="Enter Occupation"
                  defaultValue={props.userInfo.occupation}
                  disabled
                />
              </div>
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="State" />
                </div>
                <Select
                  id="countries"
                  defaultValue={props.userInfo.state}
                  disabled
                >
                  {states.map((state) => (
                    <option>{state}</option>
                  ))}
                </Select>
              </div>
            </div>
            <div
              className="mt-2 flex justify-end cursor-pointer font-bold text-[#547AF7] text-sm"
              onClick={() => {
                if (props.onBoarding) {
                  props.setState("NEW_ACCOUNT");
                }
              }}
            >
              Edit Information
            </div>
          </div>
          <div className="m-4 mt-0 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Aadhar Number" />
                </div>
                <TextInput
                  id="aadhar"
                  type="text"
                  placeholder="Enter aadhar Number"
                  defaultValue={props.userInfo.kycDetails?.aadhar}
                  maxLength={12}
                  disabled
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="PAN Number" />
                </div>
                <TextInput
                  id="email"
                  type="text"
                  placeholder="Enter PAN number"
                  defaultValue={props.userInfo.kycDetails?.pan}
                  maxLength={10}
                  disabled
                />
              </div>
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="Highest Qualification" />
                </div>
                <Select
                  id="countries"
                  defaultValue={props.userInfo.kycDetails?.qualification}
                  disabled
                >
                  {qualifications.map((qualification) => (
                    <option>{qualification}</option>
                  ))}
                </Select>
              </div>
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="Degree" />
                  {
                    props.userInfo.kycDetails?.degree.map((value: any, index: any) => (
                      <Select
                        id="countries"
                        defaultValue={value}
                        disabled
                        className={`${index !== 0 ? "mt-2" : ""}`}
                      >
                        <option>{value}</option>

                      </Select>
                    ))
                  }
                </div>

              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Holiding Certificate" />
                </div>
                <div className="flex items-center gap-8 flex-row">
                  <div className="flex items-center gap-2">
                    <Radio
                      id="united-state"
                      name="countries"
                      value="True"
                      defaultChecked={
                        props.userInfo.kycDetails?.certificate === "True"
                      }
                      disabled
                    />
                    <Label htmlFor="united-state">Yes</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      id="united-state"
                      name="countries"
                      value="False"
                      defaultChecked={
                        props.userInfo.kycDetails?.certificate === "False"
                      }
                      disabled
                    />
                    <Label htmlFor="united-state">No</Label>
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Institute/License Number" />
                </div>
                <TextInput
                  id="occuption"
                  type="text"
                  placeholder="Enter License Number"
                  defaultValue={props.userInfo.kycDetails?.license}
                  disabled
                />
              </div>
            </div>

            <div
              className="mt-2 flex justify-end cursor-pointer font-bold text-[#547AF7] text-sm"
              onClick={() => {
                if (props.onBoarding) {
                  props.setState("PROFILE_COMPLETED");
                }
              }}
            >
              Edit Information
            </div>
          </div>
          <div className="m-4 mt-0 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Years of Experience" />
                </div>
                <TextInput
                  id="aadhar"
                  type="number"
                  placeholder="Enter Years of Experience"
                  defaultValue={props.userInfo.experience}
                  maxLength={12}
                  disabled
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Clients worked with" />
                </div>
                <TextInput
                  id="email"
                  type="number"
                  placeholder="Enter number of clients worked with"
                  defaultValue={props.userInfo.professionalDetails?.clients}
                  maxLength={10}
                  disabled
                />
              </div>
              <div className="max-w-md">
              <div className="mb-2 flex justify-between items-center">
                <Label htmlFor="countries" value="Social Media Handles (Max 5)" />
              </div>
              {
                props.userInfo.professionalDetails?.links.map((achivement: any, index: any) => (
                  <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""} w-full cursor-pointer`} onClick={()=>{window.open(achivement, '_blank', 'noopener,noreferrer')}}>
                    <TextInput
                      id="email"
                      placeholder="Add your social media links"
                      defaultValue={achivement}
                      disabled
                      icon={getIconfromLink(achivement)}
                      className="w-full "

                    />
                  </div>
                ))
              }
            </div>
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="Awards" />
                  {
                    props.userInfo.professionalDetails?.award.map((value: any, index: any) => (
                      <Textarea
                        id="countries"
                        defaultValue={value}
                        disabled
                        className={`${index !== 0 ? "mt-2" : ""}`}
                      />
                    ))
                  }
                </div>

              </div>
              <div className="max-w-md">
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="Achivements" />
                  {
                    props.userInfo.professionalDetails?.achivement.map((value: any, index: any) => (
                      <Textarea
                        id="countries"
                        defaultValue={value}
                        disabled
                        className={`${index !== 0 ? "mt-2" : ""}`}
                      />
                    ))
                  }
                </div>

              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Professional Summary" />
                </div>
                <Textarea
                  id="email"
                  rows={4}
                  placeholder="Enter a description"
                  defaultValue={props.userInfo.professionalDetails?.summary}
                  disabled
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Select Category (max 3)" />
                </div>
                <Multiselect
                  options={options} // Options to display in the dropdown
                  selectedValues={options.filter(
                    (option) =>
                      props.userInfo.expertise &&
                      props.userInfo.expertise.indexOf(option.name) !== -1
                  )} // Preselected value to persist in dropdown
                  displayValue="name" // Property name to display in the dropdown options
                  placeholder="Select your Category"
                  disable
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Services" />
                </div>
                {Object.keys(props.userInfo.professionalDetails?.services).map(
                  (serivce: any) => (
                    <div className="grid gap-2 mt-2 grid-col-2 items-center">
                      <div>{serivce}</div>
                      <div>
                        <TextInput
                          type="number"
                          placeholder="Enter amount"
                          defaultValue={
                            props.userInfo.professionalDetails?.services[
                            serivce
                            ]
                          }
                          disabled
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div
              className="flex justify-end cursor-pointer font-bold text-[#547AF7] mt-2 text-sm"
              onClick={() => {
                if (props.onBoarding) {
                  props.setState("KYC_ADDED");
                }
              }}
            >
              Edit Information
            </div>
          </div>
        </div>
      </div>
      {props.onBoarding && (
        <div className="flex m-4 flex lg:justify-end gap-4">
          {props.onBoarding && (
            <Button
              className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
              size="xs"
              onClick={() => {
                props.setState("KYC_ADDED");
              }}
            >
              Previous
            </Button>
          )}
          <Button
            className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
            size="xs"
            onClick={submitForVerification}
          >
            Register Now
          </Button>
        </div>
      )}
    </ConsultantSideBar>
  );
}

export default ConsultantProfile;
