import { useEffect, useState } from "react";
import MobileHomePage from "./MobileHomePage";
import DesktopHomePage from "./DesktopHomePage";

import { Button, Checkbox, Modal } from "flowbite-react";

import LoginPopup from "../Images/LoginPopup.svg";
import PopupBottom from "../Images/PopupBottom.svg";
import TaxviewLogo from "../Images/TaxviewLogo.svg";
import PopupTop from "../Images/PopupTop.svg";
import PopupBottomMobile from "../Images/PopupBottomMobile.svg";
import AxiosInstance from "../Utils/AxiosInstance";
import { toast } from "react-toastify";
import SpinnerComponent from "../Components/SpinnerComponent";
import { useNavigate } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import Multiselect from "multiselect-react-dropdown";
import userLogin from "../Images/userlogin.png"
import consultantLogin from "../Images/consultantlogin.png"

function NewHomePage() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRequestCallBackModal, setShowRequestCallBackModal] =
    useState(false);
  const [consultant, setConsultant] = useState(false);
  const [pageState, setPageState] = useState("number");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [sendWhatsAppNotifications, setSendWhatsappNotifications] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [showLogin,setShowLogin] = useState(false)

  const [requestCallbackDetails, setRequestCallbackDetails]: any = useState({});

  const navigate = useNavigate();

  const handleMobileNumberChange = (event: any) => {
    if (event.target.value.length <= 10) {
      setMobileNumber(event.target.value);
    }
  };

  const handleOTPChange = (event: any) => {
    if (event.target.value.length <= 6) {
      setOTP(event.target.value);
    }
  };

  const handleRequestCallbackDetailsChange = (field: any, value: any) => {
    if (field === "number" && value.length > 10) {
      return;
    }
    let tempDetails = requestCallbackDetails;
    tempDetails[field] = value;
    setRequestCallbackDetails(tempDetails);
  };
  const bookaSession = () => {
    if (localStorage.token) {
      setLoading(true);
      AxiosInstance()
        .get("/users/verify")
        .then(({ data }) => {
          setLoading(false);
          if (data.username) {
            localStorage.setItem("username", data.username);
          }
          if (data.email) {
            localStorage.setItem("email", data.email);
          }

          localStorage.setItem("firstSlot", data.firstSlot ? "True" : "False");

          if (data.status === "NEW_ACCOUNT") {
            setPageState("newAccount");
            setName("");
            setEmail("");
          } else {
            navigate("/dashboard");
          }
        });
    } else {
      setConsultant(false);
      setShowLoginModal(true);
    }
  };

  const joinNow = () => {
    if (localStorage.token) {
      setLoading(true);
      AxiosInstance()
        .get("/consultant/verify")
        .then(({ data }) => {
          setLoading(false);
          if (data.username) {
            localStorage.setItem("username", data.username);
          }
          if (data.email) {
            localStorage.setItem("email", data.email);
          }

          if (data.status == "ACCOUNT_VERIFIED") {
            navigate("/consultant/dashboard");
          } else {
            navigate("/consultant/onboarding");
          }
        });
    } else {
      setConsultant(true);
      setShowLoginModal(true);
    }
  };

  const sendOTP = () => {
    setLoading(true);
    if (consultant) {
      AxiosInstance()
        .post("/consultant/sendOTP", { number: `+91${mobileNumber}` })
        .then(({ data }) => {
          toast.success("OTP sent successfully");
          setPageState("otp");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`Failed to send OTP, please try again`);
        });
    } else {
      AxiosInstance()
        .post("/users/sendOTP", { number: `+91${mobileNumber}` })
        .then(({ data }) => {
          toast.success("OTP sent successfully");
          setPageState("otp");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(`Failed to send OTP, please try again`);
        });
    }
  };

  const verifyOTP = () => {
    setLoading(true);
    if (consultant) {
      AxiosInstance()
        .post("/consultant/verifyOTP", {
          number: `+91${mobileNumber}`,
          otp: otp,
        })
        .then(({ data }) => {
          localStorage.setItem("token", data.token);
          if (data.username) {
            localStorage.setItem("username", data.username);
          }
          if (data.email) {
            localStorage.setItem("email", data.email);
          }
          localStorage.setItem("consultant", "true");

          if (data.status == "ACCOUNT_VERIFIED") {
            navigate("/consultant/dashboard");
          } else {
            navigate("/consultant/onboarding");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.message);
          setLoading(false);
        });
    } else {
      AxiosInstance()
        .post("/users/verifyOTP", { number: `+91${mobileNumber}`, otp: otp })
        .then(({ data }) => {
          localStorage.setItem("token", data.token);
          if (data.username) {
            localStorage.setItem("username", data.username);
          }
          if (data.email) {
            localStorage.setItem("email", data.email);
          }

          localStorage.setItem("firstSlot", data.firstSlot ? "True" : "False");

          if (data.status === "NEW_ACCOUNT") {
            setPageState("newAccount");
            setName("");
            setEmail("");
          } else {
            navigate("/dashboard");
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err?.message);
          setLoading(false);
        });
    }
  };

  const handlePersonalDetailsUpdate = (event: any) => {
    event.preventDefault();
    setLoading(true);
    AxiosInstance()
      .post("/users/updateUserDetails", {
        username: name,
        email: email,
      })
      .then(({ data }) => {
        localStorage.setItem("username", name);
        localStorage.setItem("email", email);
        navigate("/dashboard");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to update personal details ${err?.message}`);
        setLoading(false);
      });
  };
  const [width, setWidth] = useState(window.innerWidth);
  console.log(width);

  useEffect(() => {
    if (width >= 1024) {
      document.documentElement.style.setProperty("--design-width", "1980");
    } else {
      document.documentElement.style.setProperty("--design-width", "375");
    }
    function handleResize() {
      console.log(window.innerWidth);
      if (window.innerWidth >= 1024) {
        document.documentElement.style.setProperty("--design-width", "1980");
      } else {
        document.documentElement.style.setProperty("--design-width", "375");
      }
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const requestCallbackSubmission = (event: any) => {
    event.preventDefault();
    AxiosInstance().post("/users/requestCallback",requestCallbackDetails).then(({data})=>{
      toast.success("We have got your request and we will get back to you soon.")
      setShowRequestCallBackModal(false)
    }).catch((err)=>{
      toast.error("Failed to request callback, please try again")
    })
  };

  return (
    <div>
      {width >= 1024 ? (
        <DesktopHomePage
          openClientLogin={bookaSession}
          openConsultantLogin={joinNow}
          openRequestCallback={() => {
            setRequestCallbackDetails({})
            setShowRequestCallBackModal(true);
          }}
          openLogin={()=>{setShowLogin(true)}}
        />
      ) : (
        <MobileHomePage
          openClientLogin={bookaSession}
          openConsultantLogin={joinNow}
          openLogin={()=>{setShowLogin(true)}}
        />
      )}
      {loading && <SpinnerComponent />}
      <Modal show={showLoginModal} className="relative">
        <div className="h-96 static">
          <div className="flex static">
            <div className="hidden lg:block lg:w-1/2 static">
              <img src={TaxviewLogo} className="absolute top-4 left-4 h-6" />
              <img
                src={PopupBottom}
                className="absolute bottom-0 left-0 rounded-bl-md h-40 z-0"
              />
              <img
                src={LoginPopup}
                className="absolute w-4/6 bottom-0 h-60 -left-20 z-0"
              />
            </div>
            <div className="static w-full lg:w-1/2">
              <img
                src={PopupTop}
                className="absolute top-0 right-0 h-10 rounded-tr-md"
              />
              <img
                src={PopupBottomMobile}
                className="block absolute bottom-0 left-0 lg:hidden rounded-bl-md"
              />
              <div
                className="cursor-pointer absolute top-1 right-4 text-white"
                onClick={() => {
                  setConsultant(false);
                  setShowLoginModal(false);
                }}
              >
                X
              </div>
              <div className="mt-6 p-4 z-10 relative">
                <div className="text-xl font-bold">Let's get you Started!</div>
                <div className="text-xs font-light">
                  {consultant
                    ? "Be a Taxview Expert"
                    : "Book a session with our experts"}
                </div>
                {pageState !== "newAccount" ? (
                  <div id="login-section">
                    <div id="number-section" className="mt-6">
                      <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                        <div>Mobile Number</div>
                        {mobileNumber.length === 10 &&
                          pageState === "number" && (
                            <div
                              className="cursor-pointer text-[#547AF7] font-bold"
                              onClick={sendOTP}
                            >
                              Get OTP
                            </div>
                          )}
                      </div>
                      <input
                        className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Enter your Mobile Number"
                        disabled={pageState !== "number"}
                        type="number"
                        id="mobile-number"
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                      />
                    </div>
                    {pageState === "otp" && (
                      <div
                        className="cursor-pointer text-[#547AF7] font-bold mt-1 text-xs"
                        onClick={() => {
                          sendOTP();
                        }}
                      >
                        Resend OTP
                      </div>
                    )}
                    <div id="otp-section" className="mt-6">
                      <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                        <div>OTP</div>
                        {pageState === "otp" && (
                          <div
                            className="cursor-pointer text-[#547AF7] font-bold"
                            onClick={() => {
                              setMobileNumber("");
                              setOTP("");
                              setPageState("number");
                              document.getElementById("mobile-number")?.focus();
                            }}
                          >
                            Change Number
                          </div>
                        )}
                      </div>

                      <input
                        className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-2"
                        placeholder="Enter OTP"
                        disabled={pageState !== "otp"}
                        type="number"
                        value={otp}
                        onChange={handleOTPChange}
                      />
                    </div>

                    <div
                      className="flex justify-between items-center mt-4"
                      id="whatsapp-section"
                    >
                      <div className="flex items-center gap-1">
                        <IoLogoWhatsapp className="text-[#27B43E]" />{" "}
                        <div className="text-xs">
                          {" "}
                          Subscribe to get updates via Whatsapp
                        </div>
                      </div>
                      <div>
                        <Checkbox
                          checked={sendWhatsAppNotifications}
                          onClick={(event: any) => {
                            console.log(event);
                            setSendWhatsappNotifications(event.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        className="text-xs w-full bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                        size="xs"
                        disabled={pageState !== "otp" || otp.length != 6}
                        onClick={verifyOTP}
                      >
                        Log In
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div id="onboarding-section">
                    <form onSubmit={handlePersonalDetailsUpdate}>
                      <div id="name-section" className="mt-6">
                        <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                          <div>Tell us your Name</div>
                        </div>
                        <input
                          className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                          placeholder="Enter your Name"
                          type="text"
                          id="mobile-number"
                          value={name}
                          required
                          onChange={(event: any) => {
                            setName(event.target.value);
                          }}
                        />
                      </div>
                      <div id="email-section" className="mt-6">
                        <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                          <div>Email Id</div>
                        </div>
                        <input
                          className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-2"
                          placeholder="Enter you Email Id"
                          type="email"
                          value={email}
                          required
                          onChange={(event: any) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-4">
                        <Button
                          className="text-xs w-full bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                          size="xs"
                          type="submit"
                        >
                          Next
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
            <div></div>
          </div>
        </div>
        {loading ? <SpinnerComponent /> : null}
      </Modal>
      <Modal show={showRequestCallBackModal} className="relative">
        <div className="static">
          <div className="flex static">
            <div className="hidden lg:block lg:w-1/2 static">
              <img src={TaxviewLogo} className="absolute top-4 left-4 h-6" />
              <img
                src={PopupBottom}
                className="absolute bottom-0 left-0 rounded-bl-md h-40 z-0"
              />
              <img
                src={LoginPopup}
                className="absolute w-4/6 bottom-0 h-60 -left-20 z-0"
              />
            </div>
            <div className="static w-full lg:w-1/2 h-inherit">
              <img
                src={PopupTop}
                className="absolute top-0 right-0 h-10 rounded-tr-md"
              />
              <img
                src={PopupBottomMobile}
                className="block absolute bottom-0 left-0 lg:hidden rounded-bl-md"
              />
              <div
                className="cursor-pointer absolute top-1 right-4 text-white"
                onClick={() => {
                  setShowRequestCallBackModal(false);
                }}
              >
                X
              </div>
              <div className="mt-6 p-4 z-10 relative">
                <div className="text-md font-bold">
                  Provide your details and we will get back to you
                </div>
                <form onSubmit={requestCallbackSubmission}>
                  <div id="login-section">
                    <div id="number-section" className="mt-2">
                      <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                        <div>Name</div>
                      </div>
                      <input
                        className="border-0 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Enter your Name"
                        required
                        type="text"
                        id="mobile-number"
                        onChange={(event) => {
                          handleRequestCallbackDetailsChange(
                            "name",
                            event?.target.value
                          );
                        }}
                      />
                    </div>
                    <div id="number-section" className="mt-2">
                      <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                        <div>Mobile Number</div>
                      </div>
                      <input
                        className="border-0 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Enter your Mobile Number"
                        type="number"
                        required
                        id="mobile-number"
                        onChange={(event) => {
                          handleRequestCallbackDetailsChange(
                            "number",
                            event?.target.value
                          );
                        }}
                      />
                    </div>
                    <div id="otp-section" className="mt-2">
                      <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                        <div>Email</div>
                      </div>
                      <input
                        className="border-0 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Enter your email"
                        type="email"
                        required
                        onChange={(event) => {
                          handleRequestCallbackDetailsChange(
                            "email",
                            event?.target.value
                          );
                        }}
                      />
                    </div>
                    <div id="otp-section" className="mt-2">
                      <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                        <div>Category</div>
                      </div>
                      <Multiselect
                        className="mt-2"
                        options={[
                          { name: "Income Tax", id: 1 },
                          { name: "Buisness Registrations", id: 2 },
                          { name: "Investements", id: 3 },
                          { name: "Insurance", id: 4 },
                          { name: "Legals", id: 4 },
                          { name: "NRI Corner", id: 4 }
                        ]} // Options to display in the dropdown
                        caseSensitiveSearch={true}
                        avoidHighlightFirstOption={true}
                        onSelect={(selectedList: any, changedItem: any) => {
                          handleRequestCallbackDetailsChange(
                            "category",
                            selectedList
                          );
                        }} // Function will trigger on select event
                        onRemove={(selectedList: any, changedItem: any) => {
                          handleRequestCallbackDetailsChange(
                            "category",
                            selectedList
                          );
                        }} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Select your Category"
                        selectionLimit={3}
                      />
                    </div>
                    <div id="otp-section" className="mt-2">
                      <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                        <div>Comments</div>
                      </div>
                      <input
                        className="border-0 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Add you Comments"
                        type="text"
                        onChange={(event) => {
                          handleRequestCallbackDetailsChange(
                            "comments",
                            event?.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="mt-2">
                      <Button
                        className="text-xs w-full bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                        size="xs"
                        type="submit"
                      >
                        Request Callback
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        {loading ? <SpinnerComponent /> : null}
      </Modal>
      <Modal show={showLogin} className="relative">
        <div className="static">
          <div className="flex static">
            <div className="hidden lg:block lg:w-1/2 static">
              <img src={TaxviewLogo} className="absolute top-4 left-4 h-6" />
              <img
                src={PopupBottom}
                className="absolute bottom-0 left-0 rounded-bl-md h-40 z-0"
              />
              <img
                src={LoginPopup}
                className="absolute w-4/6 bottom-0 h-60 -left-20 z-0"
              />
            </div>
            <div className="static w-full lg:w-1/2 h-96">
              <img
                src={PopupTop}
                className="absolute top-0 right-0 h-10 rounded-tr-md"
              />
              <img
                src={PopupBottomMobile}
                className="block absolute bottom-0 left-0 lg:hidden rounded-bl-md"
              />
              <div
                className="cursor-pointer absolute top-1 right-4 text-white"
                onClick={() => {
                  setShowLogin(false);
                }}
              >
                X
              </div>
              <div className="mt-6 p-4 z-10 relative">
                <div className="text-xl font-bold">
                  Let's get you started
                </div>
                <div className="text-xs font-light">
                  Login as
                </div>
                <div className="m-4 border-2 border-dashed rounded-xl cursor-pointer" onClick={()=>{
                  setConsultant(false)
                  setMobileNumber("")
                  setOTP("")
                  setShowLogin(false)
                  setShowLoginModal(true)
                }}>
                  <div className="flex gap-2 p-2 items-center justify-center">
                    <img src={userLogin} className="h-8"/>
                    <div className="text-xl font-semibold">User</div>
                  </div>
                </div>
                <div className="m-4 border-2 border-dashed rounded-xl cursor-pointer"  onClick={()=>{
                  setConsultant(true)
                  setMobileNumber("")
                  setOTP("")
                  setShowLogin(false)
                  setShowLoginModal(true)
                }}>
                  <div className="flex gap-2 p-2 items-center justify-center">
                    <img src={consultantLogin} className="h-8"/>
                    <div className="text-xl font-semibold">Expert</div>
                  </div>
                </div>
                </div>
                </div>
            </div>
            <div>
          </div>
        </div>
        {loading ? <SpinnerComponent /> : null}
      </Modal>
    </div>
  );
}

export default NewHomePage;
