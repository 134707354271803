import { useEffect, useState } from "react";
import ConsultantSideBar from "./ConsultantSideBar";
import StepperComponent from "../../../Components/StepperComponent";
import { Avatar, Button, Label, Select, TextInput } from "flowbite-react";
import AxiosInstance from "../../../Utils/AxiosInstance";
import { toast } from "react-toastify";

function ConsultantProfileInfo(props: any) {
  const [personalDeatails, setPersonalDetails]: any = useState({
    prefix: props.userInfo.prefix ? props.userInfo.prefix:"CA",
    username: props.userInfo.username,
    email: props.userInfo.email,
    state: props.userInfo.state ? props.userInfo.state : "Andhra Pradesh",
    occupation: props.userInfo.occupation
      ? props.userInfo.occupation
      : "Salaried",
    file:  props.userInfo.profilePic?props.userInfo.profilePic:null,
  });

  const [showFile,setShowFile] = useState(false)
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  const handleValueChange = (field: any, value: any) => {
    
    let tempData = personalDeatails;
    tempData[field] = value;
    if(tempData.file){
      setShowFile(true)
    }
    else{
      setShowFile(false)
    }

    setPersonalDetails({...tempData});
  };

  useEffect(()=>{
    if(personalDeatails.file){
      setShowFile(true)
    }
  },[])

  

  const submitPersonalDeatils = (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    if (personalDeatails.file) {
      formData.append("upload", personalDeatails.file);
    }

    formData.append("username", personalDeatails.username);
    formData.append("email", personalDeatails.email);
    formData.append("state", personalDeatails.state);
    formData.append("occupation", personalDeatails.occupation);
    formData.append("prefix", personalDeatails.prefix);

    AxiosInstance()
      .post("/consultant/updateConsultantPersonalDetails", formData)
      .then(({ data }) => {
        props.onSuccess();
      })
      .catch((err) => {
        toast.error("Failed to update personal details, please try again");
      });
  };
  return (
    <ConsultantSideBar>
      <div className="m-4">
        <div className="text-3xl font-bold text-[#547AF7]">
          Let's get your Personal Details
        </div>
        <div className="mt-1 font-light text-lg">
          Adding information about your profile helps us driving credibility -
          None of this information will be shared without your consent
        </div>
        {props.onBoarding && (
          <>
            <div className="m-4 pl-8 pr-8 hidden lg:flex">
              {" "}
              <StepperComponent
                steps={[
                  "Personal Details",
                  "KYC Details",
                  "Professional Details",
                  "Summary",
                ]}
                activeStep={0}
              />
            </div>
            <div className="mt-4 mb-4 text-2xl font-extrabold lg:hidden">
              {" "}
              STEP: 1/4
            </div>
          </>
        )}
        {showFile && (
          <div className="p-8 m-2 flex justify-center rounded-xl border-dashed border-2">
            <div>
              <Avatar img={typeof(personalDeatails.file) === "string" ? personalDeatails.file:URL.createObjectURL(personalDeatails.file)} size="md"/>
              <div className="mt-2 text-sm font-bold">Profile Picture</div>
            </div>
          </div>
        )}
        <div className="mt-4">
          <form
            className="grid grid-cols-1 gap-4 lg:grid-cols-3"
            id="PersonalDetailsForm"
            onSubmit={submitPersonalDeatils}
          >
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Profile Picture" />
              </div>
              <TextInput
                id="name"
                type="file"
                placeholder="Select Profile Pic"
                accept="image/png, image/jpg, image/jpeg, image/svg+xml, image/webp"
                onChange={(e: any) => {
                  handleValueChange("file", e.target.files[0]);
                }}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Full Name" />
              </div>
              <div className="flex gap-1 items-center">
                <Select
                  id="countries"
                  defaultValue={props.userInfo.prefix}
                  onChange={(e) => {
                    handleValueChange("prefix", e.target.value);
                  }}
                >
                    <option value="CA">CA</option>
                    <option value="CA">CS</option>
                    <option value="CFA">CFA</option>
                    <option value="Adv">Adv</option>
                    <option value="CWA">CWA</option>
                    <option value="">Others</option>
                </Select>
              <TextInput
                id="name"
                type="text"
                placeholder="Enter Name"
                defaultValue={personalDeatails.username}
                onChange={(e) => {
                  handleValueChange("username", e.target.value);
                }}
                required
              />
              </div>
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Email Id" />
              </div>
              <TextInput
                id="email"
                type="email"
                placeholder="Enter Email"
                defaultValue={personalDeatails.email}
                onChange={(e) => {
                  handleValueChange("email", e.target.value);
                }}
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Occupation" />
              </div>
              <Select
                id="occuption"
                onChange={(e) => {
                  handleValueChange("occupation", e.target.value);
                }}
                defaultValue={personalDeatails.occupation}
                required
              >
                <option>Salaried</option>
                <option>Self Employed</option>
              </Select>
            </div>
            <div className="max-w-md">
              <div className="mb-2 block">
                <Label htmlFor="countries" value="State" />
              </div>
              <Select
                id="countries"
                defaultValue={personalDeatails.state}
                onChange={(e) => {
                  handleValueChange("state", e.target.value);
                }}
                required
              >
                {states.map((state) => (
                  <option>{state}</option>
                ))}
              </Select>
            </div>
          </form>
        </div>
        <div className="mt-4 flex justify-center gap-4 lg:justify-end">
          <Button
            className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
            size="xs"
            form="PersonalDetailsForm"
            type="submit"
          >
            {props.onBoarding ? "Next" : "Update"}
          </Button>
        </div>
      </div>
    </ConsultantSideBar>
  );
}

export default ConsultantProfileInfo;
