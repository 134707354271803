import { useState } from "react";
import TaxviewLogo from "../../Images/TaxviewLogo.svg"
import { Button } from "flowbite-react";
import { toast } from "react-toastify";
import AxiosInstance from "../../Utils/AxiosInstance";
import { useNavigate } from "react-router-dom";

function AdminLogin(){

    const [mobileNumber,setMobileNumber] = useState("")
    const [pageState,setPageState] = useState("number")
    const [otp,setOTP] = useState("")
    const [loading,setLoading] = useState(true)

    const navigate = useNavigate()

    const handleMobileNumberChange = (event: any) => {
        if (event.target.value.length <= 10) {
          setMobileNumber(event.target.value);
        }
      };
    
      const handleOTPChange = (event: any) => {
        if (event.target.value.length <= 6) {
          setOTP(event.target.value);
        }
      };

      const sendOTP = () => {
        setLoading(true);
          AxiosInstance()
            .post("/admin/sendOTP", { number: `+91${mobileNumber}` })
            .then(({ data }) => {
              toast.success("OTP sent successfully");
              setPageState("otp");
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              toast.error(`Failed to send OTP, please try again`);
            });
        
      };
    
      const verifyOTP = () => {
        setLoading(true);
          AxiosInstance()
            .post("/admin/verifyOTP", {
              number: `+91${mobileNumber}`,
              otp: otp,
            })
            .then(({ data }) => {
              localStorage.setItem("token", data.token);
             
                navigate("/admin/dashboard");
              
            })
            .catch((err) => {
              toast.error(err?.message);
              setLoading(false);
            });
        
      };

    return(<div className="h-dvh w-full flex justify-center items-center gap-4">
        <div>
            <img src={TaxviewLogo}/>
        </div>
        <div>
            <div className="texl-xl font-bold">Taxview Admin Portal</div>
            <div>
            <div id="login-section">
                    <div id="number-section" className="mt-6">
                      <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                        <div>Mobile Number</div>
                        {mobileNumber.length === 10 &&
                          pageState === "number" && (
                            <div
                              className="cursor-pointer text-[#547AF7] font-bold"
                              onClick={sendOTP}
                            >
                              Get OTP
                            </div>
                          )}
                      </div>
                      <input
                        className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Enter your Mobile Number"
                        disabled={pageState !== "number"}
                        type="number"
                        id="mobile-number"
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                      />
                    </div>
                    {pageState === "otp" && (
                      <div
                        className="cursor-pointer text-[#547AF7] font-bold mt-1 text-xs"
                        onClick={() => {
                          sendOTP();
                        }}
                      >
                        Resend OTP
                      </div>
                    )}
                    <div id="otp-section" className="mt-6">
                      <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                        <div>OTP</div>
                        {pageState === "otp" && (
                          <div
                            className="cursor-pointer text-[#547AF7] font-bold"
                            onClick={() => {
                              setMobileNumber("");
                              setOTP("");
                              setPageState("number");
                              document.getElementById("mobile-number")?.focus();
                            }}
                          >
                            Change Number
                          </div>
                        )}
                      </div>

                      <input
                        className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-2"
                        placeholder="Enter OTP"
                        disabled={pageState !== "otp"}
                        type="number"
                        value={otp}
                        onChange={handleOTPChange}
                      />
                    </div>

                    <div className="mt-4">
                      <Button
                        className="text-xs w-full bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                        size="xs"
                        disabled={pageState !== "otp" || otp.length != 6}
                        onClick={verifyOTP}
                      >
                        Log In
                      </Button>
                    </div>
                  </div>
            </div>
        </div>
    </div>)
}

export default AdminLogin;