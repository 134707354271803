import { useNavigate } from "react-router-dom";
import "../CSS/MobileHomePage.css";
import { useState } from "react";

import caimage1 from "../Images/caimage1.jpg"
import caimage2 from "../Images/caimage2.jpg"
import caimage3 from "../Images/caimage3.jpg"

import Suraj from "../Images/Suraj.jpg"
import Shravan from "../Images/shravan.jpg"
import Sheetal from "../Images/sheetal.jpg"
import Aditya from "../Images/aditya.jpg"
import Unnati from "../Images/unnati.jpg"
import Atharv from "../Images/Atharv.jpg"

import Ajit from "../Images/Ajit.jpg"
import Heena from "../Images/Heena.jpg"
import Jay from  "../Images/Jay.jpg"
import Smitha from "../Images/Smitha.jpg"
import TaxviewLogo from "../Images/TaxviewLogo.svg";

import Img1 from "../Images/Img1.svg"
import Img2 from "../Images/Img2.svg"
import Img3 from "../Images/Img3.svg"
import Img4 from "../Images/Img4.svg"
import Img5 from "../Images/Img5.svg"
import Img6 from "../Images/Img6.svg"
import Img7 from "../Images/Img7.svg"

import Farid from "../Images/Farid.jpg"
import Vineet from "../Images/Vineet.jpg"
import Vikas from "../Images/Vikas.jpg"
import Kritika from "../Images/Kritika.jpg"

import exp from "../Images/exp.svg"
import spec from "../Images/spec.svg"
import client from "../Images/client.svg"
import expwhite from "../Images/expwhite.svg"
import specwhite from "../Images/specwhite.svg"
import clientwhite from "../Images/clientwhite.svg"

import gst from "../Images/gst.png"
import filling from "../Images/Filling.png"
import compnayinc from "../Images/companyinc.png"
import financialPlaning from "../Images/finacialplanning.png"
import investadv from "../Images/investmentadv.png"
import lifeinc from "../Images/lifeinc.png"
import medicaInc from "../Images/medicalinc.png"
import generalInc from "../Images/generalinc.png"
import claim from "../Images/claim.png"
import agreement from "../Images/agreement.png"
import trade from "../Images/Trade.png"
import property from "../Images/property.png"
import register from "../Images/register.png"
import planing from "../Images/planning.png"
import notice from "../Images/notice.png"
import tax from "../Images/tax.png"
import taxes from "../Images/taxes.png"
import compliance from "../Images/compliance.png"
import stamp from "../Images/stamp.png"

function MobileHomePage(props:any) {

  const [selectedService,setSelectedService] = useState("Income Tax");

  const navigate = useNavigate()
  const services:any = {
    "Income Tax":[
      {
        name:"ITR Filing",
        desc:"Get expert help for accurate and Stress- free tax filing",
        icon:filling
      },
      {
        name:"Tax Planning",
        desc:"Maximize your savings with expert tax planning assistance",
        icon:planing
      },
      {
        name:"Notices & Scrutiny",
        desc:"Resolve notices of all types and scrutiny efficiently",
        icon:notice
      },
      {
        name:"IT Appeals",
        desc:"Navigate IT appeals confidently",
        icon:register
      }
    ],
    "Business Registrations":[
      {
        name:"GST Registration",
        desc:"GST Registration Online by Certified GST Practitioner",
        icon: gst
      },
      {
        name:"GST Filing",
        desc:"Stay compliant with seamless GST return filing service",
        icon:filling
      },
      {
        name:"Company Incorporation",
        desc:"Achieve hassle-free company incorporation with specialized guidance",
        icon:compnayinc
      },
      {
        name:"License Registrations",
        desc:"Get Expert help for smooth Business license registration",
        icon:register
      }
    ],
    "Investments":[
      {
        name:"Financial Planning",
        desc:"Get a personalized financial plan, start-to-finish, for all your financial goals",
        icon:financialPlaning
      },
      {
        name:"Investment Advisory",
        desc:"Gain strategic investment advice customized to your financial objectives",
        icon:investadv
      },
      {
        name:"Research Services",
        desc:"Unlock Valuable insights with specialized research services",
        icon:register
      },
      {
        name:"Portfolio Management",
        desc:"Manage you portfolio to get the best returns, with the help of our experts",
        icon:planing
      }
    ],
    "Insurance":[
      {
        name:"Life Insurance",
        desc:"Get assistance in selecting the best policy",
        icon:lifeinc
      },
      {
        name:"Medical Insurance",
        desc:"Get assistance in selecting the best policy",
        icon:medicaInc
      },
      {
        name:"General Insurance",
        desc:"Get assistance in selecting the best policy",
        icon: generalInc
      },
      {
        name:"Claim Assistance",
        desc:"Get assistance in filling out claim forms accurately, reducing the chances of errors that could lead to claim rejection",
        icon:claim
      }
    ],
    "Legal":[
      {
        name:" Agreement Consulting",
        desc:"Ensure Legally sound agreements with our consulting and drafting service",
        icon:agreement
      },
      {
        name:"Trademark & Patent",
        desc:"Protect your innovations with expert trademark and patent registration consultancy",
        icon:trade
      },
      {
        name:"Property Disputes",
        desc:"Get Expert view on Property Disputes matters.",
        icon:property
      },
      {
        name:"Consumer Forum Complaints",
        desc:"Experts assistance in Filing Consumer Forum complaints",
        icon:register
      }
    ],
    "NRI Corner":[
      {
        name:"NRI ITR Filing",
        desc:"NRI tax preparation and filling by experts",
        icon:tax
      },
      {
        name:"FEMA Compliances",
        desc:"Ensure seamless FEMA Compliances with expert guidance",
        icon:stamp
      },
      {
        name:"Lower TDS Deduction",
        desc:"Minimize Tax Deductions with form 13",
        icon:compliance
      },
      {
        name:"Tax Residency Certificate",
        desc:"Obtain your tax residency certificate smoothly",
        icon:taxes
      }
    ]
  }
  
  return (<div className="parent-div ">
    <div className="pos-abs mobile-136957">
      {/* Background4 */}
      <section className="pos-abs background-136980"></section>
      <div className="pos-abs ellipse-1-136959"></div>
      {/* Frame 961 */}
      <section className="pos-abs frame-96-136960">
        <div className="pos-abs frame-6-136967">
          <div className="pos-abs book-a-session-136968 cursor-pointer" onClick={props.openLogin}>
            <span className="book-a-session-136968-0 ">{`Login`}</span>
          </div>
        </div>
        <div className="pos-abs frame-95-136961">
          <div className="pos-abs taxviewsitelogo-136966">
            <img
              src={TaxviewLogo}
              className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-136966 object-fit "
              alt="taxview-sitelogo-1.png1 Image"
            />{" "}
          </div>
        </div>
      </section>
      {/* Background1 */}
      <section className="pos-abs background-136969"></section>
      <div className="pos-abs connect-with-to-136971">
        <span className="connect-with-to-136971-0 ">{`Connect with `}</span>
        <span className="connect-with-to-136971-1 ">{`Top Experts`}</span>
        <span className="connect-with-to-136971-2 ">{` for Tax, Legal, Wealth  and more
`}</span>
        <span className="connect-with-to-136971-3 ">{`in seconds`}</span>
      </div>
      {/* Background2 */}
      <section className="pos-abs background-136970"></section>
      {/* Background3 */}
      <section className="pos-abs background-136979"></section>
      {/* Frame 1201 */}
      <section className="pos-abs frame-120-136973">
        <div className="pos-abs frame-2-136974">
          <div className="pos-abs background-136976">
            <div className="pos-abs bpysgyl5w3nhfbn-136977">
              <div className="pos-abs vector-136978">
                <div className="nodeBg-136978 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
          </div>
          <div className="pos-abs book-a-session-136975 cursor-pointer" onClick={props.openClientLogin}>
            <span className="book-a-session-136975-0 ">{`Book a Free Session`}</span>
          </div>
        </div>
      </section>
      {/* Background5 */}
      <section className="pos-abs background-136981"></section>
      {/* Frame 1011 */}
      <section className="pos-abs frame-101-136982">
        <div className="pos-abs frame-99-136983">
          <div className="pos-abs omshfdvcmytznsc-136984">
            <img
              src={Vineet}
              className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-136984 object-fit "
              alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
            />
            <div className="pos-abs frame-97-136985">
              <div className="pos-abs jessica-merchan-136986">
                <span className="jessica-merchan-136986-0 ">{`CA Vineet Gurbani`}</span>
              </div>
              <div className="pos-abs frame-68-136987">
                <div className="pos-abs gst-specialist-136992">
                  <span className="gst-specialist-136992-0 ">{`Financial Planning`}</span>
                </div>
                <div className="pos-abs briefcase02-136988">
                  {/* <div className="pos-abs vector-136991">
                    <div className="nodeBg-136991 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-136989">
                    <div className="nodeBg-136989 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-136990">
                    <div className="nodeBg-136990 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={specwhite}/>
                </div>
              </div>
              <div className="pos-abs frame-67-136993">
                <div className="pos-abs c-5-year-of-exper-136997">
                  <span className="c-5-year-of-exper-136997-0 ">{`5 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-136994">
                  {/* <div className="pos-abs vector-136995">
                    <div className="nodeBg-136995 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-136996">
                    <div className="nodeBg-136996 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={expwhite}/>
                </div>
              </div>
              <div className="pos-abs frame-69-136998">
                <div className="pos-abs c-100-happy-clien-137004">
                  <span className="c-100-happy-clien-137004-0 ">{`50+ Happy Clients `}</span>
                </div>
                <div className="pos-abs usergroup-136999">
                  {/* <div className="pos-abs vector-137003">
                    <div className="nodeBg-137003 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137001">
                    <div className="nodeBg-137001 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137000">
                    <div className="nodeBg-137000 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137002">
                    <div className="nodeBg-137002 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={clientwhite}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-100-137005">
          <div className="pos-abs omshfdvcmytznsc-137006">
            <img
              src={Farid}
              className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-137006 object-fit "
              alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
            />
            <div className="pos-abs frame-98-137007">
              <div className="pos-abs jessica-merchan-137008">
                <span className="jessica-merchan-137008-0 ">{`CA Farid Panjwani`}</span>
              </div>
              <div className="pos-abs frame-68-137009">
                <div className="pos-abs gst-specialist-137014">
                  <span className="gst-specialist-137014-0 ">{`ITR Filling,TDS`}</span>
                </div>
                <div className="pos-abs briefcase02-137010">
                  {/* <div className="pos-abs vector-137013">
                    <div className="nodeBg-137013 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137011">
                    <div className="nodeBg-137011 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137012">
                    <div className="nodeBg-137012 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={specwhite}/>
                </div>
              </div>
              <div className="pos-abs frame-67-137015">
                <div className="pos-abs c-5-year-of-exper-137019">
                  <span className="c-5-year-of-exper-137019-0 ">{`5 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-137016">
                  {/* <div className="pos-abs vector-137017">
                    <div className="nodeBg-137017 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137018">
                    <div className="nodeBg-137018 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={expwhite}/>
                </div>
              </div>
              <div className="pos-abs frame-69-137020">
                <div className="pos-abs c-100-happy-clien-137026">
                  <span className="c-100-happy-clien-137026-0 ">{`150+ Happy Clients `}</span>
                </div>
                <div className="pos-abs usergroup-137021">
                  {/* <div className="pos-abs vector-137025">
                    <div className="nodeBg-137025 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137023">
                    <div className="nodeBg-137023 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137022">
                    <div className="nodeBg-137022 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137024">
                    <div className="nodeBg-137024 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={clientwhite}/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-101-137027">
          <div className="pos-abs omshfdvcmytznsc-137028">
            <img
              src={caimage1}
              className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-137028 object-fit "
              alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
            />
            <div className="pos-abs frame-98-137029">
              <div className="pos-abs jessica-merchan-137030">
                <span className="jessica-merchan-137030-0 ">{`CA Muskan Rathi`}</span>
              </div>
              <div className="pos-abs frame-68-137031">
                <div className="pos-abs gst-specialist-137036">
                  <span className="gst-specialist-137036-0 ">{`ITR & Tax Panning`}</span>
                </div>
                <div className="pos-abs briefcase02-137032">
                  {/* <div className="pos-abs vector-137035">
                    <div className="nodeBg-137035 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137033">
                    <div className="nodeBg-137033 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137034">
                    <div className="nodeBg-137034 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={specwhite}/>
                </div>
              </div>
              <div className="pos-abs frame-67-137037">
                <div className="pos-abs c-5-year-of-exper-137041">
                  <span className="c-5-year-of-exper-137041-0 ">{`2 Year of Experience`}</span>
                </div>
                <div className="pos-abs clock01-137038">
                  {/* <div className="pos-abs vector-137039">
                    <div className="nodeBg-137039 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137040">
                    <div className="nodeBg-137040 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={expwhite}/>
                </div>
              </div>
              <div className="pos-abs frame-69-137042">
                <div className="pos-abs c-100-happy-clien-137048">
                  <span className="c-100-happy-clien-137048-0 ">{`50+ Happy Clients `}</span>
                </div>
                <div className="pos-abs usergroup-137043">
                  {/* <div className="pos-abs vector-137047">
                    <div className="nodeBg-137047 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137045">
                    <div className="nodeBg-137045 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137044">
                    <div className="nodeBg-137044 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137046">
                    <div className="nodeBg-137046 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={expwhite}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Background6 */}
      <section className="pos-abs background-137049">
        <div className="pos-abs paragraph-137050">
          <div className="pos-abs heading-4--resu-137051">
            <span className="heading-4--resu-137051-0 ">{`Why Taxview?`}</span>
          </div>
          <div className="pos-abs c-80-of-our-learn-137052">
            <span className="c-80-of-our-learn-137052-0 ">{`One stop solution for all your Tax / Business / Legal queries from the best experts`}</span>
          </div>
        </div>
      </section>
      {/* Frame 171 */}
      <section className="pos-abs frame-17-137053">
        <div className="pos-abs frame-14-137054">
          <div className="pos-abs vector-3-137062">
            <div className="nodeBg-137062 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-10-137055">
            <div className="pos-abs c-10lac-users-all-137061">
              <span className="c-10lac-users-all-137061-0 ">{`Verified experts with 5+ years of experience`}</span>
            </div>
            <div className="pos-abs group-5-137056">
              {/* <div className="pos-abs ellipse-13-137057"></div>
              <div className="pos-abs c-1605873928indiv-137058">
                <div className="pos-abs vector-137060">
                  <div className="nodeBg-137060 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137059">
                  <div className="nodeBg-137059 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div> */}
              <img src={Img1}/>
            </div>
          </div>
          <div className="pos-abs vector-1-137074">
            <div className="nodeBg-137074 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-11-137063">
            <div className="pos-abs ondemand-tax-fi-137073">
              <span className="ondemand-tax-fi-137073-0 ">{`Personalized solutions from experts`}</span>
            </div>
            <div className="pos-abs group-4-137064">
              {/* <div className="pos-abs ellipse-13-137065"></div>
              <div className="pos-abs c-1605873617taxsa-137066">
                <div className="pos-abs group-6-137067">
                  <div className="pos-abs vector-137068">
                    <div className="nodeBg-137068 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137072">
                    <div className="nodeBg-137072 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137069">
                    <div className="nodeBg-137069 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137070">
                    <div className="nodeBg-137070 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137071">
                    <div className="nodeBg-137071 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div> */}
              <img src={Img2}/>
            </div>
          </div>
          <div className="pos-abs vector-2-137084">
            <div className="nodeBg-137084 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-12-137075">
            <div className="pos-abs c-94-of-users-hav-137083">
              <span className="c-94-of-users-hav-137083-0 ">{`Get support for all your queries`}</span>
            </div>
            <div className="pos-abs group-4-137076">
              {/* <div className="pos-abs ellipse-13-137077"></div>
              <div className="pos-abs c-1605873617taxsa-137078">
                <div className="pos-abs group-5-137079">
                  <div className="pos-abs vector-137081">
                    <div className="nodeBg-137081 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137082">
                    <div className="nodeBg-137082 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137080">
                    <div className="nodeBg-137080 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div> */}
              <img src={Img3}/>
            </div>
          </div>
          <div className="pos-abs frame-13-137085">
            <div className="pos-abs c-974-taxbuddy-us-137094">
              <span className="c-974-taxbuddy-us-137094-0 ">{`Solve your problems in quick and efficient manner`}</span>
            </div>
            <div className="pos-abs group-4-137086">
              <div className="pos-abs ellipse-13-137087"></div>
              <div className="pos-abs c-1605873617taxsa-137088">
                {/* <div className="pos-abs group-7-137089">
                  <div className="pos-abs vector-137090">
                    <div className="nodeBg-137090 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137093">
                    <div className="nodeBg-137093 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137092">
                    <div className="nodeBg-137092 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137091">
                    <div className="nodeBg-137091 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div> */}
                <img src={Img4}/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pos-abs ellipse-2-136958"></div>
      <div className="pos-abs heading-2--serv-137147">
        <span className="heading-2--serv-137147-0 ">{`Services For Everyone and Everything`}</span>
      </div>
      <div className="pos-abs built-for-scale-137148">
        <span className="built-for-scale-137148-0 ">{`Built for scale, made by experts and secure by design. Bringing you maximum tax savings, unmatched speed and complete peace of mind.`}</span>
      </div>
      {/* Frame 1461 */}
      <section className="pos-abs frame-146-1817">
        <div className="pos-abs frame-145-1818">
          <div className={`pos-abs background-1819 ${selectedService === "Income Tax" ? "selected-background":""}`} onClick={()=>{setSelectedService("Income Tax")}}>
            <div className="pos-abs income-tax-1820">
              <span className="income-tax-1820-0 ">{`Income Tax`}</span>
            </div>
          </div>
          <div className={`pos-abs background-1821 ${selectedService === "Business Registrations" ? "selected-background":""}`} onClick={()=>{setSelectedService("Business Registrations")}}>
            <div className={`pos-abs business-regist-1822 `}>
              <span className="business-regist-1822-0 ">{`Business Registrations`}</span>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-146-1823">
          <div className={`pos-abs background-1824 ${selectedService === "Investments" ? "selected-background":""}`} onClick={()=>{setSelectedService("Investments")}}>
            <div className="pos-abs investments-1825">
              <span className="investments-1825-0 ">{`Investments`}</span>
            </div>
          </div>
          <div className={`pos-abs background-1826 ${selectedService === "Insurance" ? "selected-background":""}`} onClick={()=>{setSelectedService("Insurance")}}>
            <div className={`pos-abs insurance-1827`}>
              <span className="insurance-1827-0 ">{`Insurance`}</span>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-147-1828">
          <div className={`pos-abs background-1829 ${selectedService === "Legal" ? "selected-background":""}`} onClick={()=>{setSelectedService("Legal")}}>
            <div className="pos-abs legal-1830">
              <span className="legal-1830-0 ">{`Legal`}</span>
            </div>
          </div>
          <div className={`pos-abs background-1831 ${selectedService === "NRI Corner" ? "selected-background":""}`} onClick={()=>{setSelectedService("NRI Corner")}}>
            <div className={`pos-abs nri-corner-1832 `}>
              <span className="nri-corner-1832-0 ">{`NRI Corner`}</span>
            </div>
          </div>
        </div>
      </section>
      {/* Group 131 */}
      <section className="pos-abs group-13-1947">
        <div className="pos-abs frame-172-1942">
          <div className="pos-abs document-1-1943">
            <img
              src={services[selectedService][0].icon}
              className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-1943 object-fit "
              alt="document 11 Image"
            />{" "}
          </div>
          <div className="pos-abs frame-171-1944">
            <div className="pos-abs heading-6--clea-1945">
              <span className="heading-6--clea-1945-0 ">{services[selectedService][0].name}</span>
            </div>
            <div className="pos-abs get-expert-help-1946">
              <span className="get-expert-help-1946-0 ">{services[selectedService][0].desc}</span>
            </div>
          </div>
        </div>
      </section>
      {/* Frame 1731 */}
      <section className="pos-abs frame-173-1948">
        <div className="pos-abs personal2243183-1949">
          <img
            src={services[selectedService][1].icon}
            className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-1949 object-fit "
            alt="personal_2243183 21 Image"
          />{" "}
        </div>
        <div className="pos-abs frame-171-1950">
          <div className="pos-abs heading-6--clea-1951">
            <span className="heading-6--clea-1951-0 ">{services[selectedService][1].name}</span>
          </div>
          <div className="pos-abs maximize-your-s-1952">
            <span className="maximize-your-s-1952-0 ">{services[selectedService][1].desc}</span>
          </div>
        </div>
      </section>
      {/* Frame 1741 */}
      <section className="pos-abs frame-174-1958">
        <div className="pos-abs lawbook-1-1968">
          <img
            src={services[selectedService][2].icon}
            className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-1968 object-fit "
            alt="law-book 11 Image"
          />{" "}
        </div>
        <div className="pos-abs frame-171-1960">
          <div className="pos-abs heading-6--clea-1961">
            <span className="heading-6--clea-1961-0 ">{services[selectedService][2].name}</span>
          </div>
          <div className="pos-abs maximize-your-s-1962">
            <span className="maximize-your-s-1962-0 ">{services[selectedService][2].desc}</span>
          </div>
        </div>
      </section>
      {/* Frame 1751 */}
      <section className="pos-abs frame-175-1981">
        <div className="pos-abs notice-1-1986">
          <img
            src={services[selectedService][3].icon}
            className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-1986 object-fit "
            alt="notice 11 Image"
          />{" "}
        </div>
        <div className="pos-abs frame-171-1983">
          <div className="pos-abs heading-6--clea-1984">
            <span className="heading-6--clea-1984-0 ">{services[selectedService][3].name}</span>
          </div>
          <div className="pos-abs maximize-your-s-1985">
            <span className="maximize-your-s-1985-0 ">{services[selectedService][3].desc}</span>
          </div>
        </div>
      </section>
      {/* Frame 1091 */}
      <section className="pos-abs frame-109-137095">
        <div className="pos-abs rectangle-42303-137096"></div>
        <div className="pos-abs ellipse-79-137098"></div>
        <div className="pos-abs ellipse-80-137099"></div>
        <div className="pos-abs ellipse-78-137097"></div>
        <div className="pos-abs ellipse-81-137100"></div>
        <div className="pos-abs heading-2--serv-137101">
          <span className="heading-2--serv-137101-0 ">{`How it works`}</span>
        </div>
        <div className="pos-abs link-1833 cursor-pointer" onClick={props.openClientLogin}>
          <div className="pos-abs border-1841"></div>
          <div className="pos-abs background-1834">
            <div className="pos-abs border-1835"></div>
            <div className="pos-abs hctnqompeo4zyf3-1836">
              <div className="pos-abs hctnqompeo4zyf3-1837">
                <div className="pos-abs hctnqompeo4zyf3-1838">
                  <div className="pos-abs vector-1839">
                    <div className="nodeBg-1839 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs see-all-service-1840">
            <span className="see-all-service-1840-0 ">{`See all services`}</span>
          </div>
        </div>
        <div className="pos-abs frame-111-137111">
          <div className="pos-abs background-137112">
            <div className="pos-abs border-137113"></div>
            <div className="pos-abs frame-110-137114">
              <div className="pos-abs heading-4--prov-137115">
                <span className="heading-4--prov-137115-0 ">{`Sign-up`}</span>
              </div>
              <div className="pos-abs the-multiple-ch-137116">
                <span className="the-multiple-ch-137116-0 ">{`Login with your phone number and tell us your name and email id`}</span>
              </div>
            </div>
            <div className="pos-abs backgroundshado-137117">
              <div className="pos-abs border-137119"></div>
              <div className="pos-abs heading-5--1-137118">
                <span className="heading-5--1-137118-0 ">{`1`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs background-137120">
            <div className="pos-abs border-137121"></div>
            <div className="pos-abs frame-110-137122">
              <div className="pos-abs heading-4--prov-137123">
                <span className="heading-4--prov-137123-0 ">{`Choose Service You Want`}</span>
              </div>
              <div className="pos-abs the-multiple-ch-137124">
                <span className="the-multiple-ch-137124-0 ">{`Select the problems you need consultants on We have qualified experts with 5+ years of experience across taxes, GST and legal advisory`}</span>
              </div>
            </div>
            <div className="pos-abs backgroundshado-137125">
              <div className="pos-abs border-137127"></div>
              <div className="pos-abs heading-5--1-137126">
                <span className="heading-5--1-137126-0 ">{`2`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs background-137128">
            <div className="pos-abs border-137129"></div>
            <div className="pos-abs frame-110-137130">
              <div className="pos-abs heading-4--prov-137131">
                <span className="heading-4--prov-137131-0 ">{`Select a Preferred Time Slot  `}</span>
              </div>
              <div className="pos-abs the-multiple-ch-137132">
                <span className="the-multiple-ch-137132-0 ">{`Select a time slot that works best for you. Along with that choose a service you need`}</span>
              </div>
            </div>
            <div className="pos-abs backgroundshado-137133">
              <div className="pos-abs border-137135"></div>
              <div className="pos-abs heading-5--1-137134">
                <span className="heading-5--1-137134-0 ">{`3`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs background-137136">
            <div className="pos-abs border-137137"></div>
            <div className="pos-abs frame-110-137138">
              <div className="pos-abs heading-4--prov-137139">
                <span className="heading-4--prov-137139-0 ">{`Connect With Experts`}</span>
              </div>
              <div className="pos-abs the-multiple-ch-137140">
                <span className="the-multiple-ch-137140-0 ">{`Book a service or schedule a free call to discuss in details about your problem.`}</span>
              </div>
            </div>
            <div className="pos-abs backgroundshado-137141">
              <div className="pos-abs border-137143"></div>
              <div className="pos-abs heading-5--1-137142">
                <span className="heading-5--1-137142-0 ">{`4`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-18-137144">
          <div className="pos-abs heading-2--serv-137145">
            <span className="heading-2--serv-137145-0 ">{`What our clients are saying about us`}</span>
          </div>
          <div className="pos-abs customer-satisf-137146">
            <span className="customer-satisf-137146-0 ">{`Customer satisfaction is a primary goal for our company`}</span>
          </div>
        </div>
      </section>
      <div className="pos-abs built-for-scale-137149">
        <span className="built-for-scale-137149-0 ">{`Get an overview of how TaxView can help you find the best experts in just 5 mins`}</span>
      </div>
      {/* Frame 1121 */}
      <section className="pos-abs frame-112-137150">
        <div className="pos-abs frame-53-137151">
          <div className="pos-abs frame-60-137152">
            <div className="pos-abs frame-54-137153">
              <div className="pos-abs div-137154">
                <span className="div-137154-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-137155">
                <span className="omg-i-cannot-be-137155-0 ">{`“I loved how easy it was to explain my expert about the problems I had with respect to taxes. He understood it well and guided me through quickly. Loved the experience”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-137156">
              <div className="pos-abs ellipse-4-137157">
                <img
                  src={Suraj}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137157 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-137158">
                <div className="pos-abs jessica-merchan-137159">
                  <span className="jessica-merchan-137159-0 ">{`Suraj Sharma`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-137160">
                  <span className="cfo-charity-gal-137160-0 ">{`Product Lead, Growth Velocity`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-137161">
            <div className="pos-abs frame-57-137162">
              <div className="pos-abs income-tax-retu-137163">
                <span className="income-tax-retu-137163-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-137164">
              <div className="pos-abs gst-registratio-137165">
                <span className="gst-registratio-137165-0 ">{`IT Notice`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-110-137166">
          <div className="pos-abs frame-60-137167">
            <div className="pos-abs frame-54-137168">
              <div className="pos-abs div-137169">
                <span className="div-137169-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-137170">
                <span className="omg-i-cannot-be-137170-0 ">{`“I needed help while setting my company. TaxView connected me with the right consultant and she gave me the right advice, the entire process was a breeze. Great work!”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-137171">
              <div className="pos-abs ellipse-4-137172">
                <img
                  src={Shravan}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137172 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-137173">
                <div className="pos-abs jessica-merchan-137174">
                  <span className="jessica-merchan-137174-0 ">{`Shravan`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-137175">
                  <span className="cfo-charity-gal-137175-0 ">{`CEO, Rethink Systems`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-137176">
            <div className="pos-abs frame-57-137177">
              <div className="pos-abs income-tax-retu-137178">
                <span className="income-tax-retu-137178-0 ">{`DSC Services`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-137179">
              <div className="pos-abs gst-registratio-137180">
                <span className="gst-registratio-137180-0 ">{`GST Registration`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-111-137181">
          <div className="pos-abs frame-60-137182">
            <div className="pos-abs frame-54-137183">
              <div className="pos-abs div-137184">
                <span className="div-137184-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-137185">
                <span className="omg-i-cannot-be-137185-0 ">{`“Struggled a lot to file taxes for the first time. TaxView's expert guided me in the right direction”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-137186">
              <div className="pos-abs ellipse-4-137187">
                <img
                  src={Unnati}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137187 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-137188">
                <div className="pos-abs jessica-merchan-137189">
                  <span className="jessica-merchan-137189-0 ">{`Unnati`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-137190">
                  <span className="cfo-charity-gal-137190-0 ">{`Designer & Product Manager`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-137191">
            <div className="pos-abs frame-57-137192">
              <div className="pos-abs income-tax-retu-137193">
                <span className="income-tax-retu-137193-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-137194">
              <div className="pos-abs gst-registratio-137195">
                <span className="gst-registratio-137195-0 ">{`Legal Advisory`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-112-137196">
          <div className="pos-abs frame-60-137197">
            <div className="pos-abs frame-54-137198">
              <div className="pos-abs div-137199">
                <span className="div-137199-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-137200">
                <span className="omg-i-cannot-be-137200-0 ">{`“Loved the experience of finding the right CA for my tax queries, booked a free call, got some clarity and filed taxes the right way :)”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-137201">
              <div className="pos-abs ellipse-4-137202">
                <img
                  src={Sheetal}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137202 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-137203">
                <div className="pos-abs jessica-merchan-137204">
                  <span className="jessica-merchan-137204-0 ">{`Sheetal`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-137205">
                  <span className="cfo-charity-gal-137205-0 ">{`CTO, AfterLife`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-137206">
            <div className="pos-abs frame-57-137207">
              <div className="pos-abs income-tax-retu-137208">
                <span className="income-tax-retu-137208-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-137209">
              <div className="pos-abs gst-registratio-137210">
                <span className="gst-registratio-137210-0 ">{`GST Registration`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-113-137211">
          <div className="pos-abs frame-60-137212">
            <div className="pos-abs frame-54-137213">
              <div className="pos-abs div-137214">
                <span className="div-137214-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-137215">
                <span className="omg-i-cannot-be-137215-0 ">{`“Before TaxView, I had no way to measure and see if the CA's advice was right - With TaxView, I could consult multiple CAs and find the right solution”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-137216">
              <div className="pos-abs ellipse-4-137217">
                <img
                  src={Aditya}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137217 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-137218">
                <div className="pos-abs jessica-merchan-137219">
                  <span className="jessica-merchan-137219-0 ">{`Aditya`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-137220">
                  <span className="cfo-charity-gal-137220-0 ">{`CEO, WhatNext`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-137221">
            <div className="pos-abs frame-57-137222">
              <div className="pos-abs income-tax-retu-137223">
                <span className="income-tax-retu-137223-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-137224">
              <div className="pos-abs gst-registratio-137225">
                <span className="gst-registratio-137225-0 ">{`Wealth Management`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-114-137226">
          <div className="pos-abs frame-60-137227">
            <div className="pos-abs frame-54-137228">
              <div className="pos-abs div-137229">
                <span className="div-137229-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-137230">
                <span className="omg-i-cannot-be-137230-0 ">{`“TaxView made finding experts for my legal needs so easy! Perfect place if you need the right advice”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-137231">
              <div className="pos-abs ellipse-4-137232">
                <img
                  src={Atharv}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137232 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-137233">
                <div className="pos-abs jessica-merchan-137234">
                  <span className="jessica-merchan-137234-0 ">{`Atharv`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-137235">
                  <span className="cfo-charity-gal-137235-0 ">{`Product Lead`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-59-137236">
            <div className="pos-abs frame-57-137237">
              <div className="pos-abs income-tax-retu-137238">
                <span className="income-tax-retu-137238-0 ">{`Legal Advisory and DSC`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-137239">
              <div className="pos-abs gst-registratio-137240">
                <span className="gst-registratio-137240-0 ">{`IT Notices`}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Frame 1122 */}
      <section className="pos-abs frame-112-137498">
        <div className="pos-abs heading-2--serv-137499">
          <span className="heading-2--serv-137499-0 ">{`Why Taxview?`}</span>
        </div>
        {/* <div className="pos-abs customer-satisf-137500">
          <span className="customer-satisf-137500-0 ">{`Customer satisfaction is a primary goal for our company`}</span>
        </div> */}
      </section>
      {/* Frame 1691 */}
      <section className="pos-abs frame-169-137501">
        <div className="pos-abs frame-158-137502">
          <div className="pos-abs frame-157-137503">
            <div className="pos-abs c-1605873617taxsa-137504">
              {/* <div className="pos-abs c-1605873617taxsa-137505">
                <div className="pos-abs c-1605873617taxsa-137506">
                  <div className="pos-abs vector-137508">
                    <div className="nodeBg-137508 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137509">
                    <div className="nodeBg-137509 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137507">
                    <div className="nodeBg-137507 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div> */}
              <img src={Img5}/>
            </div>
            <div className="pos-abs maximum-tax-sav-137510">
              <span className="maximum-tax-sav-137510-0 ">{`Personalized solutions lead to better savings`}</span>
            </div>
          </div>
          <div className="pos-abs busineses-save--137511">
            <span className="busineses-save--137511-0 ">{`Individual or business owner, most solutions avaliable in the market are just one size fits all - We at TaxView are aiming to change that. Find a solution that works best for you`}</span>
          </div>
        </div>
        <div className="pos-abs frame-159-137512">
          <div className="pos-abs frame-157-137513">
            <div className="pos-abs c-1605873731unpar-137514">
              {/* <div className="pos-abs c-1605873731unpar-137515">
                <div className="pos-abs c-1605873731unpar-137516">
                  <div className="pos-abs vector-137517">
                    <div className="nodeBg-137517 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137518">
                    <div className="nodeBg-137518 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div> */}
              <img src={Img6} className="h-inherit"/>
            </div>
            <div className="pos-abs unparalleled-sp-137519">
              <span className="unparalleled-sp-137519-0 ">{`Get help fast and on-demand`}</span>
            </div>
          </div>
          <div className="pos-abs busineses-save--137520">
            <span className="busineses-save--137520-0 ">{`Our experts work with you and provide you with the right advice, whenever you need it. With multiple time slots and ratings, you can find the right expert whenever you need it.`}</span>
          </div>
        </div>
        <div className="pos-abs frame-160-137521">
          <div className="pos-abs frame-157-137522">
            <div className="pos-abs c-1605873842fullc-137523">
              {/* <div className="pos-abs c-1605873842fullc-137524">
                <div className="pos-abs vector-137525">
                  <div className="nodeBg-137525 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137526">
                  <div className="nodeBg-137526 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137527">
                  <div className="nodeBg-137527 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div> */}
              <img src={Img7}/>
            </div>
            <div className="pos-abs accurate-compli-137528">
              <span className="accurate-compli-137528-0 ">{`Your data is always secured`}</span>
            </div>
          </div>
          <div className="pos-abs busineses-save--137529">
            <span className="busineses-save--137529-0 ">{`We believe in ensuring that your data is always secure. Our experts will never use any of your data without your consent. Trust and security is something we always believe `}</span>
          </div>
        </div>
      </section>
      <div className="pos-abs bg-137241"></div>
      {/* Frame 1131 */}
      <section className="pos-abs frame-113-137242">
        <div className="pos-abs heading-2--serv-137243">
          <span className="heading-2--serv-137243-0 ">{`Meet with our Experts`}</span>
        </div>
        <div className="pos-abs customer-satisf-137244">
          <span className="customer-satisf-137244-0 ">{`Customer satisfaction is a primary goal for our company`}</span>
        </div>
      </section>
      {/* Frame 1191 */}
      <section className="pos-abs frame-119-137248">
        <div className="pos-abs frame-117-137249">
          <div className="pos-abs rectangle-42298-137250">
            <img
              src={Vikas}
              className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137250 object-fit "
              alt="Rectangle 422981 Image"
            />{" "}
          </div>
          <div className="pos-abs frame-116-137251">
            <div className="pos-abs jessica-merchan-137252">
              <span className="jessica-merchan-137252-0 ">{`Vikas Gupta`}</span>
            </div>
            <div className="pos-abs frame-115-137253">
              <div className="pos-abs clock01-137254">
                {/* <div className="pos-abs vector-137255">
                  <div className="nodeBg-137255 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137256">
                  <div className="nodeBg-137256 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={exp}/>
              </div>
              <div className="pos-abs c-5-year-of-exper-137257">
                <span className="c-5-year-of-exper-137257-0 ">{`27 Year of Experience`}</span>
              </div>
            </div>
            <div className="pos-abs frame-116-137258">
              <div className="pos-abs briefcase02-137259">
                {/* <div className="pos-abs vector-137262">
                  <div className="nodeBg-137262 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137260">
                  <div className="nodeBg-137260 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137261">
                  <div className="nodeBg-137261 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={spec}/>
              </div>
              <div className="pos-abs gst-specialist--137263">
                <span className="gst-specialist--137263-0 ">{`SEBI Consultancy, Trademark and 
Patent Registration`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs vector-4-137264">
            <div className="nodeBg-137264 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-115-137265">
            <div className="pos-abs usergroup-137266">
              {/* <div className="pos-abs vector-137270">
                <div className="nodeBg-137270 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137268">
                <div className="nodeBg-137268 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137267">
                <div className="nodeBg-137267 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137269">
                <div className="nodeBg-137269 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div> */}
              <img src={client}/>
            </div>
            <div className="pos-abs c-100-happy-clien-137271">
              <span className="c-100-happy-clien-137271-0 ">{`1000+ Happy Clients `}</span>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-118-137272">
          <div className="pos-abs rectangle-42298-137273">
            <img
              src={caimage3}
              className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137273 object-fit "
              alt="Rectangle 422981 Image"
            />{" "}
          </div>
          <div className="pos-abs frame-116-137274">
            <div className="pos-abs jessica-merchan-137275">
              <span className="jessica-merchan-137275-0 ">{`CA Shubham Gedam`}</span>
            </div>
            <div className="pos-abs frame-115-137276">
              <div className="pos-abs clock01-137277">
                {/* <div className="pos-abs vector-137278">
                  <div className="nodeBg-137278 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137279">
                  <div className="nodeBg-137279 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={exp}/>
              </div>
              <div className="pos-abs c-5-year-of-exper-137280">
                <span className="c-5-year-of-exper-137280-0 ">{`5 Year of Experience`}</span>
              </div>
            </div>
            <div className="pos-abs frame-116-137281">
              <div className="pos-abs briefcase02-137282">
                {/* <div className="pos-abs vector-137285">
                  <div className="nodeBg-137285 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137283">
                  <div className="nodeBg-137283 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137284">
                  <div className="nodeBg-137284 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={spec}/>
              </div>
              <div className="pos-abs gst-specialist--137286">
                <span className="gst-specialist--137286-0 ">{`Financial Planning, Cibil Consultancy, Research Services`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs vector-4-137287">
            <div className="nodeBg-137287 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-115-137288">
            <div className="pos-abs usergroup-137289">
              {/* <div className="pos-abs vector-137293">
                <div className="nodeBg-137293 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137291">
                <div className="nodeBg-137291 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137290">
                <div className="nodeBg-137290 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137292">
                <div className="nodeBg-137292 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div> */}
              <img src={client}/>
            </div>
            <div className="pos-abs c-100-happy-clien-137294">
              <span className="c-100-happy-clien-137294-0 ">{`20+ Happy Clients `}</span>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-119-137295">
          <div className="pos-abs rectangle-42298-137296">
            <img
              src={Heena}
              className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137296 object-fit "
              alt="Rectangle 422981 Image"
            />{" "}
          </div>
          <div className="pos-abs frame-116-137297">
            <div className="pos-abs jessica-merchan-137298">
              <span className="jessica-merchan-137298-0 ">{`CA Heena Katiyare`}</span>
            </div>
            <div className="pos-abs frame-115-137299">
              <div className="pos-abs clock01-137300">
                {/* <div className="pos-abs vector-137301">
                  <div className="nodeBg-137301 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137302">
                  <div className="nodeBg-137302 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={exp}/>
              </div>
              <div className="pos-abs c-5-year-of-exper-137303">
                <span className="c-5-year-of-exper-137303-0 ">{`8 Year of Experience`}</span>
              </div>
            </div>
            <div className="pos-abs frame-116-137304">
              <div className="pos-abs briefcase02-137305">
                {/* <div className="pos-abs vector-137308">
                  <div className="nodeBg-137308 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137306">
                  <div className="nodeBg-137306 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137307">
                  <div className="nodeBg-137307 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={spec}/>
              </div>
              <div className="pos-abs gst-specialist--137309">
                <span className="gst-specialist--137309-0 ">{`ITR Filling, Notices and Scrutiny,
IT Appeals`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs vector-4-137310">
            <div className="nodeBg-137310 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-115-137311">
            <div className="pos-abs usergroup-137312">
              {/* <div className="pos-abs vector-137316">
                <div className="nodeBg-137316 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137314">
                <div className="nodeBg-137314 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137313">
                <div className="nodeBg-137313 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137315">
                <div className="nodeBg-137315 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div> */}
              <img src={client}/>
            </div>
            <div className="pos-abs c-100-happy-clien-137317">
              <span className="c-100-happy-clien-137317-0 ">{`150+ Happy Clients `}</span>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-120-137318">
          <div className="pos-abs rectangle-42298-137319">
            <img
              src={Kritika}
              className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-137319 object-fit "
              alt="Rectangle 422981 Image"
            />{" "}
          </div>
          <div className="pos-abs frame-116-137320">
            <div className="pos-abs jessica-merchan-137321">
              <span className="jessica-merchan-137321-0 ">{`CA Kritika Bharwani`}</span>
            </div>
            <div className="pos-abs frame-115-137322">
              <div className="pos-abs clock01-137323">
                {/* <div className="pos-abs vector-137324">
                  <div className="nodeBg-137324 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137325">
                  <div className="nodeBg-137325 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div> */}
                <img src={exp}/>
              </div>
              <div className="pos-abs c-5-year-of-exper-137326">
                <span className="c-5-year-of-exper-137326-0 ">{`8 Year of Experience`}</span>
              </div>
            </div>
            <div className="pos-abs frame-116-137327">
              <div className="pos-abs briefcase02-137328">
                  {/* <div className="pos-abs vector-137331">
                    <div className="nodeBg-137331 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137329">
                    <div className="nodeBg-137329 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-137330">
                    <div className="nodeBg-137330 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div> */}
                  <img src={spec}/>
              </div>
              <div className="pos-abs gst-specialist--137332">
                <span className="gst-specialist--137332-0 ">{`Company Incorporation, Financial Planning, NRI ITR Filling`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs vector-4-137333">
            <div className="nodeBg-137333 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
          </div>
          <div className="pos-abs frame-115-137334">
            <div className="pos-abs usergroup-137335">
              {/* <div className="pos-abs vector-137339">
                <div className="nodeBg-137339 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137337">
                <div className="nodeBg-137337 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137336">
                <div className="nodeBg-137336 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-137338">
                <div className="nodeBg-137338 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div> */}
              <img src={client}/>
            </div>
            <div className="pos-abs c-100-happy-clien-137340">
              <span className="c-100-happy-clien-137340-0 ">{`15+ Happy Clients `}</span>
            </div>
          </div>
        </div>
      </section>
      {/* Frame 61 */}
      <section className="pos-abs frame-6-137341 currsor-pointer" onClick={props.openClientLogin}>
        <div className="pos-abs frame-2-137342">
          <div className="pos-abs background-137344">
            <div className="pos-abs bpysgyl5w3nhfbn-137345">
              <div className="pos-abs vector-137346">
                <div className="nodeBg-137346 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
          </div>
          <div className="pos-abs book-a-session-137343">
            <span className="book-a-session-137343-0 ">{`Book A Free Session`}</span>
          </div>
        </div>
      </section>
      {/* Frame 1351 */}
      <section className="pos-abs frame-135-137372">
        <div className="pos-abs bg-137373"></div>
        <div className="pos-abs ellipse-3-137374"></div>
        <div className="pos-abs ellipse-4-137375"></div>
      </section>
      <div className="pos-abs heading-2--serv-137377">
        <span className="heading-2--serv-137377-0 ">{`Frequently Asked Questions`}</span>
      </div>
      {/* Frame 1301 */}
      <section className="pos-abs frame-130-137378">
        <div className="pos-abs frame-128-137379">
          <div className="pos-abs heading-5--when-137380">
            <span className="heading-5--when-137380-0 ">{`What is TaxView?`}</span>
          </div>
          <div className="pos-abs heading-5--when-137381">
            <span className="heading-5--when-137381-0 ">{`TaxView is your go to platform for connecting with the best consultants for all your Tax / GST / Legal needs. We onboard the best experts and help you connect, engage and solve problems with the best consultants`}</span>
          </div>
        </div>
        <div className="pos-abs frame-132-137382">
          <div className="pos-abs heading-5--when-137383">
            <span className="heading-5--when-137383-0 ">{`Can I book a free session?`}</span>
          </div>
          <div className="pos-abs heading-5--when-137384">
            <span className="heading-5--when-137384-0 ">{`Yes you can, sign in, select the domain you need help with, select the consultant based on years of experience, work profile and either book a service with them or book a free call`}</span>
          </div>
        </div>
        <div className="pos-abs frame-133-137385">
          <div className="pos-abs heading-5--when-137386">
            <span className="heading-5--when-137386-0 ">{`What are the services TaxView offers?`}</span>
          </div>
          <div className="pos-abs heading-5--when-137387">
            <span className="heading-5--when-137387-0 ">{`TaxView offers consultants across Tax / Legal and Insurance needs. You can explore the diverse range of domains from the dashboard. Login with your phone number and select the domain you need help on`}</span>
          </div>
        </div>
        <div className="pos-abs frame-134-137388">
          <div className="pos-abs heading-5--when-137389">
            <span className="heading-5--when-137389-0 ">{`How does TaxView get the best experts?`}</span>
          </div>
          <div className="pos-abs heading-5--when-137390">
            <span className="heading-5--when-137390-0 ">{`TaxView onboards only SEBI certified / CA Qualified or Legal practicing Consultants. They have the best acumen and worked with atleast 10+ clients to solve their needs. Be assured you're in the best hands!`}</span>
          </div>
        </div>
      </section>
      <div className="pos-abs rectangle-42302-137391"></div>
      {/* Frame 1361 */}
      <section className="pos-abs frame-136-137392">
        <div className="pos-abs heading-2--serv-137393">
          <span className="heading-2--serv-137393-0 ">{`Join as Expert`}</span>
        </div>
        <div className="pos-abs be-inspired-by--137394">
          <span className="be-inspired-by--137394-0 ">{`Join our community of Tax / Legal / Business experts.`}</span>
        </div>
      </section>
      {/* Frame 62 */}
      <section className="pos-abs frame-6-137395 cursor-pointer" onClick={props.openConsultantLogin}>
        <div className="pos-abs frame-2-137396">
          <div className="pos-abs background-137398">
            <div className="pos-abs bpysgyl5w3nhfbn-137399">
              <div className="pos-abs vector-137400">
                <div className="nodeBg-137400 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
          </div>
          <div className="pos-abs join-now-137397">
            <span className="join-now-137397-0 ">{`Join Now`}</span>
          </div>
        </div>
      </section>
      {/* Background7 */}
      <section className="pos-abs background-137401 z-30">
        <div className="pos-abs frame-138-137402">
          <div className="pos-abs support-137403 z-40" onClick={()=>{navigate("/tnc")}}>
            <span className="support-137403-0">{`Support`}</span>
          </div>
          <div className="pos-abs paragraph-137404">
            <div className="pos-abs link--agenda-137406 z-40" onClick={()=>{navigate("/tnc")}}>
              <span className="link--agenda-137406-0">{`Terms & Privacy`}</span>
            </div>
            <div className="pos-abs link--agenda-137405">
              <span className="link--agenda-137405-0 ">{`FAQs`}</span>
            </div>
            {/* <div className="pos-abs link--speakers-137407">
              <span className="link--speakers-137407-0 ">{`Become Sponsor`}</span>
            </div>
            <div className="pos-abs link--tickets-137408">
              <span className="link--tickets-137408-0 ">{`Get Tickets`}</span>
            </div> */}
          </div>
        </div>
        <div className="pos-abs contact-137409">
          <span className="contact-137409-0 ">{`Contact`}</span>
        </div>
        <div className="pos-abs frame-140-137410">
          <div className="pos-abs h9jxuen4q7yyuxh-137411">
            <div className="pos-abs h9jxuen4q7yyuxh-137412">
              <div className="pos-abs h9jxuen4q7yyuxh-137413">
                <div className="pos-abs vector-137415">
                  <div className="nodeBg-137415 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137414">
                  <div className="nodeBg-137414 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137416">
                  <div className="nodeBg-137416 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-139-137417">
            <div className="pos-abs c-4296-coplin-ave-137418">
              <span className="c-4296-coplin-ave-137418-0 ">{`Nagpur`}</span>
            </div>
            <div className="pos-abs arizona-59412-137419">
              <span className="arizona-59412-137419-0 ">{`India`}</span>
            </div>
          </div>
        </div>
        <div className="pos-abs frame-142-137420">
          <div className="pos-abs ygonarmqkxum8wv-137421">
            <div className="pos-abs ygonarmqkxum8wv-137422">
              <div className="pos-abs ygonarmqkxum8wv-137423">
                <div className="pos-abs vector-137425">
                  <div className="nodeBg-137425 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-137424">
                  <div className="nodeBg-137424 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-141-137426">
            <div className="pos-abs link-137427">
              <div className="pos-abs hellowildframer-137428">
                <span className="hellowildframer-137428-0 cursor-pinter">{`support@taxview.co.in`}</span>
              </div>
            </div>
            <div className="pos-abs link-137429">
              <div className="pos-abs c-1-4953953940-137430">
                <span className="c-1-4953953940-137430-0 ">{`+91 9021227059`}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Frame 1271 */}
      <section className="pos-abs frame-127-137376"></section>
    </div>
  </div>
);
}

export default MobileHomePage;
