import { Button, Navbar } from "flowbite-react"
import { useNavigate } from "react-router-dom"
import TaxviewLogo from "../../Images/TaxviewLogo.svg"

function AdminNavbar(){

    const navigate = useNavigate()

    return (<Navbar fluid rounded>
        <Navbar.Brand>
          <img src={TaxviewLogo} className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" /></Navbar.Brand>
        <div className="flex md:order-2">
          <Button onClick={()=>{
            localStorage.clear()
            navigate("/admin")
          }}>Logout</Button>
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          <Navbar.Link href="/admin/dashboard">
            CA Request
          </Navbar.Link>
          <Navbar.Link href="/admin/leadlist">Lead List</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>)
}

export default AdminNavbar