import { useEffect, useState } from "react";
import AxiosInstance from "../../Utils/AxiosInstance";
import AdminNavbar from "./AdminNavbar";
import { toast } from "react-toastify";
import { Table, TableBody, TableHead } from "flowbite-react";
import Category from "../../Components/Category";

function AdminLeadList() {
  const [leads, setLeads] = useState([]);

  const getLeads = () => {
    AxiosInstance()
      .get("/admin/getLeadList")
      .then(({ data }) => {
        setLeads(data);
      })
      .catch((err) => {
        toast.error("Failed to get the lead list, please try again");
      });
  };

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <div>
      <AdminNavbar />
      <div className="p-4">
        <Table>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Number</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Category</Table.HeadCell>
            <Table.HeadCell>Comments</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {leads.map((lead:any)=>(
                <Table.Row>
                    <Table.Cell>{lead.name}</Table.Cell>
                    <Table.Cell>{lead.number}</Table.Cell>
                    <Table.Cell>{lead.email}</Table.Cell>
                    <Table.Cell>
                        {
                            lead.category && lead.category.map((category:any)=>(
                                <>{category.name},</>
                            ))
                        }
                    </Table.Cell>
                    <Table.Cell>{lead.comments}</Table.Cell>
                </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
}

export default AdminLeadList;
