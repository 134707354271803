import { Spinner } from "flowbite-react";

function SpinnerComponent() {
  return (
    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40">
      <div className="flex flex-col items-center bg-gray-300 rounded-md p-4 shadow-lg">
        <Spinner size="xl" />
        <p className="text-sm font-bold">Please wait</p>
      </div>
    </div>
  );
}

export default SpinnerComponent;
