import React from "react";
import "../CSS/HomePage.css";
import { Button, Checkbox, Modal } from "flowbite-react";
import { useState } from "react";

import LoginPopup from "../Images/LoginPopup.svg";
import PopupBottom from "../Images/PopupBottom.svg";
import TaxviewLogo from "../Images/TaxviewLogo.svg";
import PopupTop from "../Images/PopupTop.svg";
import PopupBottomMobile from "../Images/PopupBottomMobile.svg";

import { IoLogoWhatsapp } from "react-icons/io";
import AxiosInstance from "../Utils/AxiosInstance";
import { toast } from "react-toastify";
import SpinnerComponent from "../Components/SpinnerComponent";
import { useNavigate } from "react-router-dom";

import caimage1 from "../Images/caimage1.jpg"
import caimage2 from "../Images/caimage2.jpg"
import caimage3 from "../Images/caimage3.jpg"

import Suraj from "../Images/Suraj.jpg"
import Shravan from "../Images/shravan.jpg"
import Sheetal from "../Images/sheetal.jpg"
import Aditya from "../Images/aditya.jpg"
import Unnati from "../Images/unnati.jpg"
import Atharv from "../Images/Atharv.jpg"

import Ajit from "../Images/Ajit.jpg"
import Heena from "../Images/Heena.jpg"
import Jay from  "../Images/Jay.jpg"
import Smitha from "../Images/Smitha.jpg"

export const HomePage = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [consultant,setConsultant] = useState(false)
  const [pageState, setPageState] = useState("number");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [sendWhatsAppNotifications, setSendWhatsappNotifications] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleMobileNumberChange = (event: any) => {
    if (event.target.value.length <= 10) {
      setMobileNumber(event.target.value);
    }
  };

  const handleOTPChange = (event: any) => {
    if (event.target.value.length <= 6) {
      setOTP(event.target.value);
    }
  };

  const bookaSession = () => {
    if (localStorage.token) {
      setLoading(true)
      AxiosInstance()
        .get("/users/verify")
        .then(({ data }) => {
          setLoading(false)
          if (data.username) {
            localStorage.setItem("username", data.username);
          }
          if (data.email) {
            localStorage.setItem("email", data.email);
          }

          localStorage.setItem("firstSlot", data.firstSlot ? "True" : "False");

          if (data.status === "NEW_ACCOUNT") {
            setPageState("newAccount");
            setName("");
            setEmail("");
          } else {
            navigate("/dashboard");
          }
        });
    } else {
      setConsultant(false)
      setShowLoginModal(true);
    }
  };

  const joinNow = () =>{
    if (localStorage.token) {
      setLoading(true)
      AxiosInstance()
        .get("/consultant/verify")
        .then(({ data }) => {
          setLoading(false)
          if (data.username) {
            localStorage.setItem("username", data.username);
          }
          if (data.email) {
            localStorage.setItem("email", data.email);
          }


          if (data.status == "ACCOUNT_VERIFIED") {
            navigate("/consultant/dashboard")
          } else {
            navigate("/consultant/onboarding");
          }

        });
    } else {
      setConsultant(true)
      setShowLoginModal(true);
    }
  }

  const sendOTP = () => {
    setLoading(true);
    if(consultant){
      AxiosInstance()
      .post("/consultant/sendOTP", { number: `+91${mobileNumber}` })
      .then(({ data }) => {
        toast.success("OTP sent successfully");
        setPageState("otp");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Failed to send OTP, please try again`);
      });
    }else{
      AxiosInstance()
      .post("/users/sendOTP", { number: `+91${mobileNumber}` })
      .then(({ data }) => {
        toast.success("OTP sent successfully");
        setPageState("otp");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Failed to send OTP, please try again`);
      });
    }
    
  };

  const verifyOTP = () => {
    setLoading(true);
    if(consultant){
      AxiosInstance()
      .post("/consultant/verifyOTP", { number: `+91${mobileNumber}`, otp: otp })
      .then(({ data }) => {
        localStorage.setItem("token", data.token);
        if (data.username) {
          localStorage.setItem("username", data.username);
        }
        if (data.email) {
          localStorage.setItem("email", data.email);
        }
        localStorage.setItem("consultant","true")

        if (data.status == "ACCOUNT_VERIFIED") {
          navigate("/consultant/dashboard")
        } else {
          navigate("/consultant/onboarding");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
    }
    else{
      AxiosInstance()
      .post("/users/verifyOTP", { number: `+91${mobileNumber}`, otp: otp })
      .then(({ data }) => {
        localStorage.setItem("token", data.token);
        if (data.username) {
          localStorage.setItem("username", data.username);
        }
        if (data.email) {
          localStorage.setItem("email", data.email);
        }

        localStorage.setItem("firstSlot", data.firstSlot ? "True" : "False");

        if (data.status === "NEW_ACCOUNT") {
          setPageState("newAccount");
          setName("");
          setEmail("");
        } else {
          navigate("/dashboard");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.message);
        setLoading(false);
      });
    }
    
  };

  const handlePersonalDetailsUpdate = (event: any) => {
    event.preventDefault();
    setLoading(true);
    AxiosInstance()
      .post("/users/updateUserDetails", {
        username: name,
        email: email,
      })
      .then(({ data }) => {
        localStorage.setItem("username", name);
        localStorage.setItem("email", email);
        navigate("/dashboard");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to update personal details ${err?.message}`);
        setLoading(false);
      });
  };

  return (
    <div className="parent-div ">
      <div className="pos-abs innovatexpofram-172">
        {/* taxview-sitelogo-1.png1 */}
        <section className="pos-abs taxviewsitelogo-782">
          <img
            src="https://s3-alpha-sig.figma.com/img/df02/dccd/d77ce90b7694ecdbb8b576ed2e916414?Expires=1719792000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O~R62mTcaWWpJZsIsN1LnLFFGYCFDjOfEp4DLJ6pET2~4XB6nIUN~V05VrGWGtftYbHI6Ub4Fuhws4saKXuWEp2wTrYF8KsASJXx-wcxOOIvc0f6ABgI6S5qEP6uG2ofwup14hf5b~QOS440DK6LIIp3bvMiUzWttYgZsoE7gKrCx28z9MqLEUJG~SRDC5zJEpqxA9VHi~NtMyeF8zJFaHCbxPiIJbmzpAdB2IH-DzTKgRVJDEJo-NcZiTS7NfyzggMyLxSvDSq4xlThYIWo0V4lMPh33eLl~FCe7FSCUWoRDGsP8rvgsyzdgnrOOueGQqRtiIp9muGEcbQOI9lOrw__"
            className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-782 object-fit "
            alt="taxview-sitelogo-1.png1 Image"
          />{" "}
        </section>
        {/* Frame 61 */}
        <section
          className="pos-abs frame-6-4718 cursor-pointer"
          onClick={bookaSession}
        >
          <div className="pos-abs background-4720">
            <div className="pos-abs bpysgyl5w3nhfbn-4721">
              <div className="pos-abs bpysgyl5w3nhfbn-4722">
                <div className="pos-abs bpysgyl5w3nhfbn-4723">
                  <div className="pos-abs vector-4724">
                    <div className="nodeBg-4724 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs book-a-session-4719">
            <span className="book-a-session-4719-0 ">{`Book A Session`}</span>
          </div>
        </section>
        {/* Frame 11 */}
        {/* <section className="pos-abs frame-1-17861">
          <div className="pos-abs link--agenda-17829">
            <span className="link--agenda-17829-0 ">{`Home`}</span>
          </div>
          <div className="pos-abs link--agenda-17829">
            <span className="link--agenda-17829-0 ">{`Services`}</span>
          </div>
          <div className="pos-abs link--speaker-17830">
            <span className="link--speaker-17830-0 ">{`About Us`}</span>
          </div>
          <div className="pos-abs link--tickets-17831">
            <span className="link--tickets-17831-0 ">{`Contact Us`}</span>
          </div>
        </section> */}
        {/* Header1 */}
        <section className="pos-abs header-25975">
          <div className="pos-abs ellipse-1-271033"></div>
          <div className="pos-abs frame-5-25895">
            <div className="pos-abs frame-4-25894">
              <div className="pos-abs clarity-of-your-1718">
                <span className="clarity-of-your-1718-0 ">{`From `}</span>
                <span className="clarity-of-your-1718-1 ">{`Tax Dilemmas, Legal Hurdles and everything in between`}</span>
                <span className="clarity-of-your-1718-2 ">{`, find the best experts`}</span>
              </div>
              {/* <div className="pos-abs find-engage-and-1719">
                <span className="find-engage-and-1719-0 ">{`TaxView is India's first one stop platform for all your tax / financial needs / business needs.`}</span>
              </div> */}
            </div>
            <div className="pos-abs frame-6-25903">
              <div
                className="pos-abs frame-2-25887 cursor-pointer"
                onClick={bookaSession}
              >
                <div className="pos-abs background-25889">
                  <div className="pos-abs bpysgyl5w3nhfbn-25890">
                    <div className="pos-abs bpysgyl5w3nhfbn-25891">
                      <div className="pos-abs bpysgyl5w3nhfbn-25892">
                        <div className="pos-abs vector-25893">
                          <div className="nodeBg-25893 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs book-a-session-25888">
                  <span className="book-a-session-25888-0 ">{`Book A Session`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs group-12-143591">
            <div className="pos-abs group-9-143588">
              <div className="pos-abs omshfdvcmytznsc-1064">
                <img
                  src={caimage1}
                  className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-1064 object-fit "
                  alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
                />
                <div className="pos-abs rectangle-42247-10610"></div>
                <div className="pos-abs frame-64-10620">
                  <div className="pos-abs jessica-merchan-10621">
                    <span className="jessica-merchan-10621-0 ">{`CA Muskan`}</span>
                  </div>
                  <div className="pos-abs frame-61-10622">
                    <div className="pos-abs c-5-year-of-exper-10626">
                      <span className="c-5-year-of-exper-10626-0 ">{`5 Year Experience`}</span>
                    </div>
                    <div className="pos-abs clock01-10623">
                      <div className="pos-abs vector-10624">
                        <div className="nodeBg-10624 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-10625">
                        <div className="nodeBg-10625 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                  <div className="pos-abs frame-62-10627">
                    <div className="pos-abs briefcase02-10628">
                      <div className="pos-abs vector-10631">
                        <div className="nodeBg-10631 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-10629">
                        <div className="nodeBg-10629 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-10630">
                        <div className="nodeBg-10630 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                    <div className="pos-abs gst-specialist-10632">
                      <span className="gst-specialist-10632-0 ">{`50 Clients`}</span>
                    </div>
                  </div>
                </div>
                <div className="pos-abs vector-4-10633">
                  <div className="nodeBg-10633 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs frame-63-10634">
                  <div className="pos-abs usergroup-10635">
                    <div className="pos-abs vector-10639">
                      <div className="nodeBg-10639 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10637">
                      <div className="nodeBg-10637 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10636">
                      <div className="nodeBg-10636 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10638">
                      <div className="nodeBg-10638 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                  <div className="pos-abs c-100-happy-clien-10640">
                    <span className="c-100-happy-clien-10640-0 ">{`100+ Happy Client `}</span>
                  </div>
                </div>
              </div>
              <div className="pos-abs paragraph-106110">
                <div className="pos-abs heading-2--15-106111">
                  <span className="heading-2--15-106111-0 ">{`15+`}</span>
                </div>
                <div className="pos-abs years-of-experi-106112">
                  <span className="years-of-experi-106112-0 ">{`Years of Experience`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs group-10-143589">
              <div className="pos-abs omshfdvcmytznsc-10614">
                <img
                  src={caimage2}
                  className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-10614 object-fit "
                  alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
                />
                <div className="pos-abs rectangle-42247-10615"></div>
                <div className="pos-abs frame-66-10688">
                  <div className="pos-abs jessica-merchan-10689">
                    <span className="jessica-merchan-10689-0 ">{`Jenny`}</span>
                  </div>
                </div>
                <div className="pos-abs frame-67-10690">
                  <div className="pos-abs c-5-year-of-exper-10694">
                    <span className="c-5-year-of-exper-10694-0 ">{`10 Year Experience`}</span>
                  </div>
                  <div className="pos-abs clock01-10691">
                    <div className="pos-abs vector-10692">
                      <div className="nodeBg-10692 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10693">
                      <div className="nodeBg-10693 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs frame-68-10695">
                  <div className="pos-abs gst-specialist-106100">
                    <span className="gst-specialist-106100-0 ">{`100 Clients`}</span>
                  </div>
                  <div className="pos-abs briefcase02-10696">
                    <div className="pos-abs vector-10699">
                      <div className="nodeBg-10699 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10697">
                      <div className="nodeBg-10697 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10698">
                      <div className="nodeBg-10698 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs paragraph-106107">
                <div className="pos-abs heading-2--440-106108">
                  <span className="heading-2--440-106108-0 ">{`100+`}</span>
                </div>
                <div className="pos-abs projects-comple-106109">
                  <span className="projects-comple-106109-0 ">{`Projects Completed`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs group-11-143590">
              <div className="pos-abs omshfdvcmytznsc-10618">
                <img
                  src={caimage3}
                  className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-10618 object-fit "
                  alt="OmshFDVcmyTznsC28EeMXsMIw.png1 Image"
                />
                <div className="pos-abs rectangle-42247-10619"></div>
                <div className="pos-abs frame-65-10641">
                  <div className="pos-abs jessica-merchan-10642">
                    <span className="jessica-merchan-10642-0 ">{`Shubham`}</span>
                  </div>
                </div>
                <div className="pos-abs frame-63-10677">
                  <div className="pos-abs c-5-year-of-exper-10681">
                    <span className="c-5-year-of-exper-10681-0 ">{`5 Year Experience`}</span>
                  </div>
                  <div className="pos-abs clock01-10678">
                    <div className="pos-abs vector-10679">
                      <div className="nodeBg-10679 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10680">
                      <div className="nodeBg-10680 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs frame-61-10643"></div>
                <div className="pos-abs frame-64-10682">
                  <div className="pos-abs gst-specialist-10687">
                    <span className="gst-specialist-10687-0 ">{`20 Clients`}</span>
                  </div>
                  <div className="pos-abs briefcase02-10683">
                    <div className="pos-abs vector-10686">
                      <div className="nodeBg-10686 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10684">
                      <div className="nodeBg-10684 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-10685">
                      <div className="nodeBg-10685 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs paragraph-106113">
                <div className="pos-abs heading-2--99-106114">
                  <span className="heading-2--99-106114-0 ">{`99%`}</span>
                </div>
                <div className="pos-abs client-satisfac-106115">
                  <span className="client-satisfac-106115-0 ">{`Client Satisfaction`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs background-6145"></div>
          <div className="pos-abs ellipse-2-271034"></div>
        </section>
        {/* Background3 */}
        <section className="pos-abs background-4413"></section>
        {/* Background2 */}
        <section className="pos-abs background-4412"></section>
        {/* Background6 */}
        <section className="pos-abs background-4416"></section>
        {/* Background4 */}
        <section className="pos-abs background-4414"></section>
        {/* Background7 */}
        <section className="pos-abs background-4417"></section>
        {/* Background5 */}
        <section className="pos-abs background-4415"></section>
        {/* Frame 181 */}
        <section className="pos-abs frame-18-401581">
          <div className="pos-abs heading-2--serv-381580">
            <span className="heading-2--serv-381580-0 ">{`Services For Everyone`}</span>
          </div>
          {/* <div className="pos-abs built-for-scale-381579">
            <span className="built-for-scale-381579-0 ">{`Built for scale, made by experts and secure by design. Bringing you maximum tax savings, unmatched
speed and complete peace of mind.`}</span>
          </div> */}
        </section>
        {/* Header2 */}
        <section className="pos-abs header-6148">
          <div className="pos-abs container-6149">
            <div className="pos-abs background-6150">
              <div className="pos-abs frame-11-61280">
                <div className="pos-abs frame-24-61281">
                  <div className="pos-abs for-individuals-61282">
                    <span className="for-individuals-61282-0 ">{`For Individuals`}</span>
                  </div>
                </div>
              </div>
              <div className="pos-abs c-1605873415taxre-61107">
                <div className="pos-abs c-1605873415taxre-61108">
                  <div className="pos-abs c-1605873415taxre-61109">
                    <div className="pos-abs vector-61110">
                      <div className="nodeBg-61110 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61114">
                      <div className="nodeBg-61114 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61111">
                      <div className="nodeBg-61111 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61112">
                      <div className="nodeBg-61112 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61113">
                      <div className="nodeBg-61113 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61115">
                      <div className="nodeBg-61115 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs itr-filing-assi-61116">
                <span className="itr-filing-assi-61116-0 ">{`GST Filing Assistance`}</span>
              </div>
              <div className="pos-abs get-expert-assi-61117">
                <span className="get-expert-assi-61117-0 ">{`Get Expert assistance in tax filing for Salaried and self-employed individuals, NRIs, Capital gains, and more.`}</span>
              </div>
              <div className="pos-abs c-1651557178group-61118">
                <div className="pos-abs c-1651557178group-61119">
                  <div className="pos-abs c-1651557178group-61120">
                    <div className="pos-abs vector-61121">
                      <div className="nodeBg-61121 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61125">
                      <div className="nodeBg-61125 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61122">
                      <div className="nodeBg-61122 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61123">
                      <div className="nodeBg-61123 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61124">
                      <div className="nodeBg-61124 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs tds-on-sale-of--61126">
                <span className="tds-on-sale-of--61126-0 ">{`TDS on Sale of Property`}</span>
              </div>
              <div className="pos-abs hasslefree-26qb-61127">
                <span className="hasslefree-26qb-61127-0 ">{`Hassle-free 26QB compliance on sale of property`}</span>
              </div>
              <div className="pos-abs container-6160">
                <div className="pos-abs legal-services-61135">
                  <span className="legal-services-61135-0 ">{`Legal Services`}</span>
                </div>
                <div className="pos-abs c-1651557311group-61128">
                  <div className="pos-abs c-1651557311group-61129">
                    <div className="pos-abs c-1651557311group-61130">
                      <div className="pos-abs vector-61131">
                        <div className="nodeBg-61131 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-61134">
                        <div className="nodeBg-61134 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-61133">
                        <div className="nodeBg-61133 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-61132">
                        <div className="nodeBg-61132 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs legal-services-61224">
                  <span className="legal-services-61224-0 ">{`Legal Services`}</span>
                </div>
                <div className="pos-abs c-1651557311group-61225">
                  <div className="pos-abs c-1651557311group-61226">
                    <div className="pos-abs c-1651557311group-61227">
                      <div className="pos-abs vector-61228">
                        <div className="nodeBg-61228 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-61231">
                        <div className="nodeBg-61231 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-61230">
                        <div className="nodeBg-61230 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-61229">
                        <div className="nodeBg-61229 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs for-drafting-an-61136">
                  <span className="for-drafting-an-61136-0 ">{`For drafting and review of legal documents such as Sale deed, Vendor agreement, Co- founders agreement etc`}</span>
                </div>
                <div className="pos-abs for-drafting-an-61223">
                  <span className="for-drafting-an-61223-0 ">{`For drafting and review of legal documents such as Sale deed, Vendor agreement, Co- founders agreement etc`}</span>
                </div>
                <div className="pos-abs link-6164">
                  <div className="pos-abs border-6172"></div>
                  <div className="pos-abs background-6165">
                    <div className="pos-abs border-6166"></div>
                    <div className="pos-abs hctnqompeo4zyf3-6167">
                      <div className="pos-abs hctnqompeo4zyf3-6168">
                        <div className="pos-abs hctnqompeo4zyf3-6169">
                          <div className="pos-abs vector-6170">
                            <div className="nodeBg-6170 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pos-abs see-all-service-6171">
                    <span className="see-all-service-6171-0 ">{`See all services`}</span>
                  </div>
                </div>
                <div
                  className="pos-abs link-61241 pointer-cursor"
                  onClick={bookaSession}
                >
                  <div className="pos-abs border-61249"></div>
                  <div className="pos-abs background-61242">
                    <div className="pos-abs border-61243"></div>
                    <div className="pos-abs hctnqompeo4zyf3-61244">
                      <div className="pos-abs hctnqompeo4zyf3-61245">
                        <div className="pos-abs hctnqompeo4zyf3-61246">
                          <div className="pos-abs vector-61247">
                            <div className="nodeBg-61247 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pos-abs see-all-service-61248">
                    <span className="see-all-service-61248-0 ">{`See all services`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs background-6173">
              <div className="pos-abs frame-10-6199">
                <div className="pos-abs frame-24-61100">
                  <div className="pos-abs for-your-busine-61102">
                    <span className="for-your-busine-61102-0 ">{`For your Business`}</span>
                  </div>
                </div>
              </div>
              <div className="pos-abs c-1605873415taxre-61214">
                <div className="pos-abs c-1605873415taxre-61215">
                  <div className="pos-abs c-1605873415taxre-61216">
                    <div className="pos-abs vector-61217">
                      <div className="nodeBg-61217 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61221">
                      <div className="nodeBg-61221 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61218">
                      <div className="nodeBg-61218 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61219">
                      <div className="nodeBg-61219 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61220">
                      <div className="nodeBg-61220 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61222">
                      <div className="nodeBg-61222 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs itr-filing-assi-61213">
                <span className="itr-filing-assi-61213-0 ">{`GST Filing Assistance`}</span>
              </div>
              <div className="pos-abs get-expert-assi-61212">
                <span className="get-expert-assi-61212-0 ">{`Find the best experts to file GST for your company. On demand`}</span>
              </div>
              <div className="pos-abs c-1651557178group-61204">
                <div className="pos-abs c-1651557178group-61205">
                  <div className="pos-abs c-1651557178group-61206">
                    <div className="pos-abs vector-61207">
                      <div className="nodeBg-61207 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61211">
                      <div className="nodeBg-61211 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61208">
                      <div className="nodeBg-61208 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61209">
                      <div className="nodeBg-61209 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61210">
                      <div className="nodeBg-61210 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs tds-on-sale-of--61203">
                <span className="tds-on-sale-of--61203-0 ">{`Company Setup & Compliances`}</span>
              </div>
              <div className="pos-abs hasslefree-26qb-61202">
                <span className="hasslefree-26qb-61202-0 ">{` Experts that decide what's best for you and your company.`}</span>
              </div>
              <div className="pos-abs itr-filing-assi-61233">
                <span className="itr-filing-assi-61233-0 ">{`Legal & Regulatory`}</span>
              </div>
              <div className="pos-abs c-1651557311group-61234">
                <div className="pos-abs c-1651557311group-61235">
                  <div className="pos-abs c-1651557311group-61236">
                    <div className="pos-abs vector-61237">
                      <div className="nodeBg-61237 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61240">
                      <div className="nodeBg-61240 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61239">
                      <div className="nodeBg-61239 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-61238">
                      <div className="nodeBg-61238 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs get-expert-assi-61232">
                <span className="get-expert-assi-61232-0 ">{`Helping you tackle the legal hurdles and regulatory puzzles from people who know it the best.`}</span>
              </div>
              <div
                className="pos-abs link-61250 cursor-pointer"
                onClick={bookaSession}
              >
                <div className="pos-abs border-61258"></div>
                <div className="pos-abs background-61251">
                  <div className="pos-abs border-61252"></div>
                  <div className="pos-abs hctnqompeo4zyf3-61253">
                    <div className="pos-abs hctnqompeo4zyf3-61254">
                      <div className="pos-abs hctnqompeo4zyf3-61255">
                        <div className="pos-abs vector-61256">
                          <div className="nodeBg-61256 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs see-all-service-61257">
                  <span className="see-all-service-61257-0 ">{`See all services`}</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Background1 */}
        <section className="pos-abs background-61259">
          <div className="pos-abs frame-17-381536">
            <div className="pos-abs frame-14-381537">
              <div className="pos-abs frame-10-381538">
                <div className="pos-abs group-5-381539">
                  <div className="pos-abs ellipse-13-381540"></div>
                  <div className="pos-abs c-1605873928indiv-381541">
                    <div className="pos-abs vector-381543">
                      <div className="nodeBg-381543 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-381542">
                      <div className="nodeBg-381542 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs c-10lac-users-all-381544">
                  <span className="c-10lac-users-all-381544-0 ">{`Get the right advice, on demand`}</span>
                </div>
              </div>
              <div className="pos-abs frame-11-381546">
                <div className="pos-abs group-4-381547">
                  <div className="pos-abs ellipse-13-381548"></div>
                  <div className="pos-abs c-1605873617taxsa-381549">
                    <div className="pos-abs group-6-381550">
                      <div className="pos-abs vector-381551">
                        <div className="nodeBg-381551 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381555">
                        <div className="nodeBg-381555 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381552">
                        <div className="nodeBg-381552 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381553">
                        <div className="nodeBg-381553 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381554">
                        <div className="nodeBg-381554 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs ondemand-tax-fi-381556">
                  <span className="ondemand-tax-fi-381556-0 ">{`Personalised tax
filing support`}</span>
                </div>
              </div>
              <div className="pos-abs frame-12-381558">
                <div className="pos-abs group-4-381559">
                  <div className="pos-abs ellipse-13-381560"></div>
                  <div className="pos-abs c-1605873617taxsa-381561">
                    <div className="pos-abs group-5-381562">
                      <div className="pos-abs vector-381564">
                        <div className="nodeBg-381564 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381565">
                        <div className="nodeBg-381565 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381563">
                        <div className="nodeBg-381563 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs c-94-of-users-hav-381566">
                  <span className="c-94-of-users-hav-381566-0 ">{`95% User Satisfaction`}</span>
                </div>
              </div>
              <div className="pos-abs frame-13-381568">
                <div className="pos-abs group-4-381569">
                  <div className="pos-abs ellipse-13-381570"></div>
                  <div className="pos-abs c-1605873617taxsa-381571">
                    <div className="pos-abs group-7-381572">
                      <div className="pos-abs vector-381573">
                        <div className="nodeBg-381573 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381576">
                        <div className="nodeBg-381576 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381575">
                        <div className="nodeBg-381575 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                      <div className="pos-abs vector-381574">
                        <div className="nodeBg-381574 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pos-abs c-974-taxbuddy-us-381577">
                  <span className="c-974-taxbuddy-us-381577-0 ">{`Trusted by 10K Users`}</span>
                </div>
              </div>
              <div className="pos-abs vector-3-381545">
                <div className="nodeBg-381545 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1-381557">
                <div className="nodeBg-381557 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-2-381567">
                <div className="nodeBg-381567 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
          </div>
          <div className="pos-abs paragraph-61260">
            <div className="pos-abs heading-4--resu-61261">
              <span className="heading-4--resu-61261-0 ">{`Results you can count on.`}</span>
            </div>
            <div className="pos-abs c-80-of-our-learn-61262">
              <span className="c-80-of-our-learn-61262-0 ">{`80%+ of our learners don't have an MBA, or past coding
experience`}</span>
            </div>
          </div>
        </section>
        {/* Frame 481 */}
        <section className="pos-abs frame-48-421943">
          <div className="pos-abs ellipse-74-8215"></div>
          <div className="pos-abs ellipse-77-8214"></div>
          <div className="pos-abs ellipse-76-8216"></div>
          <div className="pos-abs ellipse-75-8217"></div>
          <div className="pos-abs frame-49-8256">
            <div className="pos-abs heading-2--serv-8218">
              <span className="heading-2--serv-8218-0 ">{`How it works`}</span>
            </div>
            <div className="pos-abs built-for-scale-8219">
              <span className="built-for-scale-8219-0 ">{`Get an overview of how TaxView can help you find the best experts`}</span>
            </div>
          </div>
          <div className="pos-abs container-8227">
            <div className="pos-abs background-8228">
              <div className="pos-abs border-8230"></div>
              <div className="pos-abs heading-4--prov-8229">
                <span className="heading-4--prov-8229-0 ">{`Sign Up`}</span>
              </div>
              <div className="pos-abs backgroundshado-8232">
                <div className="pos-abs border-8234"></div>
                <div className="pos-abs heading-5--1-8233">
                  <span className="heading-5--1-8233-0 ">{`1`}</span>
                </div>
              </div>
              <div className="pos-abs the-multiple-ch-8231">
                <span className="the-multiple-ch-8231-0 ">{`Login with your phone number and tell us your name and email id`}</span>
              </div>
            </div>
            <div className="pos-abs background-8235">
              <div className="pos-abs border-8237"></div>
              <div className="pos-abs heading-4--tail-8236">
                <span className="heading-4--tail-8236-0 ">{`Choose a problem you need advice on`}</span>
              </div>
              <div className="pos-abs backgroundshado-8239">
                <div className="pos-abs border-8241"></div>
                <div className="pos-abs heading-5--2-8240">
                  <span className="heading-5--2-8240-0 ">{`2`}</span>
                </div>
              </div>
              <div className="pos-abs whether-youre-a-8238">
                <span className="whether-youre-a-8238-0 ">{`Select the problems you need consultants on We have qualified experts with 5+ years of experience across taxes, GST and legal advisory`}</span>
              </div>
            </div>
            <div className="pos-abs background-8242">
              <div className="pos-abs border-8244"></div>
              <div className="pos-abs heading-4--resu-8243">
                <span className="heading-4--resu-8243-0 ">{`Select a Preferred Time Slot `}</span>
              </div>
              <div className="pos-abs backgroundshado-8246">
                <div className="pos-abs border-8248"></div>
                <div className="pos-abs heading-5--3-8247">
                  <span className="heading-5--3-8247-0 ">{`3`}</span>
                </div>
              </div>
              <div className="pos-abs whether-your-go-8245">
                <span className="whether-your-go-8245-0 ">{`Select a time slot that works best for you. Along with that choose a service you need`}</span>
              </div>
            </div>
            <div className="pos-abs background-8257">
              <div className="pos-abs border-8259"></div>
              <div className="pos-abs heading-4--resu-8258">
                <span className="heading-4--resu-8258-0 ">{`Connect With Expert's`}</span>
              </div>
              <div className="pos-abs backgroundshado-8261">
                <div className="pos-abs border-8263"></div>
                <div className="pos-abs heading-5--3-8262">
                  <span className="heading-5--3-8262-0 ">{`4`}</span>
                </div>
              </div>
              <div className="pos-abs whether-your-go-8260">
                <span className="whether-your-go-8260-0 ">{`Book a service or schedule a free call to discuss in details about your problem.`}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs group-8-8273">
            <div
              className="pos-abs link-8264 cursor-pointer"
              onClick={bookaSession}
            >
              <div className="pos-abs border-8272"></div>
              <div className="pos-abs background-8265">
                <div className="pos-abs border-8266"></div>
                <div className="pos-abs hctnqompeo4zyf3-8267">
                  <div className="pos-abs hctnqompeo4zyf3-8268">
                    <div className="pos-abs hctnqompeo4zyf3-8269">
                      <div className="pos-abs vector-8270">
                        <div className="nodeBg-8270 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs book-a-session--8271">
                <span className="book-a-session--8271-0 ">{`Book A Session Now`}</span>
              </div>
            </div>
          </div>
        </section>
        {/* Frame 491 */}
        <section className="pos-abs frame-49-8274">
          <div className="pos-abs heading-2--serv-8275">
            <span className="heading-2--serv-8275-0 ">{`What our clients are saying about us`}</span>
          </div>
          <div className="pos-abs customer-satisf-8276">
            <span className="customer-satisf-8276-0 ">{`Customer satisfaction is a primary goal for our company`}</span>
          </div>
        </section>
        {/* Frame 561 */}
        <section className="pos-abs frame-56-87225">
          <div className="pos-abs frame-53-87205">
            <div className="pos-abs frame-60-87258">
              <div className="pos-abs frame-54-87206">
                <div className="pos-abs div-87186">
                  <span className="div-87186-0 ">{`“`}</span>
                </div>
                <div className="pos-abs omg-i-cannot-be-87185">
                  <span className="omg-i-cannot-be-87185-0 ">{`“I loved how easy it was to explain my expert about the problems I had with respect to taxes. He understood it well and guided me through quickly. Loved the experience”`}</span>
                </div>
              </div>
              <div className="pos-abs frame-51-84168">
                <div className="pos-abs ellipse-4-84167">
                  <img
                    src={Suraj}
                    className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-84167 object-fit "
                    alt="Ellipse 41 Image"
                  />{" "}
                </div>
                <div className="pos-abs paragraph-84103">
                  <div className="pos-abs jessica-merchan-84104">
                    <span className="jessica-merchan-84104-0 ">{`Suraj Sharma`}</span>
                  </div>
                  <div className="pos-abs cfo-charity-gal-84105">
                    <span className="cfo-charity-gal-84105-0 ">{`Product Lead, Growth Velocity`}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pos-abs frame-59-87229">
              <div className="pos-abs frame-57-87226">
                <div className="pos-abs income-tax-retu-84177">
                  <span className="income-tax-retu-84177-0 ">{`Income Tax Return Filling`}</span>
                </div>
              </div>
              <div className="pos-abs frame-58-87227">
                <div className="pos-abs gst-registratio-87228">
                  <span className="gst-registratio-87228-0 ">{`GST Registration`}</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="pos-abs frame-58-87261">
            <div className="pos-abs frame-60-87262">
              <div className="pos-abs frame-54-87263">
                <div className="pos-abs div-87264">
                  <span className="div-87264-0 ">{`“`}</span>
                </div>
                <div className="pos-abs omg-i-cannot-be-87265">
                  <span className="omg-i-cannot-be-87265-0 ">{`“I needed help while setting my company. TaxView connected me with the right consultant and she gave me the right advice, the entire process was a breeze. Great work guys!”`}</span>
                </div>
              </div>
              <div className="pos-abs frame-51-87266">
                <div className="pos-abs ellipse-4-87267">
                  <img
                    src={Shravan}
                    className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-87267 object-fit "
                    alt="Ellipse 41 Image"
                  />{" "}
                </div>
                <div className="pos-abs paragraph-87268">
                  <div className="pos-abs jessica-merchan-87269">
                    <span className="jessica-merchan-87269-0 ">{`Shravan`}</span>
                  </div>
                  <div className="pos-abs cfo-charity-gal-87270">
                    <span className="cfo-charity-gal-87270-0 ">{`CEO, Rethink Systems`}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pos-abs frame-59-87271">
              <div className="pos-abs frame-57-87272">
                <div className="pos-abs income-tax-retu-87273">
                  <span className="income-tax-retu-87273-0 ">{`Income Tax Return Filling`}</span>
                </div>
              </div>
              <div className="pos-abs frame-58-87274">
                <div className="pos-abs gst-registratio-87275">
                  <span className="gst-registratio-87275-0 ">{`GST Registration`}</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="pos-abs frame-59-87276">
            <div className="pos-abs frame-60-87277">
              <div className="pos-abs frame-54-87278">
                <div className="pos-abs div-87279">
                  <span className="div-87279-0 ">{`“`}</span>
                </div>
                <div className="pos-abs omg-i-cannot-be-87280">
                  <span className="omg-i-cannot-be-87280-0 ">{`“Struggled a lot to file taxes for the first time. TaxView's expert guided me in the right direction. ”`}</span>
                </div>
              </div>
              <div className="pos-abs frame-51-87281">
                <div className="pos-abs ellipse-4-87282">
                  <img
                    src={Unnati}
                    className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-87282 object-fit "
                    alt="Ellipse 41 Image"
                  />{" "}
                </div>
                <div className="pos-abs paragraph-87283">
                  <div className="pos-abs jessica-merchan-87284">
                    <span className="jessica-merchan-87284-0 ">{ `Unnati `}</span>
                  </div>
                  <div className="pos-abs cfo-charity-gal-87285">
                    <span className="cfo-charity-gal-87285-0 ">{`Designer & Product Manager`}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pos-abs frame-59-87286">
              <div className="pos-abs frame-57-87287">
                <div className="pos-abs income-tax-retu-87288">
                  <span className="income-tax-retu-87288-0 ">{`Income Tax Return Filling`}</span>
                </div>
              </div>
              <div className="pos-abs frame-58-87289">
                <div className="pos-abs gst-registratio-87290">
                  <span className="gst-registratio-87290-0 ">{`GST Registration`}</span>
                </div>
              </div>
            </div> */}
          </div>
        </section>
        {/* Frame 601 */}
        <section className="pos-abs frame-60-114195">
          <div className="pos-abs frame-60-114196">
            <div className="pos-abs frame-54-114197">
              <div className="pos-abs div-114198">
                <span className="div-114198-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-114199">
                <span className="omg-i-cannot-be-114199-0 ">{`“Loved the experience of finding the right CA for my tax queries, booked a free call, got some clarity and filed taxes the right way :)”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-114200">
              <div className="pos-abs ellipse-4-114201">
                <img
                  src={Sheetal}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-114201 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-114202">
                <div className="pos-abs jessica-merchan-114203">
                  <span className="jessica-merchan-114203-0 ">{`Sheetal`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-114204">
                  <span className="cfo-charity-gal-114204-0 ">{`CTO, AfterLife`}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="pos-abs frame-59-114205">
            <div className="pos-abs frame-57-114206">
              <div className="pos-abs income-tax-retu-114207">
                <span className="income-tax-retu-114207-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-114208">
              <div className="pos-abs gst-registratio-114209">
                <span className="gst-registratio-114209-0 ">{`GST Registration`}</span>
              </div>
            </div>
          </div> */}
        </section>
        {/* Frame 611 */}
        <section className="pos-abs frame-61-114210">
          <div className="pos-abs frame-60-114211">
            <div className="pos-abs frame-54-114212">
              <div className="pos-abs div-114213">
                <span className="div-114213-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-114214">
                <span className="omg-i-cannot-be-114214-0 ">{`“Before TaxView, I had no way to measure and see if the CA's advice was right - With TaxView, I could consult multiple CAs and find the right solution”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-114215">
              <div className="pos-abs ellipse-4-114216">
                <img
                  src={Aditya}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-114216 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-114217">
                <div className="pos-abs jessica-merchan-114218">
                  <span className="jessica-merchan-114218-0 ">{`Aditya`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-114219">
                  <span className="cfo-charity-gal-114219-0 ">{`CEO, WhatNext`}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="pos-abs frame-59-114220">
            <div className="pos-abs frame-57-114221">
              <div className="pos-abs income-tax-retu-114222">
                <span className="income-tax-retu-114222-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-114223">
              <div className="pos-abs gst-registratio-114224">
                <span className="gst-registratio-114224-0 ">{`GST Registration`}</span>
              </div>
            </div>
          </div> */}
        </section>
        {/* Frame 621 */}
        <section className="pos-abs frame-62-114225">
          <div className="pos-abs frame-60-114226">
            <div className="pos-abs frame-54-114227">
              <div className="pos-abs div-114228">
                <span className="div-114228-0 ">{`“`}</span>
              </div>
              <div className="pos-abs omg-i-cannot-be-114229">
                <span className="omg-i-cannot-be-114229-0 ">{`“TaxView made finding experts for my legal needs so easy! Perfect place if you need the right advice”`}</span>
              </div>
            </div>
            <div className="pos-abs frame-51-114230">
              <div className="pos-abs ellipse-4-114231">
                <img
                  src={Atharv}
                  className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-114231 object-fit "
                  alt="Ellipse 41 Image"
                />{" "}
              </div>
              <div className="pos-abs paragraph-114232">
                <div className="pos-abs jessica-merchan-114233">
                  <span className="jessica-merchan-114233-0 ">{`Atharv`}</span>
                </div>
                <div className="pos-abs cfo-charity-gal-114234">
                  <span className="cfo-charity-gal-114234-0 ">{`Product Lead`}</span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="pos-abs frame-59-114235">
            <div className="pos-abs frame-57-114236">
              <div className="pos-abs income-tax-retu-114237">
                <span className="income-tax-retu-114237-0 ">{`Income Tax Return Filling`}</span>
              </div>
            </div>
            <div className="pos-abs frame-58-114238">
              <div className="pos-abs gst-registratio-114239">
                <span className="gst-registratio-114239-0 ">{`GST Registration`}</span>
              </div>
            </div>
          </div> */}
        </section>
        {/* Frame 701 */}
        <section className="pos-abs frame-70-89531">
          <div className="pos-abs ellipse-3-114116"></div>
          <div className="pos-abs bg-89293"></div>
          <div
            className="pos-abs frame-2-89533 cursor-pointer"
            onClick={bookaSession}
          >
            <div className="pos-abs background-89535">
              <div className="pos-abs bpysgyl5w3nhfbn-89536">
                <div className="pos-abs bpysgyl5w3nhfbn-89537">
                  <div className="pos-abs bpysgyl5w3nhfbn-89538">
                    <div className="pos-abs vector-89539">
                      <div className="nodeBg-89539 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs book-a-session-89534">
              <span className="book-a-session-89534-0 ">{`Book A Session`}</span>
            </div>
          </div>
        </section>
        {/* Frame 571 */}
        <section className="pos-abs frame-57-89297">
          <div className="pos-abs heading-2--serv-89298">
            <span className="heading-2--serv-89298-0 ">{`Meet with our Experts`}</span>
          </div>
          <div className="pos-abs customer-satisf-89299">
            <span className="customer-satisf-89299-0 ">{`Top Experts for your Domain`}</span>
          </div>
        </section>
        {/* Frame 671 */}
        <section className="pos-abs frame-67-89459">
          <div className="pos-abs frame-63-89375">
            <div className="pos-abs rectangle-42298-89376">
              <img
                src={Smitha}
                className="pos-abs image-div bg-no-repeat fill-parent bg-cover nodeBg-89376 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-89378">
              <div className="pos-abs jessica-merchan-89349">
                <span className="jessica-merchan-89349-0 ">{`CA Suchismita`}</span>
              </div>
              <div className="pos-abs frame-61-89365">
                <div className="pos-abs c-5-year-of-exper-89363">
                  <span className="c-5-year-of-exper-89363-0 ">{`3 Year Experience`}</span>
                </div>
                <div className="pos-abs clock01-89351">
                  <div className="pos-abs vector-89352">
                    <div className="nodeBg-89352 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89353">
                    <div className="nodeBg-89353 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-89366">
                <div className="pos-abs briefcase02-89371">
                  <div className="pos-abs vector-89374">
                    <div className="nodeBg-89374 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89372">
                    <div className="nodeBg-89372 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89373">
                    <div className="nodeBg-89373 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--89370">
                  <span className="gst-specialist--89370-0 ">{`GST Specialist, ITR Notices, Scrutiny `}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-89377">
              <div className="nodeBg-89377 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-89379">
              <div className="pos-abs usergroup-89385">
                <div className="pos-abs vector-89389">
                  <div className="nodeBg-89389 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89387">
                  <div className="nodeBg-89387 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89386">
                  <div className="nodeBg-89386 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89388">
                  <div className="nodeBg-89388 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-89384">
                <span className="c-100-happy-clien-89384-0 ">{`20+ Happy Client `}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-67-89461">
            <div className="pos-abs rectangle-42298-89462">
              <img
                src={Jay}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-89462 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-89463">
              <div className="pos-abs jessica-merchan-89464">
                <span className="jessica-merchan-89464-0 ">{`Jai `}</span>
              </div>
              <div className="pos-abs frame-61-89465">
                <div className="pos-abs c-5-year-of-exper-89469">
                  <span className="c-5-year-of-exper-89469-0 ">{`4 Year Experience`}</span>
                </div>
                <div className="pos-abs clock01-89466">
                  <div className="pos-abs vector-89467">
                    <div className="nodeBg-89467 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89468">
                    <div className="nodeBg-89468 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-89470">
                <div className="pos-abs briefcase02-89471">
                  <div className="pos-abs vector-89474">
                    <div className="nodeBg-89474 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89472">
                    <div className="nodeBg-89472 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89473">
                    <div className="nodeBg-89473 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--89475">
                  <span className="gst-specialist--89475-0 ">{`Life Insurance, Medi Claim,
Investement Advisory`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-89476">
              <div className="nodeBg-89476 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-89477">
              <div className="pos-abs usergroup-89478">
                <div className="pos-abs vector-89482">
                  <div className="nodeBg-89482 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89480">
                  <div className="nodeBg-89480 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89479">
                  <div className="nodeBg-89479 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89481">
                  <div className="nodeBg-89481 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-89483">
                <span className="c-100-happy-clien-89483-0 ">{`120+ Happy Client `}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-68-89484">
            <div className="pos-abs rectangle-42298-89485">
              <img
                src={Heena}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-89485 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-89486">
              <div className="pos-abs jessica-merchan-89487">
                <span className="jessica-merchan-89487-0 ">{`CA Heena`}</span>
              </div>
              <div className="pos-abs frame-61-89488">
                <div className="pos-abs c-5-year-of-exper-89492">
                  <span className="c-5-year-of-exper-89492-0 ">{`8 Year Experience`}</span>
                </div>
                <div className="pos-abs clock01-89489">
                  <div className="pos-abs vector-89490">
                    <div className="nodeBg-89490 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89491">
                    <div className="nodeBg-89491 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-89493">
                <div className="pos-abs briefcase02-89494">
                  <div className="pos-abs vector-89497">
                    <div className="nodeBg-89497 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89495">
                    <div className="nodeBg-89495 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89496">
                    <div className="nodeBg-89496 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--89498">
                  <span className="gst-specialist--89498-0 ">{`ITR Filling, Notices and Scrutiny, IT Appeals`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-89499">
              <div className="nodeBg-89499 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-89500">
              <div className="pos-abs usergroup-89501">
                <div className="pos-abs vector-89505">
                  <div className="nodeBg-89505 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89503">
                  <div className="nodeBg-89503 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89502">
                  <div className="nodeBg-89502 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89504">
                  <div className="nodeBg-89504 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-89506">
                <span className="c-100-happy-clien-89506-0 ">{`150+ Happy Client `}</span>
              </div>
            </div>
          </div>
          <div className="pos-abs frame-69-89507">
            <div className="pos-abs rectangle-42298-89508">
              <img
                src={Ajit}
                className="pos-abs image-div bg-no-repeat  bg-crop nodeBg-89508 object-fit "
                alt="Rectangle 422981 Image"
              />{" "}
            </div>
            <div className="pos-abs frame-64-89509">
              <div className="pos-abs jessica-merchan-89510">
                <span className="jessica-merchan-89510-0 ">{`CA Ajit`}</span>
              </div>
              <div className="pos-abs frame-61-89511">
                <div className="pos-abs c-5-year-of-exper-89515">
                  <span className="c-5-year-of-exper-89515-0 ">{`10 Year Experience`}</span>
                </div>
                <div className="pos-abs clock01-89512">
                  <div className="pos-abs vector-89513">
                    <div className="nodeBg-89513 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89514">
                    <div className="nodeBg-89514 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-62-89516">
                <div className="pos-abs briefcase02-89517">
                  <div className="pos-abs vector-89520">
                    <div className="nodeBg-89520 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89518">
                    <div className="nodeBg-89518 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-89519">
                    <div className="nodeBg-89519 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
                <div className="pos-abs gst-specialist--89521">
                  <span className="gst-specialist--89521-0 ">{`Company Incorporation, Trademark and Patent Registration`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs vector-4-89522">
              <div className="nodeBg-89522 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
            </div>
            <div className="pos-abs frame-63-89523">
              <div className="pos-abs usergroup-89524">
                <div className="pos-abs vector-89528">
                  <div className="nodeBg-89528 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89526">
                  <div className="nodeBg-89526 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89525">
                  <div className="nodeBg-89525 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-89527">
                  <div className="nodeBg-89527 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs c-100-happy-clien-89529">
                <span className="c-100-happy-clien-89529-0 ">{`200+ Happy Client `}</span>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="pos-abs ellipse-2-87292"></div> */}
        {/* Container2 */}
        {/* <section className="pos-abs container-114129">
          <div className="pos-abs container-114130">
            <div className="pos-abs background-114147">
              <div className="pos-abs gst-specialist--1141103">
                <span className="gst-specialist--1141103-0 ">{`GST Specialist, Wealth Management,
Financial Expert`}</span>
              </div>
              <div className="pos-abs svg-1141113">
                <div className="pos-abs vector-1141115">
                  <div className="nodeBg-1141115 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-1141116">
                  <div className="nodeBg-1141116 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs vector-1211209">
                <div className="nodeBg-1211209 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171181">
                <div className="nodeBg-1171181 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171191">
                <div className="nodeBg-1171191 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171182">
                <div className="nodeBg-1171182 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171192">
                <div className="nodeBg-1171192 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs svg-1141117">
                <div className="pos-abs vector-1141119">
                  <div className="nodeBg-1141119 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-1141120">
                  <div className="nodeBg-1141120 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs gst-specialist--1141104">
                <span className="gst-specialist--1141104-0 ">{`GST Specialist, Wealth Management,
Financial Expert`}</span>
              </div>
              <div className="pos-abs vector-1211210">
                <div className="nodeBg-1211210 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171179">
                <div className="nodeBg-1171179 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171189">
                <div className="nodeBg-1171189 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs gst-specialist--1141108">
                <span className="gst-specialist--1141108-0 ">{`GST Specialist, Wealth Management,
Financial Expert`}</span>
              </div>
              <div className="pos-abs vector-1211211">
                <div className="nodeBg-1211211 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
            <div className="pos-abs background-114131">
              <div className="pos-abs unlimited-exper-114132">
                <span className="unlimited-exper-114132-0 ">{`Unlimited expert
help`}</span>
              </div>
              <div className="pos-abs svg-114133">
                <div className="pos-abs vector-114134">
                  <div className="nodeBg-114134 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-114135">
                  <div className="nodeBg-114135 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
                <div className="pos-abs vector-114136">
                  <div className="nodeBg-114136 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                </div>
              </div>
              <div className="pos-abs svg-114137">
                <div className="pos-abs group-114138">
                  <div className="pos-abs vector-114145">
                    <div className="nodeBg-114145 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114144">
                    <div className="nodeBg-114144 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114146">
                    <div className="nodeBg-114146 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114139">
                    <div className="nodeBg-114139 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114140">
                    <div className="nodeBg-114140 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114141">
                    <div className="nodeBg-114141 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114142">
                    <div className="nodeBg-114142 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                  <div className="pos-abs vector-114143">
                    <div className="nodeBg-114143 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs container-114167">
            <div className="pos-abs background-114168">
              <div className="pos-abs heading-2--serv-1181193">
                <span className="heading-2--serv-1181193-0 ">{`Others`}</span>
              </div>
              <div className="pos-abs svg-114170"></div>
              <div className="pos-abs gst-specialist--1141109">
                <span className="gst-specialist--1141109-0 ">{`GST Specialist, Wealth Management,
Financial Expert`}</span>
              </div>
              <div className="pos-abs vector-1181195">
                <div className="nodeBg-1181195 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs vector-1171172">
                <div className="nodeBg-1171172 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs gst-specialist--1141110">
                <span className="gst-specialist--1141110-0 ">{`GST Specialist, Wealth Management,
Financial Expert`}</span>
              </div>
              <div className="pos-abs vector-1191207">
                <div className="nodeBg-1191207 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
              <div className="pos-abs gst-specialist--1141111">
                <span className="gst-specialist--1141111-0 ">{`GST Specialist, Wealth Management,
Financial Expert`}</span>
              </div>
              <div className="pos-abs vector-1191208">
                <div className="nodeBg-1191208 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <div className="pos-abs ellipse-1-114117"></div> */}
        {/* Frame 781 */}
        {/* <section className="pos-abs frame-78-89626">
          <div className="pos-abs heading-2--serv-1141112">
            <span className="heading-2--serv-1141112-0 ">{`Taxview`}</span>
          </div>
        </section> */}
        {/* Container1 */}
        {/* <section className="pos-abs container-114124">
          <div className="pos-abs container-114125">
            <div className="pos-abs frame-76-89624">
              <div className="pos-abs heading-2--serv-89578">
                <span className="heading-2--serv-89578-0 ">{`Find best, Take Best `}</span>
              </div>
              <div className="pos-abs customer-satisf-89579">
                <span className="customer-satisf-89579-0 ">{`Customer satisfaction is a primary goal for our company. Whether you need in-the-moment support or want a tax pro to double-check your work, we got you covered. `}</span>
              </div>
            </div>
          </div>
        </section> */}
        <div className="pos-abs bg-89630"></div>
        {/* Container3 */}
        <section className="pos-abs container-114240">
          <div className="pos-abs container-114241">
            <div className="pos-abs container-114242">
              <div className="pos-abs border-114248"></div>
            </div>
            <div className="pos-abs heading-2--freq-114249">
              <span className="heading-2--freq-114249-0 ">{`Frequently Asked`}</span>
            </div>
          </div>
          <div className="pos-abs container-114250">
            <div className="pos-abs background-114251">
              <div className="pos-abs border-114255"></div>
              <div className="pos-abs heading-5--when-114252">
                <span className="heading-5--when-114252-0 ">{`What is TaxView?`}</span>
              </div>
              <div className="pos-abs horizontalborde-114253"></div>
              <div className="pos-abs the-event-will--114254">
                <span className="the-event-will--114254-0 ">{`TaxView is your go to platform for connecting with the best consultants for all your Tax / GST / Legal needs. We onboard the best experts and help you connect, engage and solve problems with the best consultants`}</span>
              </div>
            </div>
            <div className="pos-abs background-114256">
              <div className="pos-abs border-114260"></div>
              <div className="pos-abs heading-5--is-t-114257">
                <span className="heading-5--is-t-114257-0 ">{`Can I book a free session?`}</span>
              </div>
              <div className="pos-abs horizontalborde-114258"></div>
              <div className="pos-abs yes-there-is-a--114259">
                <span className="yes-there-is-a--114259-0 ">{`Yes you can, sign in, select the domain you need help with, select the consultant based on years of experience, work profile and either book a service with them or book a free call`}</span>
              </div>
            </div>
            <div className="pos-abs background-114261">
              <div className="pos-abs border-114265"></div>
              <div className="pos-abs heading-5--what-114262">
                <span className="heading-5--what-114262-0 ">{`What are the services TaxView offers?`}</span>
              </div>
              <div className="pos-abs horizontalborde-114263"></div>
              <div className="pos-abs the-event-will--114264">
                <span className="the-event-will--114264-0 ">{`TaxView offers consultants across Tax / Legal and Insurance needs. You can explore the diverse range of domains from the dashboard. Login with your phone number and select the domain you need help on`}</span>
              </div>
            </div>
            <div className="pos-abs background-114266">
              <div className="pos-abs border-114270"></div>
              <div className="pos-abs heading-5--can--114267">
                <span className="heading-5--can--114267-0 ">{`How does TaxView get the best experts?`}</span>
              </div>
              <div className="pos-abs horizontalborde-114268"></div>
              <div className="pos-abs yes-we-welcome--114269">
                <span className="yes-we-welcome--114269-0 ">{`TaxView onboards only SEBI certified / CA Qualified or Legal practicing Consultants. They have the best acumen and worked with atleast 10+ clients to solve their needs. Be assured you're in the best hands!`}</span>
              </div>
            </div>
          </div>
        </section>
        <div className="pos-abs ellipse-3-114121"></div>
        <div className="pos-abs ellipse-4-114122"></div>
        <div className="pos-abs rectangle-42302-921105"></div>
        <div className="pos-abs join-as-a-ca-921173">
          <span className="join-as-a-ca-921173-0 ">{`Join as an Expert`}</span>
        </div>
        {/* <div className="pos-abs be-inspired-by--921174">
          <span className="be-inspired-by--921174-0 ">{`Be inspired by thought-provoking keynotes, workshops, and networking opportunities. `}</span>
        </div> */}
        {/* Frame 21 */}
        <section className="pos-abs frame-2-921175 cursor-pointer" onClick={()=>{joinNow()}}>
          <div className="pos-abs background-921177">
            <div className="pos-abs bpysgyl5w3nhfbn-921178">
              <div className="pos-abs bpysgyl5w3nhfbn-921179">
                <div className="pos-abs bpysgyl5w3nhfbn-921180">
                  <div className="pos-abs vector-921181">
                    <div className="nodeBg-921181 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pos-abs join-now-921176">
            <span className="join-now-921176-0 ">{`Join Now`}</span>
          </div>
        </section>
        {/* Frame 941 */}
        <section className="pos-abs frame-94-921172">
          <div className="pos-abs background-921125">
            <div className="pos-abs border-921166 cursor-pointer" onClick={()=>{navigate("/tnc")}}></div>
            <div className="pos-abs navigate-921126 cursor-pointer" onClick={()=>{navigate("/tnc")}}>
              <span className="navigate-921126-0 ">{`Terms & Conditions`}</span>
            </div>
            {/* <div className="pos-abs support-921133">
              <span className="support-921133-0 ">{`Support`}</span>
            </div> */}
            <div className="pos-abs contact-921139">
              <span className="contact-921139-0 ">{`Contact`}</span>
            </div>
            {/* <div className="pos-abs heading-5-921158">
              <div className="pos-abs subscribe-for-t-921159">
                <span className="subscribe-for-t-921159-0 ">{`Subscribe for the latest 
event updates`}</span>
              </div>
            </div> */}
            <div className="pos-abs frame-140-1431765">
              <div className="pos-abs h9jxuen4q7yyuxh-921140">
                <div className="pos-abs h9jxuen4q7yyuxh-921141">
                  <div className="pos-abs h9jxuen4q7yyuxh-921142">
                    <div className="pos-abs vector-921144">
                      <div className="nodeBg-921144 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-921143">
                      <div className="nodeBg-921143 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-921145">
                      <div className="nodeBg-921145 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-139-1431764">
                <div className="pos-abs c-4296-coplin-ave-921146">
                  <span className="c-4296-coplin-ave-921146-0 ">{`Mumbai`}</span>
                </div>
                <div className="pos-abs arizona-59412-921147">
                  <span className="arizona-59412-921147-0 ">{`India`}</span>
                </div>
              </div>
            </div>
            <div className="pos-abs paragraph-921127">
              <div className="pos-abs link--home-921128 cursor-pointer" onClick={()=>{navigate("/tnc")}}>
                <span className="link--home-921128-0 " >{`Privacy Policy`}</span>
              </div>
              <div className="pos-abs link--agenda-921129 cursor-pointer" onClick={()=>{navigate("/tnc")}}>
                <span className="link--agenda-921129-0 cursor-pointer" >{`Cookie Ploicy`}</span>
              </div>
              {/* <div className="pos-abs link--speakers-921130">
                <span className="link--speakers-921130-0 ">{`Speakers`}</span>
              </div>
              <div className="pos-abs link--tickets-921131">
                <span className="link--tickets-921131-0 ">{`Tickets`}</span>
              </div>
              <div className="pos-abs link--insights-921132">
                <span className="link--insights-921132-0 ">{`Insights`}</span>
              </div> */}
            </div>
            {/* <div className="pos-abs paragraph-921134">
              <div className="pos-abs link--terms--pr-921135">
                <span className="link--terms--pr-921135-0 ">{`Terms & Privacy`}</span>
              </div>
              <div className="pos-abs link--faqs-921136">
                <span className="link--faqs-921136-0 ">{`FAQs`}</span>
              </div>
              <div className="pos-abs link--become-sp-921137">
                <span className="link--become-sp-921137-0 ">{`Become Sponsor`}</span>
              </div>
              <div className="pos-abs link--get-ticke-921138">
                <span className="link--get-ticke-921138-0 ">{`Get Tickets`}</span>
              </div>
            </div> */}
            {/* <div className="pos-abs border-921165"></div> */}
            {/* <div className="pos-abs form-921160">
              <div className="pos-abs input-921163">
                <div className="pos-abs send-921164">
                  <span className="send-921164-0 ">{`Send`}</span>
                </div>
              </div>
              <div className="pos-abs input-921161">
                <div className="pos-abs enter-your-emai-921162">
                  <span className="enter-your-emai-921162-0 ">{`Enter your email`}</span>
                </div>
              </div>
            </div> */}
            <div className="pos-abs frame-142-1431767">
              <div className="pos-abs ygonarmqkxum8wv-921148">
                <div className="pos-abs ygonarmqkxum8wv-921149">
                  <div className="pos-abs ygonarmqkxum8wv-921150">
                    <div className="pos-abs vector-921152">
                      <div className="nodeBg-921152 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                    <div className="pos-abs vector-921151">
                      <div className="nodeBg-921151 pos-abs pos-init fill-parent image-div bg-contain bg-no-repeat "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pos-abs frame-141-1431766">
                <div className="pos-abs link-921153">
                  <div className="pos-abs hellowildframer-921154">
                    <span className="hellowildframer-921154-0 ">{`tech@taxview.com`}</span>
                  </div>
                </div>
                <div className="pos-abs link-921156">
                  <div className="pos-abs c-1-4953953940-921157">
                    <span className="c-1-4953953940-921157-0 ">{`+91 8879073748`}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pos-abs horizontalborde-921155"></div>
          </div>
          {/* <div className="pos-abs wildframer--202-921171">
            <span className="wildframer--202-921171-0 ">{`WildFramer © 2024, All Rights Reserved`}</span>
          </div> */}
        </section>
      </div>
      {loading && <SpinnerComponent/>}
      <Modal show={showLoginModal} className="relative">
        <div className="h-96 static">
          <div className="flex static">
            <div className="hidden lg:block lg:w-1/2 static">
              <img src={TaxviewLogo} className="absolute top-4 left-4 h-6" />
              <img
                src={PopupBottom}
                className="absolute bottom-0 left-0 rounded-bl-md h-40 z-0"
              />
              <img
                src={LoginPopup}
                className="absolute w-4/6 bottom-0 h-60 -left-20 z-0"
              />
            </div>
            <div className="static w-full lg:w-1/2">
              <img
                src={PopupTop}
                className="absolute top-0 right-0 h-10 rounded-tr-md"
              />
              <img
                src={PopupBottomMobile}
                className="block absolute bottom-0 left-0 lg:hidden rounded-bl-md"
              />
              <div
                className="cursor-pointer absolute top-1 right-4 text-white"
                onClick={() => {
                  setConsultant(false)
                  setShowLoginModal(false);
                }}
              >
                X
              </div>
              <div className="mt-6 p-4 z-10 relative">
                <div className="text-xl font-bold">Let's get you Started!</div>
                <div className="text-xs font-light">
                  {consultant?"Be a Taxview Expert":"Book a session with our experts"}
                </div>
                {pageState !== "newAccount" ? (
                  <div id="login-section">
                    <div id="number-section" className="mt-6">
                      <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                        <div>Mobile Number</div>
                        {mobileNumber.length === 10 &&
                          pageState === "number" && (
                            <div
                              className="cursor-pointer text-[#547AF7] font-bold"
                              onClick={sendOTP}
                            >
                              Get OTP
                            </div>
                          )}
                      </div>
                      <input
                        className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                        placeholder="Enter your Mobile Number"
                        disabled={pageState !== "number"}
                        type="number"
                        id="mobile-number"
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                      />
                    </div>
                    {pageState === "otp" && (
                      <div
                        className="cursor-pointer text-[#547AF7] font-bold mt-1 text-xs"
                        onClick={() => {
                          sendOTP();
                        }}
                      >
                        Resend OTP
                      </div>
                    )}
                    <div id="otp-section" className="mt-6">
                      <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                        <div>OTP</div>
                        {pageState === "otp" && (
                          <div
                            className="cursor-pointer text-[#547AF7] font-bold"
                            onClick={() => {
                              setMobileNumber("");
                              setOTP("");
                              setPageState("number");
                              document.getElementById("mobile-number")?.focus();
                            }}
                          >
                            Change Number
                          </div>
                        )}
                      </div>

                      <input
                        className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-2"
                        placeholder="Enter OTP"
                        disabled={pageState !== "otp"}
                        type="number"
                        value={otp}
                        onChange={handleOTPChange}
                      />
                    </div>

                    <div
                      className="flex justify-between items-center mt-4"
                      id="whatsapp-section"
                    >
                      <div className="flex items-center gap-1">
                        <IoLogoWhatsapp className="text-[#27B43E]" />{" "}
                        <div className="text-xs">
                          {" "}
                          Subscribe to get updates via Whatsapp
                        </div>
                      </div>
                      <div>
                        <Checkbox
                          checked={sendWhatsAppNotifications}
                          onClick={(event: any) => {
                            console.log(event);
                            setSendWhatsappNotifications(event.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <Button
                        className="text-xs w-full bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                        size="xs"
                        disabled={pageState !== "otp" || otp.length != 6}
                        onClick={verifyOTP}
                      >
                        Log In
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div id="onboarding-section">
                    <form onSubmit={handlePersonalDetailsUpdate}>
                      <div id="name-section" className="mt-6">
                        <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
                          <div>Tell us your Name</div>
                        </div>
                        <input
                          className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
                          placeholder="Enter your Name"
                          type="text"
                          id="mobile-number"
                          value={name}
                          required
                          onChange={(event: any) => {
                            setName(event.target.value);
                          }}
                        />
                      </div>
                      <div id="email-section" className="mt-6">
                        <div className="flex justify-between items-center font-semibold text-xs text-[#8A8A8A]">
                          <div>Email Id</div>
                        </div>
                        <input
                          className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-2"
                          placeholder="Enter you Email Id"
                          type="email"
                          value={email}
                          required
                          onChange={(event: any) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </div>
                      <div className="mt-4">
                        <Button
                          className="text-xs w-full bg-[#547AF7] hover:bg-[#547AF7] sm:mb-2"
                          size="xs"
                          type="submit"
                        >
                          Next
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
            <div></div>
          </div>
        </div>
        {loading ? <SpinnerComponent /> : null}
      </Modal>
    </div>
  );
};

export default HomePage;
