import { useEffect, useState } from "react";
import AxiosInstance from "../../Utils/AxiosInstance";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../../Components/SpinnerComponent";
import ConsultantProfileInfo from "./ConsultantComponents/ConsultantProfileInfo";
import ConsultantKYCInfo from "./ConsultantComponents/ConsultantKYCInfo";
import ConsultantProfessionaInfo from "./ConsultantComponents/ConsultantProfessionalInfo";
import ConsultantProfile from "./ConsultantComponents/ConsultantProfile";
import ConsultantSideBar from "./ConsultantComponents/ConsultantSideBar";
import RegistrationRejected from "../../Images/RegistationRejected.svg";
import Success from "../../Images/Success.svg";
import { Button } from "flowbite-react";
import { toast } from "react-toastify";

function ConsultantOnboarding() {
  const [consultantDetails, setConsultantDeatils]: any = useState({});
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [showNewAccount, setShowNewAccount] = useState(false);

  const rectifyAccount = () => {
    AxiosInstance()
      .post("/consultant/resetAccount")
      .then(({ data }) => {
        getDeatails();
      })
      .catch((err) => {
        toast.error("Failed to reset account, please try again");
      });
  };

  const navigate = useNavigate();
  useEffect(() => {
    getDeatails();
  }, []);
  const getDeatails = () => {
    setLoading(true);
    AxiosInstance()
      .get("/consultant/verify")
      .then(({ data }) => {
        setConsultantDeatils(data);
        setStatus(data.status);
        if(data.status === "ACCOUNT_VERIFIED"){
          navigate("/consultant/dashboard")
        }
        setLoading(false);
      })
      .catch((err) => {
        localStorage.clear();
        setLoading(false);
        navigate("/");
      });
  };
  return (
    <>
      {status === "NEW_ACCOUNT" && (
        <>
          {showNewAccount ? (
            <div></div>
          ) : (
            <ConsultantProfileInfo
              userInfo={consultantDetails}
              onSuccess={() => {
                getDeatails();
              }}
              setState={(state: any) => {
                setStatus(state);
              }}
              onBoarding={true}
            />
          )}
        </>
      )}
      {status === "PROFILE_COMPLETED" && (
        <ConsultantKYCInfo
          userInfo={consultantDetails}
          onSuccess={() => {
            getDeatails();
          }}
          setState={(state: any) => {
            setStatus(state);
          }}
          onBoarding={true}
        />
      )}
      {status === "KYC_ADDED" && (
        <ConsultantProfessionaInfo
          userInfo={consultantDetails}
          onSuccess={() => {
            getDeatails();
          }}
          setState={(state: any) => {
            setStatus(state);
          }}
          onBoarding={true}
        />
      )}

      {status === "PROFESSIONAL_DETAILS_ADDED" && (
        <ConsultantProfile
          userInfo={consultantDetails}
          setState={(state: any) => {
            setStatus(state);
          }}
          onSuccess={() => {
            getDeatails();
          }}
          onBoarding={true}
        />
      )}

      {status === "UNDER_REVIEW" && (
        <ConsultantSideBar>
          <div className="h-full w-full justify-center items-center text-center flex flex-col p-4">
            <img src={Success} />
            <div className="font-bold text-2xl text-[#0CBC8B] mt-2 mb-2">
              Profile Submission Success
            </div>
            <div className="text-sm font-light">
              {" "}
              <div>
              Thanks for sharing your data,
              <strong>
                your profile is being reviewed and might take upto 48 hours to
                confirm.
              </strong>
              </div>
              <div>Once your account validation is done your profile will be visible on TaxView.</div>
            </div>
          </div>
        </ConsultantSideBar>
      )}

      {status === "ACCOUNT_REJECTED" && (
        <ConsultantSideBar>
          <div className="h-full w-full justify-center items-center text-center flex flex-col p-4">
            <img src={RegistrationRejected} />
            <div className="font-bold text-2xl text-[#FF5F69] mt-2 mb-2">
              Regisration Rejected
            </div>
            <div className="text-sm font-light mb-2">
              {" "}
              Your profile has been rejected due to{" "}
              <strong>{consultantDetails.rejectedMessage}</strong>.
            </div>
            <div className="text-sm font-light mb-8">
              Please rectify the above mentioned reasons and resubmit your
              profile for verification
            </div>
            <Button
              className="text-xs w-fit bg-[#547AF7] hover:bg-[#547AF7] mb-2 w-full lg:w-[200px]"
              size="xs"
              onClick={rectifyAccount}
            >
              Rectify Account
            </Button>
          </div>
        </ConsultantSideBar>
      )}
      {loading && <SpinnerComponent />}
    </>
  );
}

export default ConsultantOnboarding;
