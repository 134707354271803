import { useState } from "react";
import Category from "../Components/Category";
import NavigationBar from "../Components/NavigationBar";
import { IoArrowBackSharp } from "react-icons/io5";
import SelectSlot from "../Components/SelectSlot";

function SlotBooking() {
  const [slotStep, setSlotStep] = useState(0);
  const [selectedService, setSelectedService] = useState("");

  const onCategorySelect = (type: string) => {
    setSelectedService(type);
    setSlotStep(slotStep + 1);
  };
  return (
    <NavigationBar>
      <div className="h-full">
        <div className="text-2xl  text-[#1a1a1a] text-bold leading-[normal]">
          {slotStep === 0 && "Select Category"}
          {slotStep === 1 && (
            <div className="flex items-center gap-2 cursor-pointer" onClick={()=>{setSlotStep(0)}}>
              {" "}
              <IoArrowBackSharp />
              <div>Book Session/ {selectedService}</div>
            </div>
          )}
        </div>
        {slotStep === 0 && <Category onCategorySelect={onCategorySelect} />}
        {slotStep === 1 && <SelectSlot category={selectedService}/>}
      </div>
    </NavigationBar>
  );
}

export default SlotBooking;
