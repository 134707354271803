import { useEffect, useState } from "react";
import NavigationBar from "../Components/NavigationBar";
import SlotBooking from "./SlotBooking";
import mixpanel from 'mixpanel-browser';

import NoSession from "../Static/Images/NoSession.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Avatar, Button, Checkbox, Modal, Rating } from "flowbite-react";
import { IoIosLink } from "react-icons/io";
import { FaRegClock } from "react-icons/fa6";
import { Parser } from "html-to-react";
import { FaChevronDown } from "react-icons/fa";
import AxiosInstance from "../Utils/AxiosInstance";
import { toast } from "react-toastify";
import SpinnerComponent from "../Components/SpinnerComponent";
import SelectionPopup from "../Components/SelectionPopup";

function UserDashboard() {
  const [firstSlot, setFirstSlot] = useState(
    localStorage.getItem("firstSlot") === "True"
  );
  const [selectedTab, setSelectedTab] = useState("Upcoming Session");
  const [showSessionDetails, setShowSessionDetails] = useState("");

  const [consultedCAs, setConultedCAs]: any = useState([]);
  const [selectedCA, setSelectedCA]:any = useState(null);
  const [showSlots, setShowSlots] = useState(false);

  mixpanel.init("2a8c8c171837a69413b0e3cbe9e26fb2")

  const navigate = useNavigate();
  const htmlParser = Parser();
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackCall, setFeedbackCall]: any = useState({});
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback]: any = useState({
    rating: 0,
    details: {
      understoodProblem: false,
      explainedProcess: false,
      answeredAll: false,
    },
  });

  useEffect(() => {
    if (!firstSlot) {
      getUpcomingSlots();
      getConsultedCAs();
    }
  }, []);

  const getUpcomingSlots = () => {
    setLoading(true);
    AxiosInstance()
      .get("/users/getUpcomingBookings")
      .then(({ data }) => {
        setUpcomingSessions(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get your upcoming sessions ${err.message}`);
        setLoading(false);
      });
  };

  const getPastSlots = () => {
    setLoading(true);
    AxiosInstance()
      .get("/users/getPastBookings")
      .then(({ data }) => {
        setPastSessions(data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(`Failed to get your past sessions ${err.message}`);
        setLoading(false);
      });
  };

  const handleRatingClick = (rating: any) => {
    let tempFeedback = feedback;
    tempFeedback.rating = rating;
    setFeedback({ ...tempFeedback });
  };

  const handleRatingCheckBoxClick = (checkboxname: any, value: any) => {
    let tempFeedback = feedback;
    tempFeedback.details[checkboxname] = value;
    setFeedback({ ...tempFeedback });
  };

  const submitFeedback = () => {
    setLoading(true);
    AxiosInstance()
      .post("/users/rateASession", {
        bookingId: feedbackCall._id,
        feedback: feedback,
      })
      .then(({ data }) => {
        setShowFeedbackModal(false);
        toast.success("Feedback submitted");
        setLoading(false);
        getPastSlots();
      })
      .catch((err) => {
        toast.error(`Failed to submit feedback ${err.message}`);
        setLoading(false);
      });
  };

  const getConsultedCAs = () => {
    AxiosInstance()
      .get("/users/getConsultedCAs")
      .then(({ data }) => {
        console.log(data)
        setConultedCAs(data);
      });
  };

  return (
    <>
      {firstSlot ? (
        <SlotBooking />
      ) : (
        <NavigationBar>
          <div className="h-dvh overflow-auto">
            <div className="overflow-y-auto">
              <div className="m-4 flex gap-2 items-center justify-between">
                <div className="flex gap-2 items-center">
                  <div
                    className={`pl-4 pt-1 pb-1 ${
                      selectedTab === "Upcoming Session"
                        ? "border-l-4 border-[#0CBC8B] text-bold text-lg text-[#212121]"
                        : "text-xs text-[#B1B1B1] cursor-pointer"
                    }`}
                    onClick={() => {
                      getUpcomingSlots();
                      setSelectedTab("Upcoming Session");
                    }}
                  >
                    Upcoming Session
                  </div>
                  <div
                    className={`pl-2 pt-1 pb-1 ${
                      selectedTab === "Past Session"
                        ? "border-l-4 border-[#0CBC8B] text-bold text-lg text-[#212121]"
                        : "text-xs text-[#B1B1B1] cursor-pointer"
                    }`}
                    onClick={() => {
                      getPastSlots();
                      setShowSessionDetails("");
                      setSelectedTab("Past Session");
                    }}
                  >
                    Past Session
                  </div>
                </div>
                <div className="hidden lg:block">
                  <Button
                    className="text-center bg-[#547AF7] font-bold text-sm"
                    onClick={() => {
                      navigate("/slotBooking");
                    }}
                  >
                    Book a Slot
                  </Button>
                </div>
              </div>
              <div className="block lg:hidden">
                <Button
                  className="text-center bg-[#547AF7] font-bold text-sm"
                  onClick={() => {
                    navigate("/slotBooking");
                  }}
                >
                  Book a Slot
                </Button>
              </div>
              {selectedTab === "Upcoming Session" && (
                <>
                  {upcomingSessions.length === 0 ? (
                    <div className="flex flex-col items-center justify-center w-full h-inherit p-8">
                      <img src={NoSession} />
                      <div className="text-sm text-[#121212]">
                        No Upcoming Sessions Available
                      </div>
                      <div
                        className="text-sm text-[#547AF7] underline decoration-[#547AF7] cursor-pointer"
                        onClick={() => {
                          navigate("/slotBooking");
                        }}
                      >
                        Book a Session
                      </div>
                    </div>
                  ) : (
                    <div className="p-4 flex flex-col h-96 overflow-y-auto">
                      {upcomingSessions.map((session: any) => (
                        <div className="pb-2 mt-2 border-b-2 border-[#DEDEDE]">
                          <div className="flex flex-col lg:flex-row justify-between rounded-lg bg-white p-4 items-center">
                            <div className="flex gap-2 items-center">
                              <Avatar
                                img={session.consultantDetails.profilePic}
                                rounded
                                className="z-0"
                              />
                              <div>
                                <div className="flex gap-1">
                                  <div className="font-bold">
                                  {session.consultantDetails.prefix}{" "}{session.consultantDetails.username}
                                  </div>
                                  <div className="font-light">
                                    ({session.consultantDetails.experience} Year
                                    Experience)
                                  </div>
                                </div>
                                <div className="text-xs font-light">
                                  Session with {session.consultantDetails.prefix}{" "}
                                  {session.consultantDetails.username} to help
                                  you with {session.services.toString()}
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-1 flex-col">
                              <div className="flex gap-1 items-center font-bold">
                                <FaRegClock />
                                {moment(session.startTime).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}
                              </div>
                              <div
                                className="flex gap-1 items-center font-light text-xs text text-[#547AF7] cursor-pointer"
                                onClick={() => {
                                  mixpanel.track("Joined Session",{
                                    distinct_id:session.userId,
                                    consultantId:session.consultantId,
                                    consultantUsername:session.consultantDetails.username,
                                    consultantNumber:session.consultantDetails.number,
                                    startTime:session.startTime
                                  })
                                  window.open(
                                    session.meetingUrl,
                                    "_blank",
                                    "noreferrer"
                                  );
                                }}
                              >
                                <IoIosLink /> Join Session
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              {selectedTab === "Past Session" && (
                <>
                  {pastSessions.length === 0 ? (
                    <div className="flex flex-col items-center justify-center w-full h-inherit p-8">
                      <img src={NoSession} />
                      <div className="text-sm text-[#121212]">
                        No Past Sessions Available
                      </div>
                      <div
                        className="text-sm text-[#547AF7] underline decoration-[#547AF7] cursor-pointer"
                        onClick={() => {
                          navigate("/slotBooking");
                        }}
                      >
                        Book a Session
                      </div>
                    </div>
                  ) : (
                    <div className="p-4 flex flex-col h-96 overflow-y-auto">
                      {pastSessions.map((session: any) => (
                        <div className="pb-2 mt-2 border-b-2 border-[#DEDEDE]">
                          <div className="rounded-lg bg-white p-4">
                            <div className="flex flex-col lg:flex-row justify-between items-center">
                              <div className="flex gap-2 items-center">
                                <Avatar
                                  img={session.consultantDetails.profilePic}
                                  rounded
                                  className="z-0"
                                />
                                <div>
                                  <div className="flex gap-1">
                                    <div className="font-bold">
                                    {session.consultantDetails.prefix}{" "}{session.consultantDetails.username}
                                    </div>
                                    <div className="font-light">
                                      ({session.consultantDetails.experience}{" "}
                                      Year Experience)
                                    </div>
                                  </div>
                                  <div className="text-xs font-light">
                                    Session with{session.consultantDetails.prefix}{" "}
                                    {session.consultantDetails.username} to help
                                    you with {session.statement}
                                  </div>
                                </div>
                              </div>
                              <div className="flex gap-1 flex-col">
                                <div className="flex gap-1 items-center font-bold">
                                  <FaRegClock />
                                  {moment(session.startTime).format(
                                    "MMMM Do YYYY, h:mm a"
                                  )}
                                </div>
                                <div className="flex gap-3 items-center font-light text-xs ">
                                  <div
                                    className="flex items-center text-[#547AF7] cursor-pointer gap-1"
                                    onClick={() => {
                                      setShowSessionDetails(session._id);
                                    }}
                                  >
                                    <div>View Full Summary</div>
                                    <FaChevronDown />
                                  </div>
                                  {session.rated ? null : (
                                    <div
                                      className="flex items-center text-[#BE8A26] cursor-pointer"
                                      onClick={() => {
                                        setFeedback({
                                          rating: 0,
                                          details: {
                                            understoodProblem: false,
                                            explainedProcess: false,
                                            answeredAll: false,
                                          },
                                        });
                                        setFeedbackCall(session);
                                        setShowFeedbackModal(true);
                                      }}
                                    >
                                      Give a Feedback
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {showSessionDetails === session._id && (
                              <div>
                                {session.momAdded ? (
                                  <div className="flex flex-col gap-2 bg-white">
                                    <div>
                                      <div className="font-extrabold mt-2">
                                        Session Summary
                                      </div>
                                      <div className="mt-1 text-xs">
                                        {htmlParser.parse(
                                          session.mom.description
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <div className="font-extrabold mt-2">
                                        Documents
                                      </div>
                                      <div className="mt-1 text-xs">
                                        {htmlParser.parse(
                                          session.mom.documents
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex mt-2 mb-2 items-center text-xs font-light justify-center">
                                    Summary not added yet
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            <div>
              <div className="m-2">
                <div className="text-lg font-bold">Already Consulted CA's</div>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mt-2">
                  {consultedCAs.map((ca: any) => (
                    <div className="border-[0.5px] cursor-pointer hover:shadow-lg hover:bg-[#547af7]/[.14] hover:border-0 p-2 rounded-lg"
                      onClick={()=>{
                        setSelectedCA(ca)
                        setShowSlots(true)
                      }}
                    >
                      <div className="flex items-center gap-4 pr-2 pl-2 ">
                        <div>
                          <Avatar
                            img={ca?.profilePic}
                            rounded
                            className="z-0"
                          />
                        </div>
                        <div>
                          <div className="font-bold">
                            {ca.prefix}{" "}{ca.username}
                          </div>
                          <div className="font-light text-xs">
                            {ca.experience} Years
                            experience
                          </div>
                          <Rating size="sm" className="mt-1">
                            <Rating.Star
                              filled={ca.rating >= 1}
                            />
                            <Rating.Star
                              filled={ca.rating >= 2}
                            />
                            <Rating.Star
                              filled={ca.rating >= 3}
                            />
                            <Rating.Star
                              filled={ca.rating >= 4}
                            />
                            <Rating.Star
                              filled={ca.rating >= 5}
                            />
                          </Rating>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showFeedbackModal}
            onClose={() => {
              setShowFeedbackModal(false);
            }}
            size="sm"
          >
            <div className="p-4">
              <div className="flex justify-between">
                <div></div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setShowFeedbackModal(false);
                  }}
                >
                  X
                </div>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <div className="text-bold text-lg">Rate you Converstion</div>
                <div className="text-xs font-light text-center">
                  Your call with {feedbackCall.consultantDetails?.prefix}{" "}{feedbackCall.consultantDetails?.username} on{" "}
                  {moment(feedbackCall.startTime).format("MMM Do, h:mm a")}. How
                  did it go?{" "}
                </div>
                <div>
                  <Rating size="md">
                    <Rating.Star
                      filled={feedback.rating >= 1}
                      className="pointer-cursor"
                      onClick={() => {
                        handleRatingClick(1);
                      }}
                    />
                    <Rating.Star
                      filled={feedback.rating >= 2}
                      className="cursor-pointer"
                      onClick={() => {
                        handleRatingClick(2);
                      }}
                    />
                    <Rating.Star
                      filled={feedback.rating >= 3}
                      className="cursor-pointer"
                      onClick={() => {
                        handleRatingClick(3);
                      }}
                    />
                    <Rating.Star
                      filled={feedback.rating >= 4}
                      className="cursor-pointer"
                      onClick={() => {
                        handleRatingClick(4);
                      }}
                    />
                    <Rating.Star
                      filled={feedback.rating >= 5}
                      className="cursor-pointer"
                      onClick={() => {
                        handleRatingClick(5);
                      }}
                    />
                  </Rating>
                </div>
                {feedback.rating > 0 && (
                  <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-col gap-2 rounded-lg border-2 m-1 p-2 border-[#D0D5DD] w-full">
                      <div className="flex gap-1 items-center">
                        <Checkbox
                          className="text-[#FBBF24]"
                          checked={feedback.details.understoodProblem}
                          onClick={(event: any) => {
                            handleRatingCheckBoxClick(
                              "understoodProblem",
                              event.target.checked
                            );
                          }}
                        />
                        <div className="text-xs">Understood Problem Well.</div>
                      </div>
                      <div className="flex gap-1 items-center ">
                        <Checkbox
                          className="text-[#FBBF24]"
                          checked={feedback.details.explainedProcess}
                          onClick={(event: any) => {
                            handleRatingCheckBoxClick(
                              "explainedProcess",
                              event.target.checked
                            );
                          }}
                        />
                        <div className="text-xs">
                          Explained Process in detail.
                        </div>
                      </div>
                      <div className="flex gap-1 items-center ">
                        <Checkbox
                          className="text-[#FBBF24]"
                          checked={feedback.details.answeredAll}
                          onClick={(event: any) => {
                            handleRatingCheckBoxClick(
                              "answeredAll",
                              event.target.checked
                            );
                          }}
                        />
                        <div className="text-xs">Answered all my queries.</div>
                      </div>
                    </div>
                    <Button
                      className="text-center bg-[#547AF7] font-bold text-sm"
                      onClick={() => {
                        submitFeedback();
                      }}
                    >
                      Submit Feedback
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {loading ? <SpinnerComponent /> : null}
          </Modal>
          <SelectionPopup
        showConsultantPopup={showSlots}
        modalClose={() => {
          setShowSlots(false);
          setSelectedCA(null);
        }}
        consultantInfo={selectedCA}
        category=""
      />
          {loading ? <SpinnerComponent /> : null}
        </NavigationBar>
      )}
    </>
  );
}

export default UserDashboard;
