import {
  Avatar,
  Button,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  Navbar,
  Radio,
  Select,
  Table,
  Textarea,
  TextInput,
} from "flowbite-react";
import AdminNavbar from "./AdminNavbar";
import { useEffect, useState } from "react";
import AxiosInstance from "../../Utils/AxiosInstance";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLink } from "react-icons/fa6";

function AdminDashboard() {
  const [caRequests, setCARequests] = useState([]);
  const [selectedCA, setSelectedCA]: any = useState({});
  const [showCADetails, setShowCADetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [rejectId, setRejectId] = useState("");

  const getCARequests = () => {
    AxiosInstance()
      .get("/admin/getNewCARequests")
      .then(({ data }) => {
        setCARequests(data);
      })
      .catch((err) => {
        toast.error("Failed to get the lead list, please try again");
      });
  };

  useEffect(() => {
    getCARequests();
  }, []);

  const getIconfromLink: any = (link: string) => {
    if (link.toLowerCase().startsWith("https://facebook.com") || link.toLowerCase().startsWith("https://www.facebook.com") || link.toLowerCase().startsWith("www.facebook.com") || link.toLowerCase().startsWith("facebook.com")) {
      return FaFacebook
    }
    else if (link.toLowerCase().startsWith("https://twitter.com") || link.toLowerCase().startsWith("https://www.twitter.com") || link.toLowerCase().startsWith("www.twitter.com") || link.toLowerCase().startsWith("twitter.com")) {
      return FaTwitter
    }
    else if (link.toLowerCase().startsWith("https://youtube.com") || link.toLowerCase().startsWith("https://www.youtube.com") || link.toLowerCase().startsWith("www.youtube.com") || link.toLowerCase().startsWith("youtube.com")) {
      return IoLogoYoutube
    }
    else if (link.toLowerCase().startsWith("https://linkedin.com") || link.toLowerCase().startsWith("https://www.linkedin.com") || link.toLowerCase().startsWith("www.linkedin.com") || link.toLowerCase().startsWith("linkedin.com")) {
      return FaLinkedin
    }
    else if (link.toLowerCase().startsWith("https://instagram.com") || link.toLowerCase().startsWith("https://www.instagram.com") || link.toLowerCase().startsWith("www.instagram.com") || link.toLowerCase().startsWith("instagram.com")) {
      return FaInstagram
    }
    else {
      return FaLink
    }

  }

  const acceptCA = (id: any) => {
    setLoading(true);
    AxiosInstance()
      .post("/admin/updateCARequest", { _id: id, status: "ACCOUNT_VERIFIED" })
      .then(({ data }) => {
        setLoading(false);
        toast.success("Consultant Request accepted.");
        getCARequests();
      })
      .catch((err) => {
        toast.error("Failed to update request, please try again");
      });
  };

  const rejectCA = (event: any) => {
    event.preventDefault();
    setLoading(true);
    AxiosInstance()
      .post("/admin/updateCARequest", {
        _id: rejectId,
        status: "ACCOUNT_REJECTED",
        message: rejectReason,
      })
      .then(({ data }) => {
        setLoading(false);
        toast.success("Consultant Request rejected.");
        setShowRejectPopup(false);
        getCARequests();
      })
      .catch((err) => {
        toast.error("Failed to update request, please try again");
      });
  };

  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  let qualifications = ["Graduate", "Post Graduate", "P.Hd."];

  const options = [
    { name: "Income Tax", id: 1 },
    { name: "NRI Corner", id: 2 },
    { name: "Business Registration", id: 3 },
    { name: "Legal", id: 4 },
    { name: "Investments", id: 5 },
    { name: "Insurance", id: 6 },
  ];

  return (
    <div>
      <AdminNavbar />
      <div className="p-4">
        <Table>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Number</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Experience</Table.HeadCell>
            <Table.HeadCell>Occupation</Table.HeadCell>
            <Table.HeadCell>State</Table.HeadCell>
            <Table.HeadCell>Actions</Table.HeadCell>
          </Table.Head>
          <Table.Body>
            {caRequests.map((lead: any) => (
              <Table.Row>
                <Table.Cell>
                  <div
                    className="cursor-pointer text-blue-600"
                    onClick={() => {
                      setSelectedCA(lead);
                      setShowCADetails(true);
                    }}
                  >
                    {lead.username}
                  </div>
                </Table.Cell>
                <Table.Cell>{lead.number}</Table.Cell>
                <Table.Cell>{lead.email}</Table.Cell>
                <Table.Cell>{lead.experience}</Table.Cell>
                <Table.Cell>{lead.occupation}</Table.Cell>
                <Table.Cell>{lead.state}</Table.Cell>
                <Table.Cell>
                  <div className="flex gap-2 items-center">
                    <Button
                      size="xs"
                      onClick={() => {
                        acceptCA(lead._id);
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => {
                        setRejectId(lead._id);
                        setRejectReason("");
                        setShowRejectPopup(true);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <Modal
        show={showCADetails}
        onClose={() => {
          setShowCADetails(false);
        }}
        size="7xl"
      >
        <ModalHeader>
          <div>Consultant Details</div>
        </ModalHeader>
        <div className="h-inherit overflow-auto">
          <div className="grid grid-cols-1 gap-4">
            <div className="m-4 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
              <div className="block lg:flex">
                <div className="w-1/3">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Profile Pic" />
                    </div>
                    <Avatar img={selectedCA.profilePic} size="xl" />
                  </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Full Name" />
                    </div>
                    <div className="flex gap-1 items-center">
                      <Select
                        id="countries"
                        defaultValue={selectedCA.prefix}
                        disabled
                      >
                        <option value="CA">{selectedCA.prefix === "" ? "Others" : selectedCA.prefix}</option>
                      </Select>
                      <TextInput
                        id="name"
                        type="text"
                        placeholder="Enter Name"
                        defaultValue={selectedCA.username}
                        disabled
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Email Id" />
                    </div>
                    <TextInput
                      id="email"
                      type="email"
                      placeholder="Enter Email"
                      defaultValue={selectedCA.email}
                      disabled
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Occupation" />
                    </div>
                    <TextInput
                      id="occuption"
                      type="text"
                      placeholder="Enter Occupation"
                      defaultValue={selectedCA.occupation}
                      disabled
                    />
                  </div>
                  <div className="max-w-md">
                    <div className="mb-2 block">
                      <Label htmlFor="countries" value="State" />
                    </div>
                    <Select
                      id="countries"
                      defaultValue={selectedCA.state}
                      disabled
                    >
                      {states.map((state) => (
                        <option>{state}</option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

            </div>
            <div className="m-4 mt-0 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Aadhar Number" />
                  </div>
                  <TextInput
                    id="aadhar"
                    type="text"
                    placeholder="Enter aadhar Number"
                    defaultValue={selectedCA.kycDetails?.aadhar}
                    maxLength={12}
                    disabled
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="PAN Number" />
                  </div>
                  <TextInput
                    id="email"
                    type="text"
                    placeholder="Enter PAN number"
                    defaultValue={selectedCA.kycDetails?.pan}
                    maxLength={10}
                    disabled
                  />
                </div>
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Highest Qualification" />
                  </div>
                  <Select
                    id="countries"
                    defaultValue={selectedCA.kycDetails?.qualification}
                    disabled
                  >
                    {qualifications.map((qualification) => (
                      <option>{qualification}</option>
                    ))}
                  </Select>
                </div>
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Degree" />
                    {
                      selectedCA.kycDetails?.degree.map((value: any, index: any) => (
                        <Select
                          id="countries"
                          defaultValue={value}
                          disabled
                          className={`${index !== 0 ? "mt-2" : ""}`}
                        >
                          <option>{value}</option>

                        </Select>
                      ))
                    }
                  </div>

                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Holiding Certificate" />
                  </div>
                  <div className="flex items-center gap-8 flex-row">
                    <div className="flex items-center gap-2">
                      <Radio
                        id="united-state"
                        name="countries"
                        value="True"
                        defaultChecked={
                          selectedCA.kycDetails?.certificate === "True"
                        }
                        disabled
                      />
                      <Label htmlFor="united-state">Yes</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="united-state"
                        name="countries"
                        value="False"
                        defaultChecked={
                          selectedCA.kycDetails?.certificate === "False"
                        }
                        disabled
                      />
                      <Label htmlFor="united-state">No</Label>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Institute/License Number" />
                  </div>
                  <TextInput
                    id="occuption"
                    type="text"
                    placeholder="Enter License Number"
                    defaultValue={selectedCA.kycDetails?.license}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="m-4 mt-0 p-4 border-[0.5px] rounded-lg border-[#EAEAEA]">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Years of Experience" />
                  </div>
                  <TextInput
                    id="aadhar"
                    type="number"
                    placeholder="Enter Years of Experience"
                    defaultValue={selectedCA.experience}
                    maxLength={12}
                    disabled
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Clients worked with" />
                  </div>
                  <TextInput
                    id="email"
                    type="number"
                    placeholder="Enter number of clients worked with"
                    defaultValue={selectedCA.professionalDetails?.clients}
                    maxLength={10}
                    disabled
                  />
                </div>
                <div className="max-w-md">
                  <div className="mb-2 flex justify-between items-center">
                    <Label htmlFor="countries" value="Social Media Handles (Max 5)" />
                  </div>
                  {
                    selectedCA.professionalDetails?.links.map((achivement: any, index: any) => (
                      <div className={`flex items-center gap-2 ${index != 0 ? "mt-2" : ""} w-full cursor-pointer`} onClick={() => { window.open(achivement, '_blank', 'noopener,noreferrer') }}>
                        <TextInput
                          id="email"
                          placeholder="Add your social media links"
                          defaultValue={achivement}
                          disabled
                          icon={getIconfromLink(achivement)}
                          className="w-full"
                        />
                      </div>
                    ))
                  }
                </div>
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Awards" />
                    {
                      selectedCA.professionalDetails?.award.map((value: any, index: any) => (
                        <Textarea
                          id="countries"
                          defaultValue={value}
                          disabled
                          className={`${index !== 0 ? "mt-2" : ""}`}
                        />
                      ))
                    }
                  </div>

                </div>
                <div className="max-w-md">
                  <div className="mb-2 block">
                    <Label htmlFor="countries" value="Achivements" />
                    {
                      selectedCA.professionalDetails?.achivement.map((value: any, index: any) => (
                        <Textarea
                          id="countries"
                          defaultValue={value}
                          disabled
                          className={`${index !== 0 ? "mt-2" : ""}`}
                        />
                      ))
                    }
                  </div>

                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Professional Summary" />
                  </div>
                  <Textarea
                    id="email"
                    rows={4}
                    placeholder="Enter a description"
                    defaultValue={selectedCA.professionalDetails?.summary}
                    disabled
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Select Category (max 3)" />
                  </div>
                  <Multiselect
                    options={options} // Options to display in the dropdown
                    selectedValues={options.filter(
                      (option) =>
                        selectedCA.expertise &&
                        selectedCA.expertise.indexOf(option.name) !== -1
                    )} // Preselected value to persist in dropdown
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder="Select your Category"
                    disable
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Services" />
                  </div>
                  {selectedCA.professionalDetails?.services && Object.keys(selectedCA.professionalDetails?.services).map(
                    (serivce: any) => (
                      <div className="grid gap-2 mt-2 grid-col-2 items-center">
                        <div>{serivce}</div>
                        <div>
                          <TextInput
                            type="number"
                            placeholder="Enter amount"
                            defaultValue={
                              selectedCA.professionalDetails?.services[
                              serivce
                              ]
                            }
                            disabled
                          />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        show={showRejectPopup}
        onClose={() => {
          setShowRejectPopup(false);
        }}
      >
        <form onSubmit={rejectCA}>
          <ModalHeader>Reject Reason</ModalHeader>
          <div id="number-section" className="m-6">
            <div className="flex items-center justify-between font-semibold text-xs text-[#8A8A8A]">
              <div>Reject Reason</div>
            </div>
            <input
              className="border-0 mt-2 focus:ring-0 border-b-2 border-[#161616] w-full placeholder:text-sm font-light p-1"
              placeholder="Enter reason to reject profile"
              type="text"
              id="mobile-number"
              onChange={(event) => {
                setRejectReason(event.target.value);
              }}
            />
          </div>
          <ModalFooter>
            <Button type="submit">Reject</Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
}

export default AdminDashboard;
