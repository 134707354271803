export const costing:any = {
    "Income Tax Return Filling":1000,
    "Tax Planning":1000,
    "IT Notices":1000,
    "GST Filling":1000,
    "DSC Services":1000,
    "Legal Advisory":1000,
    "IT Appeal Services":1000,
    "Wealth Management":1000,
    "GST Registration":1000,
    "Discovery":0
}

export const timeMap: any = {
    "0": "12:00 AM",
    "1": "01:00 AM",
    "2": "02:00 AM",
    "3": "03:00 AM",
    "4": "04:00 AM",
    "5": "05:00 AM",
    "6": "06:00 AM",
    "7": "07:00 AM",
    "8": "08:00 AM",
    "9": "09:00 AM",
    "10": "10:00 AM",
    "11": "11:00 AM",
    "12": "12:00 AM",
    "13": "01:00 PM",
    "14": "02:00 PM",
    "15": "03:00 PM",
    "16": "04:00 PM",
    "17": "05:00 PM",
    "18": "06:00 PM",
    "19": "07:00 PM",
    "20": "08:00 PM",
    "21": "09:00 PM",
    "22": "10:00 PM",
    "23": "11:00 PM",
  };